import mxContent from './es-MX'
import esContent from './es-ES'

export default {
  getLocales: function (lang = 'mx') {
    const langs = {
      mx: 'es-MX',
      es: 'es-ES',
    };
    return langs[lang];
  },
  getLocaleMessages: function (lang = 'mx') {
    const langs = {
      mx: { 'es-MX': mxContent },
      es: { 'es-ES': esContent },
    };
    return langs[lang];
  }
}
