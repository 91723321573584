<template>
  <div>
    <div
      class="timeline"
      v-if="timeline.length"
    >
      <div class="timeline-header">
        <span class="tag is-medium is-primary">
          {{ timeline[0].createdAt | year }}
        </span>
      </div>

      <div
        v-for="(timelapse, index) in timeline"
        :key="`timelapse-${index}`"
        class="timeline-item"
      >
        <div
          :style="`background: ${timelapse.company.mainColor}`"
          class="timeline-marker"
        />
        <div class="timeline-content">
          <p class="heading">
            {{ timelapse.createdAt | formatDate }}
          </p>
          <p>Vacante: {{ timelapse.vacancy.name }}</p>
          <p>Empresa: {{ timelapse.company.name }}</p>
          <b-tag :type="types[timelapse.status]">
            {{ statuses[timelapse.status] }}
          </b-tag>
        </div>
      </div>

      <div class="timeline-header">
        <span class="tag is-medium is-primary">
          {{ timeline[timeline.length - 1].createdAt | year }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    timeline:{
      default: () => ([]),
      required: true
    }
  },
  data() {
    return {
      statuses: {
        applying: "En aplicación",
        selected: "Seleccionad@",
        sent: "En revisión con la empresa",
        rejected: "Rechazad@",
        "pre-hired": "Seleccionad@ por la empresa",
        hired: "Contratad@",
      },
      types: {
        applying: 'is-success',
        selected: 'is-blue',
        sent: 'is-warning',
        "pre-hired": 'is-danger',
        rejected: 'is-black'
      }
    };
  },
  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>