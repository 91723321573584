<template>

  <div class="container pb-50">

      <div class="pt-5 column is-flex justify-flex-end">

        <b-button
          rounded
          icon-left="arrow-left"
          type="is-primary"
          tag="router-link"
          :to="getReturningPageParams"
        >
          Regresar
        </b-button>

      </div>

      <div class="columns">

        <div class="column">

          <span class="is-size-3">
            <strong>{{ company?.name }}</strong> | Editar Vacante
          </span>

        </div>
      </div>

      <div class="columns">

        <div class="column is-8">

          <ValidationObserver ref="observer">

            <div 
              class="card" 
              style="border-radius: 15px">

              <div class="column mt-3 is-12">

                <div class="columns is-multiline">

                  <div class="column">
                    <p class="title is-4 text--primary">Información principal</p>
                  </div>

                  <div class="column is-5" v-if="alligment">
                    <b-field 
                      horizontal 
                      expanded
                      >
                      <template #label>
                        <p class="is-size-7">Fecha de alineación</p>
                      </template>
                      
                      <b-datepicker
                          icon="calendar"
                          v-model="phoneCallDate"
                          placeholder="Selecciona una fecha"
                          :unselectable-days-of-week="[0, 6]"
                        />

                    </b-field>
                  </div>

                </div>

                <div class="columns is-multiline px-3">

                  <div class="column is-6  ">

                    <ValidationProvider 
                      rules="required" 
                      name="Nombre de la Vacante" 
                      v-slot="{ errors, valid }">

                      <b-field
                        :label="renameField(fieldsNames.name)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">

                        <b-input
                          icon="angle-right"
                          :placeholder="newPlaceholder(fieldsNames.name)"
                          v-model="form.name" 
                          maxlength="64"/>

                      </b-field>

                    </ValidationProvider>
                  </div>

                  <div class="column px-3">

                    <ValidationProvider 
                      rules="required" 
                      name="Período" 
                      v-slot="{ errors, valid }">

                      <b-field
                        :label="renameField(fieldsNames.duration)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">
                        <b-select
                        :placeholder="newPlaceholder(fieldsNames.duration)"
                        icon="calendar-days" 
                        v-model="form.duration" 
                        expanded>

                          <option
                            v-for="option in durationOptions"
                            :key="`duration-${option.text}`"
                            :value="option.text"
                          >
                            {{ option.text }}
                          </option>

                        </b-select>

                      </b-field>

                    </ValidationProvider>

                  </div>

                <div class="column px-3">

                  <ValidationProvider 
                    rules="required|numeric|min_value:1" 
                    name="Número de Posiciones" 
                    v-slot="{ errors, valid }">

                    <b-field
                      :label="renameField(fieldsNames.places)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">

                      <b-input
                        :placeholder="newPlaceholder(fieldsNames.places)"
                        icon="user-plus"
                        type="number" 
                        v-model="form.places"/>

                    </b-field>

                  </ValidationProvider>

                </div>

                </div>

              <div class="columns px-3">

                <div class="column">

                  <ValidationProvider 
                    rules="required" 
                    name="Estado" 
                    v-slot="{ errors, valid }">

                    <b-field
                      :label="renameField(fieldsNames.state)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">

                      <b-select 
                        :placeholder="newPlaceholder(fieldsNames.state)"
                        icon="map-pin" 
                        v-model="stateSelected" 
                        expanded
                        v-if="isMexico"  
                      >

                        <option
                          v-for="(state, index) in states"
                          :key="`state-${index}`"
                          :value="state"
                        >
                          {{ state.name }}
                        </option>

                      </b-select>

                      <b-select
                          v-else
                          icon="map-pin"
                          :placeholder="newPlaceholder(fieldsNames.state)"
                          v-model="stateSelected"
                          expanded
                        >
                          <option
                            v-for="(province, index) in provinces"
                            :key="`province-${index}`"
                            :value="province"
                          >
                            {{province.name}}
                          </option>
                        </b-select>

                    </b-field>

                  </ValidationProvider>

                </div>

                <div class="column">

                  <ValidationProvider 
                    rules="required" 
                    name="Ciudad" 
                    v-slot="{ errors, valid }">

                    <b-field
                      :label="renameField(fieldsNames.city)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">

                      <b-select
                        :placeholder="newPlaceholder(fieldsNames.city)"
                        icon="map-pin"
                        v-model="citySelected"
                        :disabled="stateCities.length === 0"
                        expanded
                        v-if="isMexico"
                      >

                        <option
                          v-for="(city, index) in stateCities"
                          :key="`city-${index}`"
                          :value="city"
                        >
                          {{ city.name }}
                        </option>

                      </b-select>

                      <b-select
                          v-else
                          icon="map-pin"
                          :disabled="stateSelected?.id === '00'"
                          v-model="citySelected"
                          expanded
                        >
                          <option
                            v-for="(municipality,index) in provincesMunicipalities"
                            :key="`municipality-${index}`"
                            :value="municipality"
                          >
                          {{ municipality.name }}
                          </option>
                        </b-select>

                    </b-field>

                  </ValidationProvider>

                </div>

                <div class="column ">

                  <ValidationProvider 
                    rules="required" 
                    name="Modalidad" 
                    v-slot="{ errors, valid }">

                    <b-field
                      :label="renameField(fieldsNames.modality)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">

                      <b-select
                        icon="square-user"
                        v-model="form.modality"
                        :placeholder="newPlaceholder(fieldsNames.modality)"
                        expanded>

                        <option
                          v-for="modality in modalities"
                          :key="`modality-${modality}`"
                          :value="modality"
                        >
                          {{ modality }}
                        </option>

                      </b-select>

                    </b-field>

                  </ValidationProvider>

                </div>

              </div>

              <div class="columns px-3">

                <div class="column mb-5">

                  <b-field
                    :label="renameField(fieldsNames.showScholarship)">

                    <b-switch
                      class="mt-2 ml-40"
                      :rounded="noRounded"
                      v-model="form.showScholarship" />

                  </b-field>

                </div>

                <div class="column is-4">

                  <ValidationProvider 
                    rules="required|numeric" 
                    name="Beca" 
                    v-slot="{ errors, valid }">

                    <b-field
                      :label="renameField(fieldsNames.scholarship)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                      >
                      
                      <b-input
                        :placeholder="newPlaceholder(fieldsNames.scholarship)"
                        icon="money-bill"
                        v-model="form.scholarship"
                        :disabled="!canManageAllFields"
                        />

                    </b-field>

                  </ValidationProvider>

                </div>

                <div class="column is-5">
                  <ValidationProvider 
                        rules="required" 
                        name="Horario" 
                        v-slot="{ errors, valid }">
  
                        <b-tooltip
                          style="width: 100%;"
                          label="6 horas diarias, 30 horas semanales, Matutino, 8 a.m. a 2 p.m."
                          position="is-bottom"
                          animated>
  
                          <b-field
                            :label="renameField(fieldsNames.schedule)"
                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
  
                            <b-input
                              icon="clock"
                              :placeholder="newPlaceholder(fieldsNames.schedule)"
                              v-model="form.schedule"/>
  
                          </b-field>
  
                        </b-tooltip>
  
                      </ValidationProvider>
  
                </div>

              </div>

              <!-- <div class="columns is-multiline px-3">
                
                <div class="column is-9 title is-4">
                  Horario
                </div>

                <div class="column is-2">

                  <b-tooltip
                    multilined
                    type="is-info is-light"
                    position="is-left"
                    label="Utiliza el horario del día Lunes para completar los días restantes."
                  >

                    <b-button  
                      :disabled="!disabledCompleteButton"
                      type="is-info is-light" 
                      label="Completar horario"
                      @click="completeSchedule"
                    />

                  </b-tooltip>

                  
                </div>
                
                <div class="column is-4">

                  <div class="is-flex direction-column">
                    <b-tooltip
                      label="Ingresa la hora en formato de 24 hrs  HH:MM"
                      position="is-bottom"
                    >
  
                      <ValidationProvider 
                        rules="hour|required" 
                        name="Lunes" 
                        v-slot="{ errors, valid }">
                      
                        <b-field
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                          label="Lunes"
                        >
                          <b-input
                            class="my-1" 
                            placeholder="Hora de entrada" 
                            icon="chess-clock"
                            @focus="verifyDaySchedule(form.schedule.detail.monday[0])"
                            v-model="form.schedule.detail.monday[0].startHour"
                          />
  
                        </b-field>
                        
                      </ValidationProvider>
  
                      <ValidationProvider 
                        rules="hour|required" 
                        name="Lunes" 
                        v-slot="{ errors, valid }">
                      
                        <b-field
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                        >
                          <b-input
                            class="my-1" 
                            placeholder="Hora de salida" 
                            icon="chess-clock-flip"
                            @focus="verifyDaySchedule(form.schedule.detail.monday[0])"
                            v-model="form.schedule.detail.monday[0].endHour"
                          />
  
                        </b-field>
                        
                      </ValidationProvider>
  
                    </b-tooltip>
  
                  </div>

                </div>

                <div class="column is-4">

                  <div class="is-flex direction-column">
                    <b-tooltip
                      label="Ingresa la hora en formato de 24 hrs  HH:MM"
                      position="is-bottom"
                    >
  
                      <ValidationProvider 
                        rules="hour|required" 
                        name="Martes" 
                        v-slot="{ errors, valid }">
                      
                        <b-field
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                          label="Martes"
                        >
                          <b-input
                            class="my-1" 
                            placeholder="Hora de entrada" 
                            icon="chess-clock"
                            @focus="verifyDaySchedule(form.schedule.detail.tuesday[0])"
                            v-model="form.schedule.detail.tuesday[0].startHour"
                          />
  
                        </b-field>
                        
                      </ValidationProvider>
  
                      <ValidationProvider 
                        rules="hour|required" 
                        name="Martes" 
                        v-slot="{ errors, valid }">
                      
                        <b-field
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                        >
                          <b-input
                            class="my-1" 
                            placeholder="Hora de salida" 
                            icon="chess-clock-flip"
                            @focus="verifyDaySchedule(form.schedule.detail.tuesday[0])"
                            v-model="form.schedule.detail.tuesday[0].endHour"
                          />
  
                        </b-field>
                        
                      </ValidationProvider>
  
                    </b-tooltip>
  
                  </div>

                </div>

                <div class="column is-4">

                  <div class="is-flex direction-column">
                    <b-tooltip
                      label="Ingresa la hora en formato de 24 hrs  HH:MM"
                      position="is-bottom"
                    >
  
                      <ValidationProvider 
                        rules="hour|required" 
                        name="Miércoles" 
                        v-slot="{ errors, valid }">
                      
                        <b-field
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                          label="Miércoles"
                        >
                          <b-input
                            class="my-1" 
                            placeholder="Hora de entrada" 
                            icon="chess-clock"
                            @focus="verifyDaySchedule(form.schedule.detail.wednesday[0])"
                            v-model="form.schedule.detail.wednesday[0].startHour"
                          />
  
                        </b-field>
                        
                      </ValidationProvider>
  
                      <ValidationProvider 
                        rules="hour|required" 
                        name="Miércoles" 
                        v-slot="{ errors, valid }">
                      
                        <b-field
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                        >
                          <b-input
                            class="my-1" 
                            placeholder="Hora de salida" 
                            icon="chess-clock-flip"
                            @focus="verifyDaySchedule(form.schedule.detail.wednesday[0])"
                            v-model="form.schedule.detail.wednesday[0].endHour"
                          />
  
                        </b-field>
                        
                      </ValidationProvider>
  
                    </b-tooltip>
  
                  </div>

                </div>

                <div class="column is-4">

                  <div class="is-flex direction-column">

                    <b-tooltip
                      label="Ingresa la hora en formato de 24 hrs  HH:MM"
                      position="is-bottom"
                    >
  
                      <ValidationProvider 
                        rules="hour|required" 
                        name="Jueves" 
                        v-slot="{ errors, valid }">
                      
                        <b-field
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                          label="Jueves"
                        >
                          <b-input
                            class="my-1" 
                            placeholder="Hora de entrada" 
                            icon="chess-clock"
                            @focus="verifyDaySchedule(form.schedule.detail.thursday[0])"
                            v-model="form.schedule.detail.thursday[0].startHour"
                          />
  
                        </b-field>
                        
                      </ValidationProvider>
  
                      <ValidationProvider 
                        rules="hour|required" 
                        name="Jueves" 
                        v-slot="{ errors, valid }">
                      
                        <b-field
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                        >
                          <b-input
                            class="my-1" 
                            placeholder="Hora de salida" 
                            icon="chess-clock-flip"
                            @focus="verifyDaySchedule(form.schedule.detail.thursday[0])"
                            v-model="form.schedule.detail.thursday[0].endHour"
                          />
  
                        </b-field>
                        
                      </ValidationProvider>
  
                    </b-tooltip>
  
                  </div>

                </div>

                <div class="column is-4">

                  <div class="is-flex direction-column">

                    <b-tooltip
                      label="Ingresa la hora en formato de 24 hrs  HH:MM"
                      position="is-bottom"
                    >
  
                      <ValidationProvider 
                        rules="hour|required" 
                        name="Viernes" 
                        v-slot="{ errors, valid }">
                      
                        <b-field
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                          label="Viernes"
                        >
                          <b-input
                            class="my-1" 
                            placeholder="Hora de entrada" 
                            icon="chess-clock"
                            @focus="verifyDaySchedule(form.schedule.detail.friday[0])"
                            v-model="form.schedule.detail.friday[0].startHour"
                          />
  
                        </b-field>
                        
                      </ValidationProvider>
  
                      <ValidationProvider 
                        rules="hour|required" 
                        name="Friday" 
                        v-slot="{ errors, valid }">
                      
                        <b-field
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                        >
                          <b-input
                            class="my-1" 
                            placeholder="Hora de salida" 
                            icon="chess-clock-flip"
                            @focus="verifyDaySchedule(form.schedule.detail.friday[0])"
                            v-model="form.schedule.detail.friday[0].endHour"
                          />
  
                        </b-field>
                        
                      </ValidationProvider>
  
                    </b-tooltip>
  
                  </div>

                </div>
              
              </div> -->


              <hr>

              <div class="column is-12">

                <div class="title is-4">
                  Formación académica
                </div>

                <div class="columns is-multiline">

                  <div class="column is-half">

                    <ValidationProvider 
                      rules="required" 
                      name="Licenciatura(s)" 
                      v-slot="{ errors, valid }">

                      <b-field
                        :label="renameField(fieldsNames.degree)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">

                        <b-taginput
                          ellipsis
                          type="is-primary"
                          v-model="form.degree"
                          icon="graduation-cap"
                          placeholder="Presione 'Enter' para agregar"
                        />

                      </b-field>

                    </ValidationProvider>

                  </div>

                  <div class="column is-half">

                    <ValidationProvider 
                      rules="required" 
                      name="Nivel académico" 
                      v-slot="{ errors, valid }">

                      <b-field
                        :label="renameField(fieldsNames.academicLevel)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">

                        <div class="academicOptions">

                          <b-checkbox 
                            class="academicOptions--option" 
                            v-model="form.academicLevel" 
                            native-value="student">
                            Estudiante
                          </b-checkbox>

                          <b-checkbox 
                            class="academicOptions--option" 
                            v-model="form.academicLevel" 
                            native-value="recently-graduated">
                            Recién graduado
                          </b-checkbox>

                          <b-checkbox 
                            class="academicOptions--option" 
                            v-model="form.academicLevel" 
                            native-value="experienced-graduated">
                            Graduado con experiencia
                          </b-checkbox>

                        </div>

                      </b-field>

                    </ValidationProvider>

                  </div>

                  <div 
                    v-if="form.academicLevel.includes('student')" 
                    class="column is-half">

                    <ValidationProvider 
                      :rules="`required|max_value:${form.maximumSemester}`" 
                      name="Semestre Mínimo" 
                      v-slot="{ errors, valid }">

                      <b-field
                        :label="renameField(fieldsNames.minimumSemester)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">

                        <b-select
                          v-if="isMexico"
                          expanded
                          :placeholder="newPlaceholder(fieldsNames.minimumSemester)"
                          icon="user-minus"
                          v-model="form.minimumSemester">
                          <option
                            v-for="semester in semesters"
                            :key="`minSemester-${semester.value}`"
                            :value="semester.value">
                            {{ semester.text }}
                          </option>
                        </b-select>

                        <b-select
                          v-else
                          expanded
                          :placeholder="newPlaceholder(fieldsNames.minimumSemester)"
                          icon="user-minus"
                          v-model="form.minimumSemester">
                          <option
                            v-for="semester in esSemesters"
                            :key="`minSemester-${semester.value}`"
                            :value="semester.value">
                            {{ semester.text }}
                          </option>
                        </b-select>

                      </b-field>

                    </ValidationProvider>

                  </div>

                  <div 
                    v-if="form.academicLevel.includes('student')" 
                    class="column is-half">

                    <ValidationProvider 
                      :rules="`required|min_value:${form.minimumSemester}`" 
                      name="Semestre Máximo" 
                      v-slot="{ errors, valid }">

                      <b-field
                        :label="renameField(fieldsNames.maximumSemester)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">

                        <b-select
                          v-if="isMexico"
                          :placeholder="newPlaceholder(fieldsNames.maximumSemester)"
                          expanded
                          icon="user-plus"
                          v-model="form.maximumSemester">
                          <option
                            v-for="semester in semesters"
                            :key="`maxSemester-${semester.text}`"
                            :value="semester.value">
                            {{ semester.text }}
                          </option>
                        </b-select>

                        <b-select
                          v-else
                          :placeholder="newPlaceholder(fieldsNames.maximumSemester)"
                          expanded
                          icon="user-plus"
                          v-model="form.maximumSemester">
                          <option
                            v-for="semester in esSemesters"
                            :key="`maxSemester-${semester.text}`"
                            :value="semester.value">
                            {{ semester.text }}
                          </option>
                        </b-select>

                      </b-field>

                    </ValidationProvider>

                  </div>

                  <div class="column is-half">

                    <ValidationProvider 
                      rules="required" 
                      name="Nivel de Excel" 
                      v-slot="{ errors, valid }">

                      <b-field
                        :label="renameField(fieldsNames.excelLevel)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">

                        <b-select 
                          :label="renameField(fieldsNames.excelLevel)"
                          expanded
                          icon="browsers"
                          v-model="form.excelLevel">

                          <option
                            v-for="(level, index) in levels"
                            :key="`excelLevel-${index}`"
                            :value="level">
                            {{ level }}
                          </option>

                        </b-select>

                      </b-field>

                    </ValidationProvider>

                  </div>

                  <div class="column is-half" v-if="form.otherLanguages.length > 0" ></div>

                  <div class="column is-9">

                    <div 
                      class="language-table">

                      <p 
                        class="is-size-4 has-text-left has-text-weight-semibold">
                        {{ form.englishLevels.language }}
                      </p>

                      <p 
                        class="is-size-5 has-text-weight-medium has-text-grey-dark">
                        Deseable
                      </p>
                      <p 
                        class="is-size-5 has-text-weight-medium has-text-grey-dark">
                        Básico
                      </p>

                      <p 
                        class="is-size-5 has-text-weight-medium has-text-grey-dark">
                        Intermedio
                      </p>

                      <p 
                        class="is-size-5 has-text-weight-medium has-text-grey-dark">
                        Avanzado
                      </p>

                      <p 
                        class="is-size-5 has-text-weight-medium has-text-info">
                        Representativa
                      </p>

                      <p 
                        class="is-size-5 has-text-weight-medium has-text-primary">
                        Escritura
                      </p>

                      <b-radio 
                        v-model="form.englishLevels.writing" 
                        name="englishWriting" 
                        native-value="Deseable" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.writing" 
                        name="englishWriting" 
                        native-value="Básico" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.writing" 
                        name="englishWriting" 
                        native-value="Intermedio" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.writing" 
                        name="englishWriting" 
                        native-value="Avanzado" 
                        required></b-radio>

                        <b-checkbox 
                        v-model="form.englishLevels.representativeSkill" 
                        native-value="writing" 
                        required></b-checkbox>

                      <p 
                        class="is-size-5 has-text-weight-medium has-text-primary">
                        Lectura
                      </p>

                      <b-radio 
                        v-model="form.englishLevels.reading" 
                        name="englishReading" 
                        native-value="Deseable" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.reading" 
                        name="englishReading" 
                        native-value="Básico" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.reading" 
                        name="englishReading" 
                        native-value="Intermedio" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.reading" 
                        name="englishReading" 
                        native-value="Avanzado" 
                        required></b-radio>

                      <b-checkbox 
                      v-model="form.englishLevels.representativeSkill" 
                      native-value="reading" 
                      required></b-checkbox>

                      <p 
                        class="is-size-5 has-text-weight-medium has-text-primary">
                        Escucha
                      </p>

                      <b-radio 
                        v-model="form.englishLevels.listening" 
                        name="englishListening" 
                        native-value="Deseable" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.listening" 
                        name="englishListening" 
                        native-value="Básico" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.listening" 
                        name="englishListening" 
                        native-value="Intermedio" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.listening" 
                        name="englishListening" 
                        native-value="Avanzado" 
                        required></b-radio>
                      
                      <b-checkbox 
                        v-model="form.englishLevels.representativeSkill" 
                        native-value="listening" 
                        required></b-checkbox>

                      <p 
                        class="is-size-5 has-text-weight-medium has-text-primary">
                        Conversación
                      </p>

                      <b-radio 
                        v-model="form.englishLevels.speaking" 
                        name="englishSpeaking" 
                        native-value="Deseable" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.speaking" 
                        name="englishSpeaking" 
                        native-value="Básico" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.speaking" 
                        name="englishSpeaking" 
                        native-value="Intermedio" 
                        required></b-radio>

                      <b-radio 
                        v-model="form.englishLevels.speaking" 
                        name="englishSpeaking" 
                        native-value="Avanzado" 
                        required></b-radio>

                      <b-checkbox 
                      v-model="form.englishLevels.representativeSkill" 
                      native-value="speaking" 
                      required></b-checkbox>

                    </div>

                  </div>

                  <div class="column is-9" v-if="form.otherLanguages.length > 0">

                    <div 
                      v-for="(language, index) in form.otherLanguages" 
                      :key="`language-${index}`">

                      <div 
                        class="language-table">

                        <p 
                          class="is-size-5 has-text-weight-semibold" >
                          {{ form.otherLanguages[index].language }}
                        </p>

                        <p 
                          class="is-size-5 has-text-weight-medium has-text-grey-dark">
                          Deseable
                        </p>

                        <p 
                          class="is-size-5 has-text-weight-medium has-text-grey-dark">
                          Básico
                        </p>

                        <p 
                          class="is-size-5 has-text-weight-medium has-text-grey-dark">
                          Intermedio
                        </p>

                        <p 
                          class="is-size-5 has-text-weight-medium has-text-grey-dark">
                          Avanzado
                        </p>

                        <p 
                          class="is-size-5 has-text-weight-medium has-text-info">
                          Representativa
                        </p>

                        <p 
                          class="is-size-5 has-text-left has-text-weight-medium has-text-primary">
                          Escritura
                        </p>

                        <b-radio 
                          v-model="form.otherLanguages[index].writing" 
                          :name="`lenguage${index}Writing`" 
                          native-value="Deseable" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].writing" 
                          :name="`lenguage${index}Writing`" 
                          native-value="Básico" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].writing" 
                          :name="`lenguage${index}Writing`" 
                          native-value="Intermedio" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].writing" 
                          :name="`lenguage${index}Writing`" 
                          native-value="Avanzado" 
                          required></b-radio>

                          <b-checkbox 
                            v-model="form.otherLanguages[index].representativeSkill" 
                            native-value="writing" 
                            required></b-checkbox>

                        <p 
                          class="is-size-5 has-text-left has-text-weight-medium has-text-primary">
                          Lectura
                        </p>

                        <b-radio 
                          v-model="form.otherLanguages[index].reading" 
                          :name="`lenguage${index}Reading`" 
                          native-value="Deseable" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].reading" 
                          :name="`lenguage${index}Reading`" 
                          native-value="Básico" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].reading" 
                          :name="`lenguage${index}Reading`" 
                          native-value="Intermedio" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].reading" 
                          :name="`lenguage${index}Reading`" 
                          native-value="Avanzado" 
                          required></b-radio>

                        <b-checkbox 
                          v-model="form.otherLanguages[index].representativeSkill" 
                          native-value="reading" 
                          required></b-checkbox>

                        <p 
                          class="is-size-5 has-text-left has-text-weight-medium has-text-primary">
                          Escucha
                        </p>

                        <b-radio 
                          v-model="form.otherLanguages[index].listening" 
                          :name="`lenguage${index}Listening`" 
                          native-value="Deseable" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].listening" 
                          :name="`lenguage${index}Listening`" 
                          native-value="Básico" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].listening" 
                          :name="`lenguage${index}Listening`" 
                          native-value="Intermedio" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].listening" 
                          :name="`lenguage${index}Listening`" 
                          native-value="Avanzado" 
                          required></b-radio>

                        <b-checkbox 
                          v-model="form.otherLanguages[index].representativeSkill" 
                          native-value="listening" 
                          required></b-checkbox>

                        <p 
                          class="is-size-5 has-text-left has-text-weight-medium has-text-primary">
                          Conversación
                        </p>

                        <b-radio 
                          v-model="form.otherLanguages[index].speaking" 
                          :name="`lenguage${index}Speaking`" 
                          native-value="Deseable" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].speaking" 
                          :name="`lenguage${index}Speaking`" 
                          native-value="Básico" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].speaking" 
                          :name="`lenguage${index}Speaking`" 
                          native-value="Intermedio" 
                          required></b-radio>

                        <b-radio 
                          v-model="form.otherLanguages[index].speaking" 
                          :name="`lenguage${index}Speaking`" 
                          native-value="Avanzado" 
                          required></b-radio>

                        <b-checkbox 
                          v-model="form.otherLanguages[index].representativeSkill" 
                          native-value="speaking" 
                          required></b-checkbox>

                      </div>

                    </div>

                  </div>

                  <div class="column is-half">

                    <ValidationProvider 
                      rules="required" 
                      name="Termino de Materias" 
                      v-slot=" { errors,valid } ">

                      <b-tooltip
                        style="width: 100%;"
                        label="Maximo mes de termino de materias permitido"
                        position="is-top"
                        animated>

                        <b-field
                          :label="renameField(fieldsNames.graduateDate)"
                          :type="{ 'is-danger': errors[0],'is-success': valid}"
                          :message="errors">

                          <b-datepicker
                            :placeholder="newPlaceholder(fieldsNames.graduateDate)"
                            trap-focus
                            type="month"
                            icon="calendar-range"
                            v-model="form.graduateDate">
                            </b-datepicker>

                        </b-field>

                      </b-tooltip>

                    </ValidationProvider>

                  </div>

                  <div class="column is-half">

                    <ValidationProvider 
                      rules="required" 
                      name="Titulación" 
                      v-slot="{ errors, valid }">

                      <b-tooltip
                        style="width: 100%;"
                        label="Maximo mes de titulación permitido"
                        position="is-top"
                        animated>

                        <b-field
                          :label="renameField(fieldsNames.degreeDate)"
                          :type="{ 'is-danger': errors[0],'is-success': valid}"
                          :message="errors">

                          <b-datepicker
                            trap-focus
                            :placeholder="newPlaceholder(fieldsNames.degreeDate)"
                            type="month"
                            icon="calendar-range"
                            v-model="form.degreeDate">
                            </b-datepicker>

                        </b-field>

                      </b-tooltip>

                    </ValidationProvider>

                  </div>

                </div>

              </div>

              <hr>

              <div class="column is-12">

                <div class="title is-4">
                  Habilidades y Conocimientos
                </div>

                <div class="column is-12">

                  <h1 class="title is-5">{{ renameField(fieldsNames.skills) }}</h1>

                  <h1 class="subtitle is-6">Habilidades, competencias y caracteristicas personales necesarias para la posición.</h1>

                  <div 
                    v-for="(skill, index) in form.skills" 
                    :key="`skill-${index}.`" 
                    class="columns is-multiline align-flex-end">

                      <div class="column is-5">

                        <b-field 
                          style="height: 3rem"
                          horizontal 
                          :label="`${index + 1}.`">

                          <b-input
                            :placeholder="newPlaceholder(fieldsNames.skills)"
                            icon="award" 
                            v-model="skill.name"
                            type="text"/>

                        </b-field>

                      </div>

                    <div class="column is-1 pb-20 pl-12">

                      <b-button 
                        outlined 
                        type="is-danger" 
                        icon-left="trash" 
                        @click="deleteSkill(index)" />

                    </div>

                  </div>

                  <div class="mb-15">

                    <b-button 
                      rounded 
                      type="is-primary" 
                      @click="addSkill">
                      Agregar Habilidad
                    </b-button>

                  </div>

                </div>

                <div class="column is-12">

                  <h1 class="title is-5">{{ renameField(fieldsNames.knowledges) }}</h1>

                  <h1 class="subtitle is-6">Conocimientos como paquetería, programas, equipos, etc., necesarias para la posición.</h1>
                    
                    <div 
                      v-for="(knowledge, index) in form.knowledges" 
                      :key="`${index + 1}.`" 
                      class="columns is-multiline align-flex-end">
                      <div class="column is-6">

                        <ValidationProvider 
                          rules="required" 
                          name="Conocimiento" 
                          v-slot="{ errors, valid }">

                          <b-field
                            style="height: 4rem"
                            horizontal
                            :label="`${index + 1}.`" 
                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors"
                          >
                            <b-autocomplete
                              :placeholder="newPlaceholder(fieldsNames.knowledges)"
                              icon="cube"
                              v-model="knowledge.name"
                              maxlength="64"
                              :data="filteredKnowledges"
                              :open-on-focus="true"
                              :clearable="true"
                              field="name"
                              @focus="focusedKnowledge(index)"
                              @select="option => ( selectedKnowledge = option )"
                            >
                            </b-autocomplete>

                          </b-field>

                        </ValidationProvider>

                      </div>

                      <div class="column is-5">

                        <ValidationProvider 
                        rules="required" 
                        name="Nivel conocimiento" 
                        v-slot="{ errors, valid }">

                          <b-field
                            style="height: 4rem"
                            horizontal
                            label="Nivel"
                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors"
                          >

                            <b-select
                              v-model="knowledge.level"
                              icon="signal-alt-3"
                              expanded>

                              <option
                                v-for="knowledgeLevel in knowledgeLevels"
                                :key="`${knowledgeLevel}`"
                                :value="knowledgeLevel">
                                {{ knowledgeLevel }}
                              </option>

                            </b-select>

                          </b-field>

                        </ValidationProvider>

                      </div>

                      <div class="column is-1 pb-36 pl-12">

                        <b-button 
                          outlined 
                          type="is-danger" 
                          icon-left="trash" 
                          @click="deleteKnowledge(index)" />

                      </div>
                      
                    </div>

                    <div class="mb-15">

                      <b-button rounded type="is-primary" @click="addKnowledge">
                        Agregar Conocimiento
                      </b-button>

                    </div>

                </div>

              </div>

              <hr>

              <div class="column is-12">

                <div class="title is-4 text--primary">
                  Descripción de la Práctica
                </div>

                <div class="columns is-multiline">

                  <div class="column is-6">

                    <ValidationProvider 
                      rules="required" 
                      name="Objetivo" 
                      v-slot="{ errors, valid }">

                      <b-field
                        :label="renameField(fieldsNames.goal)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">

                        <b-input
                          :placeholder="newPlaceholder(fieldsNames.goal)"
                          icon="angle-right"
                          v-model="form.goal"
                          type="textarea" maxlength="256"/>

                      </b-field>

                    </ValidationProvider>

                  </div>

                  <div class="column is-6">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <h1 class="title is-5"> {{ renameField(fieldsNames.benefit) }}</h1>
                      </div>
                      <div class="column is-12">
                        <div class="columns is-multiline">
                          <div 
                            class="column is-12 py-0 my-0"
                            v-for="(benefit, index) in form.benefits" 
                            :key="`benefit-${index}`">
                            <ValidationProvider 
                              rules="required" 
                              :name="`${index + 1}. ${form.benefits[index]}`" 
                              v-slot="{ errors, valid }">
                              <div class="is-flex align-flex-start">
                                <b-field
                                  style="width: 100%"
                                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                  :message="errors">
                                  <b-input
                                    :placeholder="newPlaceholder(fieldsNames.benefits)"
                                    icon="hand-holding-heart"
                                    v-model="form.benefits[index]" 
                                    type="text"
                                    maxlength="200"
                                  />
                                </b-field>
                                <b-button
                                  class="ml-1"
                                  type="is-danger"
                                  icon-left="trash"
                                  @click="deleteBenefit(index)">
                                </b-button>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12 is-centered pt-0">
                        <b-button
                          type="is-primary"
                          expanded
                          icon-left="plus"
                          @click="addBenefit">
                          Agregar beneficio
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <div class="column is-12">

                    <div class="columns is-multiline">

                      <div class="column is-12">
                        <h1 class="title is-4"> {{ renameField(fieldsNames.activities) }}</h1>
                      </div>

                      <div class="column is-12">

                        <div class="columns is-multiline">

                          <div 
                            class="column is-12" 
                            v-for="(activity, index) in form.activities" 
                            :key="`activity-${index}`">

                            <ValidationProvider 
                              rules="required" 
                              :name="`${index + 1}. ${form.activities[index]}`" 
                              v-slot="{ errors, valid }">

                              <div class="is-flex align-flex-end">

                                <b-field
                                  style="width: 100%"
                                  :label="`${index + 1}. ${form.activities[index]}`"
                                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                  :message="errors">

                                  <b-input
                                    :placeholder="newPlaceholder(fieldsNames.activities)"
                                    icon="clipboard-list"
                                    v-model="form.activities[index]" 
                                    type="text"/>

                                </b-field>

                                <b-button
                                  v-if="index > 2"
                                  class="mb-16 ml-1"
                                  type="is-danger"
                                  icon-left="trash"
                                  @click="removeActivity(index)">

                                </b-button>

                              </div>

                            </ValidationProvider>

                          </div>

                        </div>

                      </div>

                      <div class="column is-12 is-centered">

                        <b-button
                          type="is-primary"
                          expanded
                          @click="addActivity">
                          Agregar actividad
                        </b-button>

                      </div>

                    </div>

                  </div>

                </div>

                <hr>

                <div class="column is-12 mt-3">

                  <div class="title is-4">
                    Acerca del reclutamiento
                  </div>

                  <div class="columns is-multiline">

                    <div class="column is-6">

                        <b-field label="Esquema de requisición">

                          <b-select
                            v-model="form.schemaType"
                            icon="file-contract"
                            expanded
                          >
                            <option 
                              v-for="(schema, index) in servicesSchema" 
                              :key="`schema-${index}`"
                              :value="schema"
                            >
                              {{ schema }}

                            </option>

                          </b-select>

                        </b-field>

                    </div>

                    <div class="column is-6">

                      <b-field label="Tipo de Servicio">

                        <b-select
                          v-model="form.serviceType"
                          icon="list-radio"
                          expanded
                        >
                          <option 
                            v-for="(service, index) in recruitmentServices" 
                            :key="`service-${index}`"
                            :value="service"
                          >
                            {{ service }}
                          </option>
                        </b-select>

                      </b-field>

                    </div>

                    <div class="column is-6" v-if="form.serviceType && form.serviceType != 'Reclutamiento tradicional'">

                      <b-field label="Detalle de servicio">

                        <b-select
                          v-model="form.serviceDetail"
                          icon="game-console-handheld"
                          expanded
                        >
                          <option 
                            v-for="(detail, index) in alternativeRecruitmentServices" 
                            :key="`detail-${index}`"
                            :value="detail"
                          >
                            {{ detail }}
                          </option>
                        </b-select>

                      </b-field>

                    </div>

                    <div class="column is-6">

                      <b-field label="Tipo de reclamación">

                        <b-select
                          v-model="form.claimType"
                          icon="cards-blank"
                          expanded
                        >

                          <option 
                            v-for="(claim, index) in servicesClaims"
                            :key="`claim-${index}`"
                            :value="claim"
                          >
                            {{ claim }}
                          </option>

                        </b-select>

                      </b-field>

                    </div>

                    <template v-if="alligment">

                      <div class="column is-12">

                        <div class="title is-5">
                          Solicitante
                        </div>

                      </div>

                      <div class="column is-12">
  
                        <div class="is-flex is-justify-content-space-between is-align-items-flex-end">

                          <ValidationProvider 
                            rules="required" 
                            name="Contacto de la Empresa" 
                            v-slot="{ errors, valid }">
    
                            <b-field
                              label="Contacto de la Empresa"
                              :type="{ 'is-danger': errors[0], 'is-success': valid }"
                              :message="errors">
    
                              <b-select
                                expanded
                                v-model="selectedAuthor"
                                icon="square-user">
    
                                <option
                                  v-for="(author, index) in authors"
                                  :key="`${index}-author`"
                                  :value="author">
                                  {{ author.fullName }} | {{ roles[author.role] }}
                                </option>
    
                              </b-select>
    
                            </b-field>
    
                          </ValidationProvider>
                        
                          <b-button
                              rounded
                              type="is-primary is-light"
                              icon-right="plus"
                              class="mb-1"
                              @click="openCreateDialog"
                            >
                              Agregar contacto
                          </b-button>

                        </div>

                      </div>

                    </template>

                  </div>

                </div>

                <hr>

                <KillerQuestions ref="killerQuestions" :vacancy="id"/>

                <hr>

                <div class="column is-12 mt-3">

                  <div class="title is-4">
                    {{ renameField(fieldsNames.comments) }}
                  </div>

                  <b-field label="Comentarios, observaciones, aclaraciones y más...">

                    <b-input 
                      v-model="form.comments" 
                      type="textarea" 
                      :placeholder="newPlaceholder(fieldsNames.comments)" 
                      maxlength="512"/>

                  </b-field>

                </div>

              </div>

              </div>

              <div 
                v-if="form.otherFields.length > 0" 
                class="column is-12 ml-5 pb-5 px-2">

                <hr>

                <div class="title is-4">
                  Campos adicionales
                </div>

                <div class="columns is-multiline px-2">

                  <div 
                    :class="wichType(field)" 
                    v-for="(field, index) in form.otherFields" 
                    :key="`field-${index}`" >
                    
                    <!-- Strings -->
                    <div v-if="field.fieldType === 'string'">

                      <b-field :label="field.name">

                        <b-tooltip 
                          v-if="field.tooltip" 
                          :label="field.tooltip" 
                          :position="field.tooltipDirection">

                          <b-input 
                            v-model="field.value" 
                            v-if="field.options === 'textarea'" 
                            type="textarea" 
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :required="field.isRequired"/>

                          <b-input 
                            v-model="field.value" 
                            v-else 
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :icon="field.icon" 
                            :required="field.isRequired"/>
                        
                        </b-tooltip>

                        <div v-else>

                          <b-input 
                            v-model="field.value" 
                            v-if="field.options === 'textarea'" 
                            type="textarea" 
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :required="field.isRequired"/>

                          <b-input 
                            v-model="field.value" 
                            v-else 
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :icon="field.icon" 
                            :required="field.isRequired"/>

                        </div>

                      </b-field>

                    </div>

                    <!-- Number -->
                    <div v-if="field.fieldType === 'number'"  >

                      <b-field :label="field.name">

                        <b-tooltip 
                          v-if="field.tooltip" 
                          :label="field.tooltip" 
                          :position="field.tooltipDirection"> 

                          <b-input 
                            v-model="field.value" 
                            type="number" 
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :icon="field.icon" 
                            :required="field.isRequired"/>

                        </b-tooltip>

                        <div v-else>

                          <b-input 
                            v-model="field.value" 
                            type="number" 
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :icon="field.icon" 
                            :required="field.isRequired"/>  

                        </div>

                      </b-field>

                    </div>

                    <!-- Dates -->
                    <div v-if="field.fieldType === 'Date'"  >

                      <b-field :label="field.name">

                        <b-tooltip 
                          v-if="field.tooltip" 
                          :label="field.tooltip" 
                          :position="field.tooltipDirection">

                          <b-datepicker 
                            v-model="field.rangeDates" 
                            :type="field.options === 'month' ? 'month' : null" 
                            range 
                            v-if="field.options === 'range'"
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :icon="field.icon" :unselectable-days-of-week="[0,6]" 
                            :required="field.isRequired">
                          </b-datepicker>

                          <b-datepicker 
                            v-model="field.value" 
                            :type="field.options === 'month' ? 'month' : null"  
                            v-else
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :icon="field.icon" 
                            :unselectable-days-of-week="[0,6]" 
                            :required="field.isRequired">
                          </b-datepicker>

                        </b-tooltip>

                        <div v-else>

                          <b-datepicker v-model="field.rangeDates" 
                            :type="field.options === 'month' ? 'month' : null" 
                            range 
                            v-if="field.options === 'range'"
                            :disabled="!field.isActive"  
                            :placeholder="field.placeholder" 
                            :icon="field.icon" 
                            :unselectable-days-of-week="[0,6]" 
                            :required="field.isRequired">
                          </b-datepicker>

                          <b-datepicker 
                            v-model="field.value" 
                            :type="field.options === 'month' ? 'month' : null"  
                            v-else
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :icon="field.icon" 
                            :unselectable-days-of-week="[0,6]" 
                            :required="field.isRequired">
                          </b-datepicker>

                        </div>

                      </b-field>

                    </div>

                    <!-- Boolean -->
                    <div v-if="field.fieldType === 'boolean'"  >

                      <b-field :label="field.name">

                        <b-tooltip 
                          v-if="field.tooltip" 
                          :label="field.tooltip" 
                          :position="field.tooltipDirection">

                          <b-switch 
                            v-model="field.value" 
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :required="field.isRequired">
                          </b-switch>

                          <b-icon 
                            :icon="field.icon" 
                            :type="field.value ? 'is-primary' : ''">
                          </b-icon>

                        </b-tooltip>

                        <div v-else>

                          <b-switch 
                            v-model="field.value" 
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :required="field.isRequired">
                          </b-switch>

                          <b-icon 
                            :icon="field.icon" 
                            :type="field.value ? 'is-primary' : ''">
                          </b-icon>

                        </div>

                      </b-field>

                    </div>

                    <!-- tags Array -->
                    <div 
                      v-if="field.fieldType === 'Array'" 
                      style="width: fit-content;">

                      <b-field :label="field.name">

                        <b-icon :icon="field.icon"></b-icon>

                        <b-tooltip 
                          v-if="field.tooltip" 
                          :label="field.tooltip" 
                          :position="field.tooltipDirection">

                          <div style="display: grid; grid-template-columns: repeat(2, 1fr)">

                            <b-checkbox 
                              class="mb-2" 
                              v-model="field.value" 
                              v-for="(option,index) in field.options" 
                              :key="`option1-${index}`" 
                              :native-value="option" 
                              :disabled="!field.isActive" 
                              :required="field.isRequired">
                              {{ option }}
                            </b-checkbox>

                          </div>

                        </b-tooltip>

                        <div v-else>

                          <div style="display: grid; grid-template-columns: repeat(2, 1fr)">

                            <b-checkbox 
                              class="mb-2" 
                              v-model="field.value" 
                              v-for="(option,index) in field.options" 
                              :key="`option2-${index}`" 
                              :native-value="option" 
                              :disabled="!field.isActive" 
                              :required="field.isRequired">
                              {{ option }}
                            </b-checkbox>

                          </div>

                        </div>

                      </b-field>

                    </div>

                    <!-- select Array -->
                    <div v-if="field.fieldType === 'Select'"  >

                      <b-field :label="field.name">

                        <b-tooltip 
                          v-if="field.tooltip" 
                          :label="field.tooltip" 
                          :position="field.tooltipDirection">

                          <b-select 
                            v-model="field.value" 
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :icon="field.icon" 
                            expanded 
                            :required="field.isRequired">

                            <option
                              v-for="(option, index) in field.options"
                              :value="option"
                              :key="`option-${index}`">
                              {{ option }}
                            </option>

                          </b-select>

                        </b-tooltip>

                        <div v-else>

                          <b-select 
                            v-model="field.value" 
                            :disabled="!field.isActive" 
                            :placeholder="field.placeholder" 
                            :icon="field.icon" 
                            expanded 
                            :required="field.isRequired">

                            <option
                              v-for="(option, index) in field.options"
                              :value="option"
                              :key="`option3-${index}`">
                              {{ option }}
                            </option>

                          </b-select>

                        </div>

                      </b-field>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </ValidationObserver>

        </div>

        <div class="column is-4 relative">

          <div 
            class="card sticky is--top75 mt-3" 
            style="border-radius: 15px;">

            <div class="card-content">

              <div class="title is-4">
                Guardar cambios
              </div>

              <div v-if="isMexico">
                <div class="subtitle is-6">
                  Ingresa manualmente el ID de requisición de Monday.
                </div>

                <div>
                  <b-field
                    label="ID de requisición"
                  > 
                    <b-input
                      icon="barcode"
                      placeholder="XXXXXXXXXX" maxlength="10" has-counter
                      v-model="form.projectMondayId"
                    />
                  </b-field>

                </div>
              </div>

              <div class="card-content" v-else>
                <div class="subtitle is-6">
                  Revisa adecuadamente los campos antes de guardar los cambios.
                </div>
              </div>

              <div>
                
                <b-button
                  expanded
                  rounded
                  type="is-primary"
                  @click="updateVacancy">
                  Guardar
                </b-button>
                
              </div>
              
            </div>
            
          </div>
          
        </div>
        
      </div>

      <UserCreate 
        contactUsers 
        :company="companyId" 
        @updated="getAuthors" 
        ref="create"/>
      
    <b-loading :active.sync="isLoading" />
    
  </div>
</template>

<script>
import states from '../lib/states'
import cities from '../lib/cities'
import provinces from '../lib/ES/provinces'
import municipalities  from '../lib/ES/municipalities'
import { mapGetters } from 'vuex'
import UserCreate from '../components/modals/UserCreate.vue'
import KillerQuestions from '../components/KillerQuestions.vue'
import servicesSchema from '../lib/servicesSchema'
import servicesClaims from '../lib/servicesClaims'
import days from '../lib/days'
import alternativeRecruitmentServices from '../lib/alternativeRecruitmentServices'
import recruitmentServices from '../lib/recruitmentServices'
import schedules from '../lib/schedules'
import academicLevels from '../lib/academicLevels'
import semesters from '../lib/semesters'
import modalities from '../lib/modalities'
import durationOptions from '../lib/durationOptions'
import knowledgeLevels from '../lib/knowledgeLevels'
import { vacancyManagementAllowedRoles } from '../lib/permissions'

export default {
  components: {
    UserCreate,
    KillerQuestions
  },
  data () {
    return {
      isLoading: false,
      vacancyManagementAllowedRoles,
      states,
      cities,
      provinces,
      municipalities,
      knowledgeLevels,
      form: {
        name: null,
        duration: null,
        places: 1,
        location: {
          state: null,
          city: null,
        },
        schedule: null,
        showScholarship: false,
        scholarship: null,
        modality: null,
        degree: null,
        academicLevel: [],
        minimumSemester: 0,
        maximumSemester: 0,
        excelLevel: null,
        englishLevels: {
          language: 'Inglés',
          reading: null,
          listening: null,
          speaking: null,
          writing: null,
          representativeSkill: [],
        },
        otherLanguages: [],
        graduateDate: null,
        degreeDate: null,
        skills: [],
        knowledges: [],
        goal: null,
        benefits: [],
        benefit: '',
        activities: ['','',''],
        author: null,
        project: null,
        comments: null,
        mondayVacancyId: null,
        projectMondayId: null,
        schemaType: null,
        serviceType: null,
        serviceDetail: null,
        claimType: null,
        projectId: null,
        otherFields: [],
      },
      servicesSchema,
      recruitmentServices,
      servicesClaims,
      alternativeRecruitmentServices,
      phoneCallDate: null,
      file: null,
      noRounded: false,
      citySelected: null,
      stateSelected: null,
      provinceSelected: null,
      municipalitySelected: null,
      authors: [],
      selectedAuthor: null,
      levels: [
        'Deseable',
        'Básico',
        'Intermedio',
        'Avanzado',
      ],
      durationOptions,
      modalities,
      roles: {
        hrmanager: 'Manager de RH',
        solicitant: 'Solicitante'
      },
      academicLevels,
      schedules,
      days,
      semesters,
      fieldsNames:{
        name: 'Nombre de la vacante',
        duration: 'Duración',
        places: 'Número de Posiciones',
        state: 'Estado',
        city: 'Ciudad',
        schedule: 'Horario',
        showScholarship: 'Mostrar Beca',
        scholarship: 'Beca',
        modality: 'Modalidad',
        degree: 'Licenciatura(s)',
        academicLevel: 'Nivel académico',
        minimumSemester: 'Semestre Mínimo',
        maximumSemester: 'Semestre Máximo',
        excelLevel: 'Nivel de Excel',
        englishLevel: 'Nivel de Inglés',
        graduateDate: 'Termino de Materias',
        degreeDate: 'Titulación',
        skills: 'Habilidades',
        knowledges: 'Conocimientos',
        goal: 'Objetivo',
        benefit: 'Beneficios',
        activities: 'Actividades Diarias',
        author: 'Concato de la empresa',
        authorName: 'Nombre',
        authorEmail: 'Correo electrónico',
        authorPhone: 'Teléfono',
        project: 'Proyecto',
        comments: 'Comentarios',
      },
      esSemesters: [
        { text: 'Recién graduado', value: 0 },
        { text: 'Primer curso', value: 1 },
        { text: 'Segundo curso', value: 2 },
        { text: 'Tercer curso', value: 3 },
        { text: 'Cuarto curso', value: 4 },
        { text: 'Quinto curso', value: 5 },
        { text: 'Sexto curso', value: 6 },
      ],
      renamedFields: [],
      company: null,
      maxHour: new Date(),
      knowledges: [],
      knowledgeIndex: 0,
      selectedKnowledge: '',
      availableProjects: [],
      selectedProject: null,
      project: null
    }
  },
  watch: {
    async file (val) {
      const formData = new FormData()
      if (val) {
        this.isLoading = true
        try {
          formData.append('file', val)
          const response = await this.$api.post('/upload', formData,{
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          this.form.project = response.data
          this.$buefy.toast.open({
            message: 'El archivo se subió con éxito',
            type: 'is-success'
          })
        } catch (error) {
          this.$buefy.toast.open({
            message: !error.response ? error : error.response.data.message,
            type: 'is-danger'
          })
        }
        this.isLoading = false
      }
    },
    selectedAuthor (val) {
      if(val){
        this.form.author = val.id
      }
    },
    stateSelected(){
      if(this.stateSelected.id == '00' && this.isSpain){
        this.citySelected = this.provincesMunicipalities[0]
      }
    },
    selectedProject(val){
      if(val){
        this.form.projectMondayId = val.projectMondayId
        this.form.projectId = val.projectId
      }
    }
  },
  computed: {
    ...mapGetters(['companyId']),
    user () {
      return this.$store.state.session.user
    },
    id () {
      return this.$route.params.id
    },
    alligment(){
      return  this.$route.query.alligment
    },
    getReturningPageParams(){
      if (this.$route.query.from == 'projects'){
        return { name: 'Projects', params: { id: this.company?.id  }, query: { project: this.form.projectId}}
      }
      else {
        return { name: 'Vacancies', params: { id: this.company?.id } }
      }
    },
    stateCities () {
      if (this.stateSelected) {
        const state = this.states.find(st => st.id === this.stateSelected.id)
        const cities = [...this.cities]
          .filter(city => city.state_id === state.id)
          .sort((a, b) => {
            const nameA = this.replaceAccent(a.name)
            const nameB = this.replaceAccent(b.name)
            if (nameA > nameB) {
              return 1
            }
            if (nameA < nameB) {
              return -1
            }
            return 0
          })
        return cities
      }
      return []
    },
    provincesMunicipalities() {
      const provinces = this.stateSelected
        ? this.municipalities.filter(item => item.state_id == this.stateSelected.id)
            .sort((a, b) => {
              const nameA = this.replaceAccent(a.name);
              const nameB = this.replaceAccent(b.name);
              if (nameA > nameB) {
                return 1;
              }
              if (nameA < nameB) {
                return -1;
              }
              return 0;
            })
        : [];

      return provinces;
    },
    disabledCompleteButton(){
      return  this.form.schedule && this.form.schedule.detail.monday[0].startHour 
              && this.form.schedule.detail.monday[0].endHour 
              ? true : false
    },
    filteredKnowledges () {
      if(this.form.knowledges[this.knowledgeIndex].name){
        return this.knowledges.filter( knowledge => {
          if(this.knowledgeIndex >= 0){
            return ( knowledge.name.toLowerCase()
                  .indexOf( this.form.knowledges[this.knowledgeIndex].name.toLowerCase() ) ) >=0
          }
        })
      }else return this.knowledges
    },
    canManageAllFields () {
      return (this.$hasPermissionsByRole(vacancyManagementAllowedRoles))
    },
    isMexico(){
      return process.env.VUE_APP_LOCATION  === 'mx'
    },
    isSpain(){
      return process.env.VUE_APP_LOCATION === 'es'
    },
  },
  async mounted () {
    await this.getAuthors()
    await this.getVacancy()
    await this.getRenamedFields()
    await this.getKnowledges()
  },
  methods: {
    async getVacancy () {
      const { data } = await this.$api.get(`/vacancies/${this.id}`)
      data.otherFields.forEach(field => {
        if(field.fieldType === 'Date'){
          field.value = new Date(field.value)
        }
      })
      this.form = {
        name: data.name,
        duration: data.duration,
        places: data.places,
        location: {
          state: data.location ? data.location.state : '',
          city: data.location ? data.location.city : ''
        },
        schedule: data.schedule,
        showScholarship: data.showScholarship,
        scholarship: data.scholarship,
        modality: data.modality,
        degree: data.degree,
        academicLevel: data.academicLevel,
        minimumSemester: data.minimumSemester,
        maximumSemester: data.maximumSemester,
        excelLevel: data.excelLevel,
        englishLevels: data.englishLevels,
        otherLanguages: data.otherLanguages,
        graduateDate: data.graduateDate ? new Date(data.graduateDate) : null,
        degreeDate: data.graduateDate ? new Date(data.degreeDate) : null,
        skills: data.skills,
        goal: data.goal,
        benefits: data.benefits,
        activities: data.activities,
        comments: data.comments,
        otherFields: data.otherFields,
        mondayVacancyId: data.mondayVacancyId,
        projectMondayId: data.projectMondayId,
        schemaType: data.schemaType,
        serviceType: data.serviceType,
        serviceDetail: data.serviceDetail,
        claimType: data.claimType,
        author: data.author,
        knowledges: data.newKnowledges,
        projectId: data.projectId
      }

      if(data.projectId){
        await this.getProject(data.projectId)
      }
      
      if(!this.form.englishLevels){
        this.form.englishLevels = {
          language: 'Inglés',
          listening: data.englishLevel,
          reading: data.englishLevel,
          speaking: data.englishLevel,
          writing: data.englishLevel,
          representativeSkill: []
        }
      }

      if(!data.benefits?.length > 0){
        this.form.benefits.push(data.benefit)
      }

      if(data.newKnowledges.length > 0){
        this.form.knowledges = data.newKnowledges.map( ({ knowledge, level }) => {
        return {
            name: knowledge.name,
            level: level.level
          }
        })
      }else this.form.knowledges = data.knowledges

      this.selectedAuthor = this.authors.find(item => item.id === this.form.author)

      this.company = data.company

      if(this.isMexico){
        this.stateSelected = states.find(state => state.name === data.location.state)
        this.citySelected = cities.find(city => city.name === data.location.city)
      }else{
        this.stateSelected = provinces.find(item => item.name === data.location.state)
        this.citySelected = municipalities.find(item => item.name === data.location.city)
      }
      
    },
    async updateVacancy () {
      const valid = await this.$refs.observer.validate()
      // const lastValid = await this.$refs.lastObserver.validate()
      // if (valid && lastValid) { Descomentar posteriormente para hacer obligatorio id de monday
      if (valid) {
        this.isLoading = true
        this.form.location = {
          state: this.stateSelected.name,
          city: this.citySelected.name
        }

        try {
          // delete this.form.projectId

          if(this.alligment) await this.setPhoneCallDate()

          await this.$api.put(`/vacancies/${this.id}`, this.form)
          
          this.$buefy.toast.open({
            message: 'Los cambios se guardaron correctamente',
            type: 'is-success'
          })

          await this.$refs.killerQuestions.updateKillerQuestion()

          this.$router.push(this.getReturningPageParams)
        } catch (error) {
          this.$buefy.toast.open({
            message: !error.response ? error : error.response.data.message,
            type: 'is-danger'
          })
        }
          this.isLoading = false

      }else{
        this.$buefy.toast.open({
          message: 'Uno o más campos están incompletos.',
          type: 'is-danger'
        })
      }
    },
    async setPhoneCallDate(){
      if(!this.phoneCallDate) this.phoneCallDate =  new Date()
      const payload = {
        startDate: this.phoneCallDate,
        endDate: this.phoneCallDate
      }
      try {
        await this.$api.post(`vacancies/${this.id}/set-phone-call`, payload)
        this.$buefy.toast.open({
          message: 'Se agregó la fecha de llamada de alineación',
          type: 'is-success',
          duration: 4000
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
    },
    async getKnowledges (){
      try{
        let { data } = await this.$api.get(`knowledges`)
        if(data.length > 0){
          this.knowledges = data.map( item => {
            return { name: item.name  }
          })
        }
      }catch(error){
        this.$buefy.toast.open({
          message: 'Error al buscar conocimientos',
          type: 'is-danger'
        })
      }
    },
    async getAuthors () {
      const solicitants = await this.$api.get(`/companies/${this.companyId}/solicitants`)
      const hrmanagers = await this.$api.get(`/companies/${this.companyId}/hr-managers`)
      this.authors = [ ...solicitants.data, ...hrmanagers.data]
    },
    async getProject(id){
      try{
        const { data } = await this.$api.get(`/companies/${this.companyId}/projects/detail/${id}`)
        if(data){
          this.project = data
        }
      }catch(error){
        this.$buefy.toast.open({
            message: 'No se encontro el proyecto previamente asociado',
            type: 'is-warning'
          })
      }
    },
    verifyDaySchedule({ startHour, endHour}){
      if(startHour && endHour){
        if(!this.verifySchedule(startHour, endHour)){
          this.$buefy.toast.open({
            message: 'La hora de salida debe ser mayor a la hora de entrada',
            type: 'is-warning'
          })
        }
      }
    },
    focusedKnowledge(index){
      this.knowledgeIndex = index
    },
    openCreateDialog () {
      this.$refs.create.open()
    },
    async getAvailableProjects(){
      try{
        const { data } = await this.$api.get(`/companies/${this.company.id}/projects/availables`)
        this.availableProjects = data
      }catch(error){
        error
      }
    },
    verifySchedule(start, end){
      const [ startHour, startMinutes ] = start.split(':')
      const [ endHour, endMinutes ] = end.split(':')

      const startTotal = parseInt(startHour * 60 + startMinutes)
      const endTotal = parseInt(endHour * 60 + endMinutes)
      
      return endTotal > startTotal
    },
    completeSchedule(){
      const startHour = this.form.schedule.detail.monday[0].startHour
      const endHour = this.form.schedule.detail.monday[0].endHour
      if(this.verifySchedule(startHour, endHour)){
        Object.keys(this.form.schedule.detail).forEach( day => {
          this.form.schedule.detail[day].pop()
          this.form.schedule.detail[day].push({
            startHour: startHour,
            endHour: endHour
          })
        })
      }else{
        this.$buefy.toast.open({
          message: 'La hora de salida debe ser mayor a la hora de entrada',
          type: 'is-warning'
        })
      }
    },
    addActivity () {
      this.form.activities.push('')
    },
    removeActivity (index) {
      this.form.activities.splice(index)
    },
    addSkill () {
      this.form.skills.push({
        name: ''
      })
    },
    deleteSkill (index) {
      this.form.skills.splice(index, 1)
    },
    addKnowledge () {
      if(this.form.knowledges.length < 10){
        this.form.knowledges.push({
          name: '',
          level: ''
        })
      }else this.$buefy.toast.open({
        message: 'Límite máximo de conocimientos alcanzado',
        type: 'is-warning'
      })
    },
    deleteKnowledge (index) {
      this.form.knowledges.splice(index, 1)
    },
    addBenefit(){
      this.form.benefits.push('')
    },
    deleteBenefit(index){
      this.form.benefits.splice(index, 1)
    },
    replaceAccent (term) {
      if (term) {
        const accents = /á|é|í|ó|ú/
        const word = term.toLowerCase()
        return word.replace(accents, (s) => {
          if (s === 'á') return 'a'
          if (s === 'é') return 'e'
          if (s === 'í') return 'i'
          if (s === 'ó') return 'o'
          if (s === 'ú') return 'u'
        })
      }
      return term
    },
    wichType(field){
      if(field.options === 'default' ) return 'column is-4'
      if(field.options === 'textarea' ) return 'column is-6'
      if(field.fieldType === 'number' ) return 'column is-4'
      if(field.fieldType === 'Date' ) return 'column is-4'
      if(field.fieldType === 'boolean' ) return 'column is-2'
      if(field.fieldType === 'Array' ) {
        return field.options.length > 4 ? 'column is-6' : 'column is-4'
      }
      if(field.fieldType === 'Select' ) return 'column is-4'
    },
    async getRenamedFields(){
      const { data } = await this.$api.get(`aggregate-fields/renamedFields/${this.companyId}`)
      this.renamedFields = data
    },
    renameField(actualName){
      /* Adapting ES messages */
      if (this.isSpain){
        for (const fieldKey in this.fieldsNames){ // Looking for key
          if (this.fieldsNames[fieldKey] === actualName){
            if (this.$te(`screens.vacancies.edit.${fieldKey}`)) return this.$t(`screens.vacancies.edit.${fieldKey}`)
          }
        }
      }
      
      let newNamed = null
      this.renamedFields.forEach( field => {
        if( actualName === field.actualName){
          newNamed = field.newName
        }
      })
      return newNamed ? newNamed : actualName
    },
    newPlaceholder(actualName){
      let newPlaceholder = null
      this.renamedFields.forEach( field =>{
        if( actualName === field.actualName){
          newPlaceholder = field.newPlaceholder
        }
      })
      return newPlaceholder ? newPlaceholder : ''
    }
  }
}
</script>

<style scoped>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";
  .language-table{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    align-items: center;
    row-gap: 1rem;
    column-gap: 0.5rem
  }
</style>