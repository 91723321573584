import VacancyDetail from '@/screens/VacancyDetail'
import VacancyEdit from '@/screens/VacancyEdit'
import VacancyCreate from '@/screens/VacancyCreate'
import VacancyCandidates from '@/screens/VacancyCandidates'
import VacancyPhoneCall from '@/screens/VacancyPhoneCall'
import ConfirmPhoneCall from '@/screens/ConfirmPhoneCall'
import CandidateDetail from '@/screens/CandidateDetail'
import InterviewCompany from '@/screens/InterviewCompany'
import InterviewPromeritum from '@/screens/InterviewPromeritum'

const recruitment = [
  {
    path: '/vacante',
    component: {
      render: r => r('router-view')
    },
    name: 'Vacancy',
    redirect: { name: 'VacancyDetail' },
    children: [
      {
        path: 'nueva',
        component: VacancyCreate,
        name: 'VacancyCreate',
        meta: {
          requireAuth: true,
          permissions: ['solicitant', 'hrmanager', 'superqueen', 'recruiter', 'recruiter-manager', 'manager']
        }
      },
      {
        path: ':id',
        component: VacancyDetail,
        name: 'VacancyDetail',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager']
        }
      },
      {
        path: ':id/llamada-de-alineacion',
        name: 'VacancyPhoneCall',
        component: VacancyPhoneCall,
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager']
        }
      },
      {
        path: ':id/edit',
        component: VacancyEdit,
        name: 'VacancyEdit',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'manager']
        }
      },
      {
        path: ':id/candidatos',
        name: 'VacancyCandidates',
        component: VacancyCandidates
      }
    ]
  },
  {
    path: '/confirmar-llamada/:id',
    name: 'ConfirmPhoneCall',
    component: ConfirmPhoneCall,
  },
  {
    path: '/candidato',
    name: 'Candidate',
    component: {
      render: r => r('router-view')
    },
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager']
    },
    children: [
      {
        path: ':id',
        name: 'CandidateDetail',
        component: CandidateDetail,
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager']
        }
      },
      {
        path: ':id/entrevista-empresa',
        name: 'InterviewCompany',
        component: InterviewCompany,
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager']
        }
      },
      {
        path: ':id/entrevista-promeritum',
        name: 'InterviewPromeritum',
        component: InterviewPromeritum,
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'manager']
        }
      }
    ]
  }
]

export default recruitment