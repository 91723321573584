<template>
  <div class="container">
    <div
      class="columns is-multiline"
      v-if="data._id"
    >
      <!-- Banner de candidato -->
      <div class="column is-full mt-4">
        <div class="columns">
          <div class="column is-half">
            <h1 class="title has-text-primary">
              {{ data.profile.firstName }} {{ data.profile.firstSurname }} {{ data.profile.lastSurname }}
            </h1>
          </div>

          <div class="column is-half">
            <div class="is-flex justify-flex-end">
              <b-button
                :to="{ name: 'VacancyCandidates', params: { id: data.vacancy._id }, query: query }"
                icon-left="arrow-left"
                rounded
                tag="router-link"
                type="is-primary"
              >
                Regresar
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Banner de vacante y aplicacion -->
      <div class="column is-full py-0">
        <h2 class="title is-4 has-text-blue m-0">Vacante: {{ data.vacancy.name }}</h2>
        <h2 class="title m-0">
          <b-tag
            rounded
            type="is-info is-light"
          >
            Reclutamiento: {{ data.typeCustomRecruitment ?? "Normal" }}
          </b-tag>
          <b-tag
            class="ml-2"
            rounded
            type="is-primary is-light"
          >
            {{ statuses[data.status] }}
          </b-tag>
        </h2>
      </div>

      <!-- Descarga de perfil -->
      <div class="column is-full">
        <div>
          <b-button
            @click="$refs.downloadProfile.open()"
            rounded
            type="is-primary"
          >
            Descargar en PDF
          </b-button>
        </div>

        <DownloadCandidateProfile
          :application="data"
          ref="downloadProfile"
        />
      </div>

      <!-- Contenedor de informacion -->
      <div class="column is-11">
        <!-- Informacion de candidato y proceso -->
        <div class="columns">
          <!-- Informacion de candidato -->
          <div class="column is-7">
            <!-- Perfil de candidato -->
            <CandidateProfile
              :application="data"
              :email="user.email"
            />

            <hr />

            <!-- KillerQuestions -->
            <div
              class="card is-shadowless"
              style="border-radius: 30px"
            >
              <div v-if="hasKillerQuestions">
                <KillerQuestionsAnswers
                  :application="data._id"
                  :vacancy="data.vacancy._id"
                  @hasKillerQuestions="showKillerQuestions"
                />
              </div>
            </div>

            <hr v-if="$hasPermissionsByRole(['superqueen', 'leader-recruiter-manager', 'recruiter-manager', 'recruiter']) &&
                data.promeritumInterviewSent"
              />

            <!-- Fat 6 -->
            <div
              v-if="
                $hasPermissionsByRole(['superqueen', 'leader-recruiter-manager', 'recruiter-manager', 'recruiter']) &&
                data.promeritumInterviewSent
              "
              class="card is-shadowless pl-3 pr-3 pt-2 pb-2"
              style="border-radius: 30px"
            >
              <!-- Titulo -->
              <div class="column is-full has-text-blue is-flex is-align-items-end">
                <p class="is-size-4 has-text-weight-semibold">{{ labels.characterProfile }}</p>
              </div>

              <!-- Contenedor de informacion -->
              <div class="card-content">
                <!-- Fat 6 contestado -->
                <div v-if="data.promeritumInterviewDone">
                  <CandidateProMeritumInterview :form="data.form" />
                </div>

                <!-- Formulario Fat 6 -->
                <div v-else>
                  <ProMeritumInterview @update="getCandidate" />
                </div>
              </div>
            </div>

            <hr />

            <!-- Custom evaluation  -->
            <div
              v-if="$hasPermissionsByRole(['superqueen', 'leader-recruiter-manager', 'recruiter-manager', 'recruiter'])"
              class="card is-shadowless pl-3 pr-3 pt-2 pb-2"
              style="border-radius: 30px"
            >
              <!-- Banner custom evaluation  -->
              <div class="column is-full has-text-blue is-flex is-align-items-end">
                <!-- titulo -->
                <p class="is-size-4 has-text-weight-semibold mr-2">{{ labels.customEvaluation }}</p>

                <!-- Icono guardar cambios de customevaluation -->
                <a
                  v-if="editingCustomEvaluation"
                  class="is-flex"
                  @click="sendCustomEvaluation"
                >
                  <b-tooltip
                    animated
                    class="is-flex is-align-items-end"
                    label="Guardar cambios"
                    position="is-top"
                    type="is-success"
                  >
                    <b-icon
                      class="icons"
                      icon="floppy-disk"
                    ></b-icon>
                  </b-tooltip>
                </a>

                <!-- Icono editar customEvaluation -->
                <a
                  v-else
                  @click="editingCustomEvaluation = true"
                >
                  <b-tooltip
                    animated
                    class="is-flex is-align-items-end"
                    :label="`Editar ${labels.customEvaluation}`"
                    position="is-top"
                    type="is-success"
                  >
                    <b-icon
                      b-icon
                      class="icons"
                      icon="pen-to-square"
                    ></b-icon>
                  </b-tooltip>
                </a>
              </div>

              <!-- Contenedor componentes custom Evaluation  -->
              <div class="card-content">
                <!-- Detalle customEvaluation -->
                <div v-if="!editingCustomEvaluation">
                  <CandidateCustomEvaluation :customEvaluation="customEvaluation" />
                </div>

                <!-- Formulario customEvaluation -->
                <div v-else>
                  <CustomEvaluationForm ref="CustomEvaluationForm" />
                </div>
              </div>
            </div>

            <!-- Feedback de empresa -->
            <hr>

            <div> 
              <CandidateFeedback
                v-if="data.companyInterviewDone || !$hasPermissionsByRole(externalRoles)"
                :companyInterviewFeedback="data.companyInterviewFeedback" 
              />
              <CandidateFeedbackForm
                v-else
                @doneCompanyInterview="sendCompanyInterview"
              />
            </div>

            <!-- Hirind de candidato -->
            <hr v-if="data.hiring" />

            <div v-if="data.hiring">
              <CandidateHiring :hiring:="data.hiring" />
            </div>

            <hr />
          </div>

          <!-- Timeline  de aplicaciones -->
          <div
            v-if="['superqueen', 'recruiter-manager', 'recruiter'].includes($isRole(user))"
            class="column is-3 pr-2"
          >
            <CandidateTimeLine :timeline="timeline" />
          </div>
        </div>
      </div>

      <!-- Talentbar -->
      <div class="column">
        <TalentBar
          v-if="['superqueen', 'recruiter', 'recruiter-manager'].includes($isRole(user))"
          :candidate="data"
          @candidateUpdated="getCandidate"
        />
      </div>
    </div>

    <div v-else>
      <b-loading />
    </div>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
  import DownloadCandidateProfile from "@/components/modals/DownloadCandidateProfile";
  import KillerQuestionsAnswers from "../components/KillerQuestionsAnswers";
  import TalentBar from "@/components/TalentBar";
  import CandidateProfile from "@/components/Candidate/CandidateProfile.vue";
  import CandidateProMeritumInterview from "@/components/Candidate/CandidateProMeritumInterview.vue";
  import ProMeritumInterview from "@/components/Forms/ProMeritumInterview.vue";
  import CandidateCustomEvaluation from "@/components/Candidate/CandidateCustomEvaluation.vue";
  import CandidateFeedback from "@/components/Candidate/CandidateFeedback.vue";
  import CandidateFeedbackForm from "../components/Forms/CandidateFeedbackForm.vue";
  import CandidateTimeLine from "@/components/Candidate/CandidateTimeLine.vue";

  import {externalRoles} from "@/lib/permissions";
  import { getCandidate, sendCustomEvaluation, sendCompanyFeedback } from "../api/candidate";

  export default {
    components: {
      DownloadCandidateProfile,
      KillerQuestionsAnswers,
      CandidateProfile,
      TalentBar,
      CandidateProMeritumInterview,
      ProMeritumInterview,
      CandidateCustomEvaluation,
      CandidateFeedback,
      CandidateFeedbackForm,
      CandidateTimeLine,
      CustomEvaluationForm: () => import("@/components/Forms/CustomEvaluationForm.vue"),
      CandidateHiring: () => import("@/components/Candidate/CandidateHiring.vue"),
    },
    data() {
      return {
        externalRoles,
        isLoading: false,
        vacancy: null,
        data: {},
        statuses: {
          applying: "En aplicación",
          selected: "Seleccionad@",
          sent: "En revisión con la empresa",
          rejected: "Rechazad@",
          "pre-hired": "Seleccionad@ por la empresa",
          hired: "Contratad@",
        },
        timeline: [],
        editingCustomEvaluation: false,
        hasKillerQuestions: true,
      };
    },
    computed: {
      user() {
        return this.$store.state.session.user;
      },
      id() {
        return this.$route.params.id;
      },
      query() {
        return this.$route.query;
      },
      labels() {
        return this.$t("screens.candidate.detail");
      },
      customEvaluation() {
        return {
          applicationLinkVideo: this.data.profile.applicationLinkVideo || "",
          applicationFile: this.data.profile.applicationFile || null,
          aditionalComments: this.data.profile.aditionalComments || "",
        };
      },
    },
    watch: {
      id() {
        this.getCandidate();
      },
    },
    mounted() {
      this.getCandidate();
      this.wasSeen();
    },
    methods: {
      async getCandidate() {
        this.isLoading = true;
        const candidateData = await getCandidate(this.id);
        this.data = candidateData;
        this.data.profile.email = candidateData.user.email
        this.timeline = candidateData.timeline;
        this.isLoading = false;
      },
      buildCustomEvaluationPayload() {
        return {
          applicationLinkVideo: this.$refs.CustomEvaluationForm.form.applicationLinkVideo,
          applicationFile: this.$refs.CustomEvaluationForm.form.applicationFileMetadata,
          aditionalComments: this.$refs.CustomEvaluationForm.form.aditionalComments,
        };
      },
      async sendCustomEvaluation() {
        this.isLoading = true;

        const payload = this.buildCustomEvaluationPayload();
        await sendCustomEvaluation(this.id, payload);
        await this.getCandidate();
        this.editingCustomEvaluation = false;

        this.isLoading = false;
      },
      async sendCompanyInterview(payload) {
      this.$buefy.dialog.confirm({
        title: `¿Cerrar la entrevista?`,
        message: `Al cerrarla, tu feedback del candidato no podrá ser editado, asegúrate de haber escrito
                un feedback completo de tu candidato.`,
        confirmText: "Ok, estoy de acuerdo",
        cancelText: "Ooops, mejor no",
        onConfirm: async () =>{
            this.isLoading = true;
            try {
              await sendCompanyFeedback(this.id, payload);
              await this.getCandidate();

            } catch (error) {
              console.log(error);
              this.$buefy.toast.open({
                message: "Hubo un error al enviar el feedback",
                type: "is-danger",
              });
            } finally{
              this.isLoading = false;
            }
          },
        });
      },
      showKillerQuestions(value) {
        this.hasKillerQuestions = value;
        this.data.profile.hasKillerQuestions = value;
      },
      async wasSeen() {
        this.isLoading = true;
        try {
          await this.$api.post(`application/${this.id}/set-seen-true`);
        } catch (error) {
          this.$buefy.toast.open({
            message: "Hubo un error al marcar como evaluado al candidato",
            type: "is-danger",
          });
        }
        this.isLoading = false;
      },
      academicLevel(level) {
        if (level[0] === "student") return "Estudiante";
        if (level[0] === "recently-graduated") return "Recién graduado";
        if (level[0] === "experienced-graduated") return "Graduado con experiencia";
      },
    },
  };
</script>
