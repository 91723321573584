import Login from '@/screens/auth/Login'
// import Register from '@/screens/auth/Register'
import Invite from '@/screens/auth/Invite'
import SetPassword from '@/screens/auth/SetPassword'
import RecoveryPassword from '@/screens/auth/RecoveryPassword'

const auth = [
  {
    path: '/login',
    component: Login,
    name: 'Login'
  },
  // {
  //   path: '/register',
  //   component: Register,
  //   name: 'Register'
  // },
  {
    path: '/invite-admin',
    component: Invite,
    name: 'Invite'
  },
  {
    path: '/set-password',
    component: SetPassword,
    name: 'SetPassword'
  },
  {
    path: '/recovery-password',
    component: RecoveryPassword,
    name: 'RecoveryPassword'
  }
]

export default auth