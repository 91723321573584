<template>
  <div>

    <!-- Recomendable -->
    <div>
      <div class="label">
        {{ labels.recommendable }}
      </div>
      <div>
        {{ recommendable[form.recommendable] }}
      </div>
    </div>
    <hr />

    <!-- Ambiante familiar -->
    <div>
      <div class="label">
        {{ labels.familyEnviroment }}
      </div>
      <div>
        {{ form.familyEnviroment }}
      </div>
    </div>
    <hr />

    <!-- Tiempo libre -->
    <div>
      <div class="label">
        {{ labels.freeTime }}
      </div>
      <div>
        {{ form.freeTime }}
      </div>
    </div>
    <hr />

    <!-- Deportes -->
    <div>
      <div class="label">
        {{ labels.sports }}
      </div>
      <div>
        {{ form.sports }}
      </div>
    </div>
    <hr />

    <!-- Personaje -->
    <div>
      <div class="label">
        {{ labels.character }}
      </div>
      {{ form.character }}
    </div>
    <hr />

    <!-- Retos -->
    <div>
      <div class="label">
        {{ labels.challenges }}
      </div>
      <div>
        {{ form.challenges }}
      </div>
    </div>
    <hr />

    <!-- Logros -->
    <div>
      <div class="label">
        {{ labels.achievements }}
      </div>
      <div>
        {{ form.achievements }}
      </div>
    </div>
    <hr />

    <!-- metas mediano plazo -->
    <div>
      <div class="label">
        {{ labels.mediumTermGoals }}
      </div>
      <div>
        {{ form.mediumTermGoals }}
      </div>
    </div>
    <hr />

    <!-- Metas largo plazo -->
    <div>
      <div class="label">
        {{ labels.longTermGoals }}
      </div>
      <div>
        {{ form.longTermGoals }}
      </div>
    </div>
    <hr />

    <!-- Fortalezas -->
    <div>
      <div class="label">
        {{ labels.strengths }}
      </div>
      <div>
        {{ form.strengths }}
      </div>
    </div>
    <hr />

    <!-- Habilidades por mejorar -->
    <div>
      <div class="label">
        {{ labels.skillToImprove }}
      </div>
      <div>
        {{ form.skillToImprove }}
      </div>
    </div>
    <hr />

    <!-- Trayecto profesional -->
    <div>
      <div class="label">
        {{ labels.careerPath }}
      </div>
      <div>
        {{ form.careerPath }}
      </div>
    </div>
    <hr />

    <!-- Capacidad 1 -->
    <div>
      <div class="label">
        {{ labels.capabilityOne }}
      </div>
      <div>
        {{ form.capabilityOne }}
      </div>
    </div>
    <hr />

    <!-- Capacidad 2 -->
    <div>
      <div class="label">
        {{ labels.capabilityTwo }}
      </div>
      <div>
        {{ form.capabilityTwo }}
      </div>
    </div>
    <hr />

    <!-- capacidad 3 -->
    <div>
      <div class="label">
        {{ labels.capabilityThree }}
      </div>
      <div>
        {{ form.capabilityThree }}
      </div>
    </div>

    <!-- Video aplicacion -->
    <div v-if="form.applicationLinkVideo">
      <hr />
      <div class="label">
        {{ labels.urlVideo }}
      </div>

      <div>
        <a
          :href="form.applicationLinkVideo"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ form.applicationLinkVideo }}
        </a>
      </div>
    </div>

    <!-- documento aplicacion -->
    <div v-if="form.applicationFile">
      <hr />
      <div class="label">
        {{ labels.uploadFile }}
      </div>

      <div>
        <a
          :href="form.applicationFile.url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ form.applicationFile.name }}
        </a>
      </div>
    </div>
    <hr />

    <div>
      <div class="label">
        {{ labels.comments }}
      </div>
      <div>
        {{ form.comments }}
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    form: {
      default: () => ({}),
      required: false
    }
  },
  data() {
    return {
      recommendable: {
        "recommendable": "Recomendable",
        "recommended-with-reservations": "Recomendable con reservas",
        "non-recommendable": "No recomendable",
      }
    };
  },
  mounted() {
    
  },
  computed:{
    labels() {
      return this.$t("screens.candidate.detail");
    },
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>