<template>
  <div class="container pb-50">
    <div class="pt-5 column is-flex justify-flex-end">
      <b-button
        rounded
        icon-left="arrow-left"
        type="is-primary"
        tag="router-link"
        :to="{ name: 'Vacancies', params: { id: companyId } }"
      >
        Regresar
      </b-button>
    </div>

    <div class="columns">
      <div class="column">
        <span class="is-size-3">
          <strong>{{ company?.name }}</strong> | Nueva Vacante
        </span>
      </div>
    </div>

    <div class="columns">
      <div class="column is-8">
        <ValidationObserver ref="observer">
          <div
            class="card"
            style="border-radius: 15px"
          >
            <div class="column mt-3 is-12 p-5">
              <div class="columns">
                <div class="column">
                  <p class="title is-4">Información principal</p>
                </div>
              </div>

              <div class="columns is-multiline px-3">
                <div class="column is-6">
                  <ValidationProvider
                    rules="required"
                    name="Nombre de la Vacante"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="renameField(fieldNames.name)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-input
                        icon="angle-right"
                        :placeholder="newPlaceholder(fieldNames.name)"
                        v-model="form.name"
                        maxlength="64"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column">
                  <ValidationProvider
                    rules="required"
                    name="Período"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="renameField(fieldNames.duration)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-select
                        icon="calendar-days"
                        :placeholder="newPlaceholder(fieldNames.duration)"
                        v-model="form.duration"
                        expanded
                      >
                        <option
                          v-for="month in [3, 6, 9, 12, 15, 18, 21, 24]"
                          :key="month"
                          :value="`${month} meses`"
                        >
                          {{ month === 1 ? `${month} mes` : `${month} meses` }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column">
                  <ValidationProvider
                    rules="required|numeric|min_value:1"
                    name="Número de Posiciones"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="renameField(fieldNames.places)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-input
                        :placeholder="newPlaceholder(fieldNames.places)"
                        icon="user-plus"
                        type="number"
                        v-model="form.places"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="columns px-3">
                <div class="column">
                  <ValidationProvider
                    rules="required"
                    name="Estado"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="renameField(fieldNames.state)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-select
                        icon="map-pin"
                        :placeholder="newPlaceholder(fieldNames.state)"
                        v-model="stateSelected"
                        expanded
                        v-if="isMexico"
                      >
                        <option
                          v-for="(state, index) in states"
                          :key="`state-${index}`"
                          :value="state"
                        >
                          {{ state.name }}
                        </option>
                      </b-select>

                      <b-select
                        v-else
                        icon="map-pin"
                        :placeholder="newPlaceholder(fieldNames.state)"
                        v-model="stateSelected"
                        expanded
                      >
                        <option
                          v-for="(province, index) in provinces"
                          :key="`province-${index}`"
                          :value="province"
                        >
                          {{ province.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column">
                  <ValidationProvider
                    rules="required"
                    name="Ciudad"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="renameField(fieldNames.city)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-select
                        :placeholder="newPlaceholder(fieldNames.city)"
                        icon="map-pin"
                        v-model="citySelected"
                        :disabled="stateCities.length === 0"
                        expanded
                      >
                        <option
                          v-for="(city, index) in stateCities"
                          :key="`city-${index}`"
                          :value="city"
                        >
                          {{ city.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column">
                  <ValidationProvider
                    rules="required"
                    name="Horario"
                    v-slot="{ errors, valid }"
                  >
                    <b-tooltip
                      style="width: 100%"
                      label="Ejemplo: 8:00 a 14:00 hrs"
                      position="is-bottom"
                      animated
                    >
                      <b-field
                        :label="renameField(fieldNames.schedule)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-input
                          icon="clock"
                          :placeholder="newPlaceholder(fieldNames.schedule)"
                          v-model="form.schedule"
                        />
                      </b-field>
                    </b-tooltip>
                  </ValidationProvider>
                </div>
              </div>

              <div class="columns px-3">
                <div class="column mb-5">
                  <b-field :label="renameField(fieldNames.showScholarship)">
                    <b-switch
                      class="mt-2 ml-40"
                      :rounded="false"
                      v-model="form.showScholarship"
                    />
                  </b-field>
                </div>

                <div class="column is-4">
                  <ValidationProvider
                    rules="required|numeric"
                    name="Beca"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="renameField(fieldNames.scholarship)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-input
                        :placeholder="newPlaceholder(fieldNames.scholarship)"
                        icon="money-bill"
                        v-model="form.scholarship"
                        v-if="isMexico"
                      />

                      <b-input
                        v-else
                        :placeholder="newPlaceholder(fieldNames.scholarship)"
                        icon="euro-sign"
                        v-model="form.scholarship"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-6">
                  <ValidationProvider
                    rules="required"
                    name="Modalidad"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="renameField(fieldNames.modality)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-select
                        icon="square-user"
                        :placeholder="newPlaceholder(fieldNames.modality)"
                        v-model="form.modality"
                        expanded
                      >
                        <option
                          v-for="modality in modalities"
                          :key="modality"
                          :value="modality"
                        >
                          {{ modality }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>

              <hr />

              <div class="column is-12">
                <div class="title is-4">Formación académica</div>

                <div class="columns is-multiline">
                  <div class="column is-half">
                    <ValidationProvider
                      rules="required"
                      name="Licenciatura(s)"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        :label="renameField(fieldNames.degree)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-taginput
                          ellipsis
                          type="is-primary"
                          v-model="form.degree"
                          icon="graduation-cap"
                          placeholder="Presione 'Enter' para agregar"
                        />
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div class="column is-half">
                    <ValidationProvider
                      rules="required"
                      name="Nivel académico"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        :label="renameField(fieldNames.academicLevel)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <div class="academicOptions">
                          <b-checkbox
                            v-for="(academicLevel, index) in academicLevels"
                            :key="`academic-level-${index}`"
                            class="academicOptions--option"
                            v-model="form.academicLevel"
                            :native-value="academicLevel.value"
                          >
                            {{ academicLevel.text }}
                          </b-checkbox>
                        </div>
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div
                    v-if="form.academicLevel.includes('student')"
                    class="column is-half"
                  >
                    <ValidationProvider
                      :rules="`required|max_value:${form.maximumSemester}`"
                      name="Semestre Mínimo"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        :label="renameField(fieldNames.minimumSemester)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-select
                          v-if="isMexico"
                          expanded
                          :placeholder="newPlaceholder(fieldNames.minimumSemester)"
                          icon="user-minus"
                          v-model="form.minimumSemester"
                        >
                          <option
                            v-for="semester in semesters"
                            :key="semester.value"
                            :value="semester.value"
                          >
                            {{ semester.text }}
                          </option>
                        </b-select>

                        <b-select
                          v-else
                          expanded
                          :placeholder="newPlaceholder(fieldNames.minimumSemester)"
                          icon="user-minus"
                          v-model="form.minimumSemester"
                        >
                          <option
                            v-for="semester in semesters"
                            :key="semester.value"
                            :value="semester.value"
                          >
                            {{ semester.text }}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div
                    v-if="form.academicLevel.includes('student')"
                    class="column is-half"
                  >
                    <ValidationProvider
                      :rules="`required|min_value:${form.minimumSemester}`"
                      name="Semestre Máximo"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        :label="renameField(fieldNames.maximumSemester)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-select
                          v-if="isMexico"
                          expanded
                          :placeholder="newPlaceholder(fieldNames.maximumSemester)"
                          icon="user-plus"
                          v-model="form.maximumSemester"
                        >
                          <option
                            v-for="semester in semesters"
                            :key="semester.value"
                            :value="semester.value"
                          >
                            {{ semester.text }}
                          </option>
                        </b-select>

                        <b-select
                          v-if="isSpain"
                          expanded
                          :placeholder="newPlaceholder(fieldNames.maximumSemester)"
                          icon="user-plus"
                          v-model="form.maximumSemester"
                        >
                          <option
                            v-for="semester in semesters"
                            :key="semester.value"
                            :value="semester.value"
                          >
                            {{ semester.text }}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div class="column is-half">
                    <ValidationProvider
                      rules="required"
                      name="Nivel de Excel"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        :label="renameField(fieldNames.excelLevel)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-select
                          expanded
                          icon="browsers"
                          :placeholder="newPlaceholder(fieldNames.excelLevel)"
                          v-model="form.excelLevel"
                        >
                          <option
                            v-for="(level, index) in levels"
                            :key="`excelLevel-${index}`"
                            :value="level"
                          >
                            {{ level }}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div class="column is-half"></div>

                  <div class="column is-half">
                    <ValidationProvider
                      rules="required"
                      name="Termino de Materias"
                      v-slot="{ errors, valid }"
                    >
                      <b-tooltip
                        style="width: 100%"
                        label="Maximo mes de termino de materias permitido"
                        position="is-top"
                        animated
                      >
                        <b-field
                          :label="renameField(fieldNames.graduateDate)"
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                        >
                          <b-datepicker
                            append-to-body
                            :placeholder="newPlaceholder(fieldNames.graduateDate)"
                            trap-focus
                            type="month"
                            icon="calendar-range"
                            v-model="form.graduateDate"
                          ></b-datepicker>
                        </b-field>
                      </b-tooltip>
                    </ValidationProvider>
                  </div>

                  <div class="column is-half">
                    <ValidationProvider
                      rules="required"
                      name="Titulación"
                      v-slot="{ errors, valid }"
                    >
                      <b-tooltip
                        style="width: 100%"
                        label="Maximo mes de titulación permitido"
                        position="is-top"
                        animated
                      >
                        <b-field
                          :label="renameField(fieldNames.degreeDate)"
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                        >
                          <b-datepicker
                            trap-focus
                            type="month"
                            icon="calendar-range"
                            :placeholder="newPlaceholder(fieldNames.degreeDate)"
                            v-model="form.degreeDate"
                          >
                          </b-datepicker>
                        </b-field>
                      </b-tooltip>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <hr />

              <div class="column is-12">
                <div class="title is-4">Idiomas de la vacante</div>

                <div class="columns is-multiline">
                  <!-- Nivel de inglés -->
                  <div class="column is-full">
                    <div class="language-table">
                      <p class="is-size-5 has-text-left has-text-weight-semibold">
                        {{ form.englishLevels.language }}
                      </p>
                      <p class="is-size-5 has-text-weight-medium has-text-grey-dark">Deseable</p>
                      <p class="is-size-5 has-text-weight-medium has-text-grey-dark">Básico</p>
                      <p class="is-size-5 has-text-weight-medium has-text-grey-dark">Intermedio</p>
                      <p class="is-size-5 has-text-weight-medium has-text-grey-dark">Avanzado</p>
                      <p class="is-size-5 has-text-weight-medium has-text-info-dark">Representativa</p>

                      <p class="is-size-5 has-text-left has-text-weight-medium has-text-primary">Escritura</p>
                      <b-radio
                        name="englishWriting"
                        native-value="Deseable"
                        required
                        v-model="form.englishLevels.writing"
                      ></b-radio>
                      <b-radio
                        name="englishWriting"
                        native-value="Básico"
                        required
                        v-model="form.englishLevels.writing"
                      ></b-radio>
                      <b-radio
                        name="englishWriting"
                        native-value="Intermedio"
                        required
                        v-model="form.englishLevels.writing"
                      ></b-radio>
                      <b-radio
                        name="englishWriting"
                        native-value="Avanzado"
                        required
                        v-model="form.englishLevels.writing"
                      ></b-radio>
                      <b-checkbox
                        v-model="form.englishLevels.representativeSkill"
                        native-value="writing"
                        required
                      ></b-checkbox>

                      <p class="is-size-5 has-text-left has-text-weight-medium has-text-primary">Lectura</p>
                      <b-radio
                        name="englishReading"
                        native-value="Deseable"
                        required
                        v-model="form.englishLevels.reading"
                      ></b-radio>
                      <b-radio
                        name="englishReading"
                        native-value="Básico"
                        required
                        v-model="form.englishLevels.reading"
                      ></b-radio>
                      <b-radio
                        name="englishReading"
                        native-value="Intermedio"
                        required
                        v-model="form.englishLevels.reading"
                      ></b-radio>
                      <b-radio
                        name="englishReading"
                        native-value="Avanzado"
                        required
                        v-model="form.englishLevels.reading"
                      ></b-radio>
                      <b-checkbox
                        v-model="form.englishLevels.representativeSkill"
                        native-value="reading"
                        required
                      ></b-checkbox>

                      <p class="is-size-5 has-text-left has-text-weight-medium has-text-primary">Escucha</p>
                      <b-radio
                        name="englishListening"
                        native-value="Deseable"
                        required
                        v-model="form.englishLevels.listening"
                      ></b-radio>
                      <b-radio
                        name="englishListening"
                        native-value="Básico"
                        required
                        v-model="form.englishLevels.listening"
                      ></b-radio>
                      <b-radio
                        name="englishListening"
                        native-value="Intermedio"
                        required
                        v-model="form.englishLevels.listening"
                      ></b-radio>
                      <b-radio
                        name="englishListening"
                        native-value="Avanzado"
                        required
                        v-model="form.englishLevels.listening"
                      ></b-radio>
                      <b-checkbox
                        v-model="form.englishLevels.representativeSkill"
                        native-value="listening"
                        required
                      ></b-checkbox>

                      <p class="is-size-5 has-text-left has-text-weight-medium has-text-primary">Conversación</p>
                      <b-radio
                        name="englishSpeaking"
                        native-value="Deseable"
                        required
                        v-model="form.englishLevels.speaking"
                      ></b-radio>
                      <b-radio
                        name="englishSpeaking"
                        native-value="Básico"
                        required
                        v-model="form.englishLevels.speaking"
                      ></b-radio>
                      <b-radio
                        name="englishSpeaking"
                        native-value="Intermedio"
                        required
                        v-model="form.englishLevels.speaking"
                      ></b-radio>
                      <b-radio
                        name="englishSpeaking"
                        native-value="Avanzado"
                        required
                        v-model="form.englishLevels.speaking"
                      ></b-radio>
                      <b-checkbox
                        v-model="form.englishLevels.representativeSkill"
                        native-value="speaking"
                        required
                      ></b-checkbox>
                    </div>
                  </div>

                  <!-- Otros idiomas -->
                  <div
                    class="column is-full"
                    v-for="(language, index) in form.otherLanguages"
                    :key="`language-${index}`"
                  >
                    <div class="is-flex mb-2 mt-2">
                      <b-input
                        class="mr-2"
                        style="width: 100%"
                        maxlength="24"
                        v-model="form.otherLanguages[index].language"
                      ></b-input>

                      <b-button
                        type="is-danger"
                        @click="removeLenguaje(index)"
                      >
                        <f-icon icon="fa-regular fa-trash" />
                      </b-button>
                    </div>

                    <div class="language-table">
                      <p class="is-size-5 has-text-weight-semibold">
                        {{ form.otherLanguages[index].language }}
                      </p>
                      <p class="is-size-5 has-text-weight-medium has-text-grey-dark">Deseable</p>
                      <p class="is-size-5 has-text-weight-medium has-text-grey-dark">Básico</p>
                      <p class="is-size-5 has-text-weight-medium has-text-grey-dark">Intermedio</p>
                      <p class="is-size-5 has-text-weight-medium has-text-grey-dark">Avanzado</p>
                      <p class="is-size-5 has-text-weight-medium has-text-info-dark">Representativa</p>

                      <p class="is-size-5 has-text-left has-text-weight-medium has-text-primary">Escritura</p>
                      <b-radio
                        native-value="Deseable"
                        required
                        v-model="form.otherLanguages[index].writing"
                        :name="`lenguage${index}Writing`"
                      ></b-radio>
                      <b-radio
                        native-value="Básico"
                        required
                        v-model="form.otherLanguages[index].writing"
                        :name="`lenguage${index}Writing`"
                      ></b-radio>
                      <b-radio
                        native-value="Intermedio"
                        required
                        :name="`lenguage${index}Writing`"
                        v-model="form.otherLanguages[index].writing"
                      ></b-radio>
                      <b-radio
                        native-value="Avanzado"
                        required
                        :name="`lenguage${index}Writing`"
                        v-model="form.otherLanguages[index].writing"
                      ></b-radio>
                      <b-checkbox
                        v-model="form.otherLanguages[index].representativeSkill"
                        native-value="writing"
                        required
                      ></b-checkbox>

                      <p class="is-size-5 has-text-left has-text-weight-medium has-text-primary">Lectura</p>
                      <b-radio
                        native-value="Deseable"
                        required
                        v-model="form.otherLanguages[index].reading"
                        :name="`lenguage${index}Reading`"
                      ></b-radio>
                      <b-radio
                        native-value="Básico"
                        required
                        v-model="form.otherLanguages[index].reading"
                        :name="`lenguage${index}Reading`"
                      ></b-radio>
                      <b-radio
                        native-value="Intermedio"
                        required
                        v-model="form.otherLanguages[index].reading"
                        :name="`lenguage${index}Reading`"
                      ></b-radio>
                      <b-radio
                        native-value="Avanzado"
                        required
                        v-model="form.otherLanguages[index].reading"
                        :name="`lenguage${index}Reading`"
                      ></b-radio>
                      <b-checkbox
                        v-model="form.otherLanguages[index].representativeSkill"
                        native-value="reading"
                        required
                      ></b-checkbox>

                      <p class="is-size-5 has-text-left has-text-weight-medium has-text-primary">Escucha</p>
                      <b-radio
                        native-value="Deseable"
                        required
                        v-model="form.otherLanguages[index].listening"
                        :name="`lenguage${index}Listening`"
                      ></b-radio>
                      <b-radio
                        native-value="Básico"
                        required
                        v-model="form.otherLanguages[index].listening"
                        :name="`lenguage${index}Listening`"
                      ></b-radio>
                      <b-radio
                        native-value="Intermedio"
                        required
                        v-model="form.otherLanguages[index].listening"
                        :name="`lenguage${index}Listening`"
                      ></b-radio>
                      <b-radio
                        native-value="Avanzado"
                        required
                        v-model="form.otherLanguages[index].listening"
                        :name="`lenguage${index}Listening`"
                      ></b-radio>
                      <b-checkbox
                        v-model="form.otherLanguages[index].representativeSkill"
                        native-value="listening"
                        required
                      ></b-checkbox>

                      <p class="is-size-5 has-text-left has-text-weight-medium has-text-primary">Conversación</p>
                      <b-radio
                        native-value="Deseable"
                        required
                        v-model="form.otherLanguages[index].speaking"
                        :name="`lenguage${index}Speaking`"
                      ></b-radio>
                      <b-radio
                        native-value="Básico"
                        required
                        v-model="form.otherLanguages[index].speaking"
                        :name="`lenguage${index}Speaking`"
                      ></b-radio>
                      <b-radio
                        native-value="Intermedio"
                        required
                        v-model="form.otherLanguages[index].speaking"
                        :name="`lenguage${index}Speaking`"
                      ></b-radio>
                      <b-radio
                        native-value="Avanzado"
                        required
                        v-model="form.otherLanguages[index].speaking"
                        :name="`lenguage${index}Speaking`"
                      ></b-radio>
                      <b-checkbox
                        v-model="form.otherLanguages[index].representativeSkill"
                        native-value="speaking"
                        required
                      ></b-checkbox>
                    </div>
                  </div>

                  <div class="column is-full">
                    <b-button
                      expanded
                      type="is-primary"
                      @click="addLenguaje"
                    >
                      Agregar idioma
                    </b-button>
                  </div>
                </div>
              </div>

              <hr />

              <div class="column is-12">
                <div class="title is-4">Habilidades y Conocimientos</div>

                <div class="column is-12">
                  <h1 class="title is-5">{{ renameField(fieldNames.skills) }}</h1>

                  <h1 class="subtitle is-6">
                    Habilidades, competencias y caracteristicas personales necesarias para la posición.
                  </h1>

                  <div
                    v-for="(skill, index) in form.skills"
                    :key="`${index + 1}.`"
                    class="columns is-multiline align-flex-end"
                  >
                    <div class="column is-5">
                      <b-field
                        style="height: 3rem"
                        horizontal
                        :label="`${index + 1}.`"
                      >
                        <b-input
                          :placeholder="newPlaceholder(fieldNames.skills)"
                          icon="award"
                          v-model="skill.name"
                          type="text"
                        />
                      </b-field>
                    </div>

                    <div class="column is-1 pb-20 pl-12">
                      <b-button
                        outlined
                        type="is-danger"
                        icon-left="trash"
                        @click="deleteSkill(index)"
                      />
                    </div>
                  </div>

                  <div class="mb-15">
                    <b-button
                      rounded
                      type="is-primary"
                      @click="addSkill"
                    >
                      Agregar Habilidad
                    </b-button>
                  </div>
                </div>

                <div class="column is-12">
                  <h1 class="title is-5">{{ renameField(fieldNames.knowledges) }}</h1>

                  <h1 class="subtitle is-6">
                    Conocimientos como paquetería, programas, equipos, etc., necesarias para la posición.
                  </h1>

                  <div
                    v-for="(knowledge, index) in form.knowledges"
                    :key="`${index + 1}.`"
                    class="columns is-multiline align-flex-end"
                  >
                    <div class="column is-6">
                      <ValidationProvider
                        rules="required"
                        name="Conocimiento"
                        v-slot="{ errors, valid }"
                      >
                        <b-field
                          style="height: 4rem"
                          horizontal
                          :label="`${index + 1}.`"
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                        >
                          <b-autocomplete
                            :placeholder="newPlaceholder(fieldNames.knowledges)"
                            icon="cube"
                            v-model="knowledge.name"
                            maxlength="64"
                            :data="filteredKnowledges"
                            :open-on-focus="true"
                            :clearable="true"
                            field="name"
                            @focus="focusedKnowledge(index)"
                            @select="(option) => (selectedKnowledge = option)"
                          >
                          </b-autocomplete>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-5">
                      <ValidationProvider
                        rules="required"
                        name="Nivel conocimiento"
                        v-slot="{ errors, valid }"
                      >
                        <b-field
                          style="height: 4rem"
                          horizontal
                          label="Nivel"
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                        >
                          <b-select
                            v-model="knowledge.level"
                            icon="signal-alt-3"
                            expanded
                          >
                            <option
                              v-for="knowledgeLevel in knowledgeLevels"
                              :key="`${knowledgeLevel}`"
                              :value="knowledgeLevel"
                            >
                              {{ knowledgeLevel }}
                            </option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-1 pb-36 pl-12">
                      <b-button
                        outlined
                        type="is-danger"
                        icon-left="trash"
                        @click="deleteKnowledge(index)"
                      />
                    </div>
                  </div>

                  <div class="mb-15">
                    <b-button
                      rounded
                      type="is-primary"
                      @click="addKnowledge"
                    >
                      Agregar Conocimiento
                    </b-button>
                  </div>
                </div>
              </div>

              <hr />

              <div class="column is-12 pb-0">
                <div class="title is-4 text--primary">Descripción de la Práctica</div>

                <div class="columns is-multiline">
                  <div class="column is-6">
                    <ValidationProvider
                      rules="required"
                      name="Objetivo"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        :label="renameField(fieldNames.goal)"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-input
                          :placeholder="newPlaceholder(fieldNames.goal)"
                          icon="angle-right"
                          v-model="form.goal"
                          type="textarea"
                          maxlength="256"
                        />
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div class="column is-6">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <h1 class="title is-5">{{ renameField(fieldNames.benefit) }}</h1>
                      </div>
                      <div class="column is-12">
                        <div class="columns is-multiline">
                          <div
                            class="column is-12 py-0 my-0"
                            v-for="(benefit, index) in form.benefits"
                            :key="`benefit-${index}`"
                          >
                            <ValidationProvider
                              rules="required"
                              :name="`${index + 1}. ${form.benefits[index]}`"
                              v-slot="{ errors, valid }"
                            >
                              <div class="is-flex align-flex-start">
                                <b-field
                                  style="width: 100%"
                                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                  :message="errors"
                                >
                                  <b-input
                                    :placeholder="newPlaceholder(fieldNames.benefits)"
                                    icon="hand-holding-heart"
                                    v-model="form.benefits[index]"
                                    type="text"
                                    maxlength="200"
                                  />
                                </b-field>
                                <b-button
                                  class="ml-1"
                                  type="is-danger"
                                  icon-left="trash"
                                  @click="deleteBenefit(index)"
                                >
                                </b-button>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12 is-centered pt-0">
                        <b-button
                          type="is-primary"
                          expanded
                          icon-left="plus"
                          @click="addBenefit"
                        >
                          Agregar beneficio
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <div class="column is-12">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <h1 class="title is-4">{{ renameField(fieldNames.activities) }}</h1>
                      </div>

                      <div class="column is-12">
                        <div class="columns is-multiline">
                          <div
                            class="column is-12"
                            v-for="(activity, index) in form.activities"
                            :key="`activity-${index}`"
                          >
                            <ValidationProvider
                              rules="required"
                              :name="`${index + 1}. ${form.activities[index]}`"
                              v-slot="{ errors, valid }"
                            >
                              <div class="is-flex align-flex-end">
                                <b-field
                                  style="width: 100%"
                                  :label="`${index + 1}. ${form.activities[index]}`"
                                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                  :message="errors"
                                >
                                  <b-input
                                    :placeholder="newPlaceholder(fieldNames.activities)"
                                    icon="clipboard-list"
                                    v-model="form.activities[index]"
                                    type="text"
                                  />
                                </b-field>

                                <b-button
                                  v-if="index > 2"
                                  class="mb-16 ml-1"
                                  type="is-danger"
                                  icon-left="trash"
                                  @click="removeActivity(index)"
                                >
                                </b-button>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12 is-centered">
                        <b-button
                          type="is-primary"
                          expanded
                          @click="addActivity"
                        >
                          Agregar actividad
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div class="columns is-multiline">
                  <div class="column is-6">
                    <div class="title is-4 pt-2">Datos de Contacto</div>
                  </div>
                  <div class="column is-6 has-text-right">
                    <b-button
                      rounded
                      type="is-primary"
                      icon-right="plus"
                      @click="openCreateDialog"
                    >
                      Agregar usuario
                    </b-button>
                  </div>

                  <div class="column is-6">
                    <p class="subtitle is-5">
                      <b>Contacto seleccionado</b> <br />
                      {{ getAuthorName(form.author) }}
                    </p>
                  </div>
                  <div class="column is-6 has-text-right">
                    <p class="subtitle is-6">
                      <b>Agregar otros contactos</b> <br />
                      <b-tooltip
                        multilined
                        type="is-blue"
                        position="is-top"
                        label="Desmarcar esta opción elimina los contactos adicionales agregados"
                      >
                        <b-field>
                          <b-switch v-model="otherSolicitants" />
                        </b-field>
                      </b-tooltip>
                    </p>
                  </div>

                  <div
                    class="column is-12"
                    v-if="!otherSolicitants"
                  >
                    <b class="is-size-5">Contactos disponibles</b>
                    <div
                      class="columns is-multiline mt-1"
                      style="max-height: 20rem; min-height: 20rem; overflow-y: scroll"
                    >
                      <div
                        class="column is-12"
                        v-for="(contact, index) in authors"
                        :key="`author-${index}`"
                      >
                        <div class="card">
                          <div class="card-content">
                            <div class="columns is-multiline">
                              <div class="column is-8">
                                <p>
                                  <b-icon
                                    type="is-info"
                                    icon="user"
                                  />{{ contact.fullName }}
                                </p>
                                <p>
                                  <b-icon
                                    type="is-info"
                                    icon="envelope"
                                    class="mr-1"
                                  />{{ contact.email }}
                                </p>
                              </div>
                              <div class="column is-4 has-text-right">
                                <b-button
                                  type="is-primary"
                                  rounded
                                  label="Seleccionar"
                                  @click="setAuthor(contact.id)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="column is-12"
                    v-else
                  >
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <b class="is-size-5">Contactos adicionales disponibles</b>
                        <div
                          class="px-1"
                          style="max-height: 20rem; min-height: auto; overflow-y: scroll"
                        >
                          <div
                            class="card my-3"
                            v-for="(contact, index) in authors"
                            :key="`contact-${index}`"
                          >
                            <div class="card-content">
                              <div class="columns is-multiline">
                                <div class="column is-8">
                                  <p>
                                    <b-icon
                                      type="is-info"
                                      icon="user"
                                    />{{ contact.fullName }}
                                  </p>
                                  <p>
                                    <b-icon
                                      type="is-info"
                                      icon="envelope"
                                      class="mr-1"
                                    />{{ contact.email }}
                                  </p>
                                </div>
                                <div class="column is-4 has-text-right">
                                  <b-button
                                    :disabled="
                                      form.additionalAuthors.includes(contact.id) || form.author === contact.id
                                    "
                                    type="is-primary"
                                    rounded
                                    icon-right="plus"
                                    label="Agregar"
                                    @click="addCompanyUser(contact.id)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <b class="is-size-5">Contactos adicionales asignados</b>
                        <div
                          class="px-2"
                          style="max-height: 20rem; min-height: auto; overflow-y: scroll"
                        >
                          <div
                            class="card my-3"
                            v-for="(author, index) in form.additionalAuthors"
                            :key="`author-${index}`"
                          >
                            <div class="card-content">
                              <div class="columns is-multiline">
                                <div class="column is-8">
                                  <p>
                                    <b-icon
                                      type="is-primary"
                                      icon="user"
                                    />{{ getAuthorName(author) }}
                                  </p>
                                  <p>
                                    <b-icon
                                      type="is-primary"
                                      icon="envelope"
                                      class="mr-1"
                                    />
                                    {{ getAuthorEmail(author) }}
                                  </p>
                                </div>
                                <div class="column is-4 has-text-right">
                                  <b-button
                                    class="mt-1 mr-1"
                                    type="is-danger is-ghost"
                                    rounded
                                    outlined
                                    icon-right="xmark"
                                    size="is-small"
                                    @click="popCompanyUser(index)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <b-message
                            v-if="!form.author.length"
                            type="is-info"
                            has-icon
                            >Asigna por lo menos 1 contacto</b-message
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="column is-12">
                    <b-message type="is-primary">
                      Estos datos son confidenciales y son de uso exclusivo de Pro Meritum.
                    </b-message>
                  </div>
                </div>

                <hr />

                <div class="column is-12 mt-3">
                  <div class="title is-4">Acerca del reclutamiento</div>

                  <div class="columns is-multiline">
                    <div class="column is-6">
                      <b-field label="Esquema de requisición">
                        <b-select
                          v-model="form.schemaType"
                          icon="file-contract"
                          expanded
                        >
                          <option
                            v-for="(schema, index) in servicesSchema"
                            :key="`schema-${index}`"
                            :value="schema"
                          >
                            {{ schema }}
                          </option>
                        </b-select>
                      </b-field>
                    </div>

                    <div class="column is-6">
                      <b-field label="Tipo de Servicio">
                        <b-select
                          v-model="form.serviceType"
                          icon="list-radio"
                          expanded
                        >
                          <option
                            v-for="(service, index) in recruitmentServices"
                            :key="`service-${index}`"
                            :value="service"
                          >
                            {{ service }}
                          </option>
                        </b-select>
                      </b-field>
                    </div>

                    <div
                      class="column is-6"
                      v-if="form.serviceType && form.serviceType != 'Reclutamiento tradicional'"
                    >
                      <b-field label="Detalle de servicio">
                        <b-select
                          v-model="form.serviceDetail"
                          icon="game-console-handheld"
                          expanded
                        >
                          <option
                            v-for="(detail, index) in alternativeRecruitmentServices"
                            :key="`detail-${index}`"
                            :value="detail"
                          >
                            {{ detail }}
                          </option>
                        </b-select>
                      </b-field>
                    </div>

                    <div class="column is-6">
                      <b-field label="Tipo de reclamación">
                        <b-select
                          v-model="form.claimType"
                          icon="cards-blank"
                          expanded
                        >
                          <option
                            v-for="(claim, index) in servicesClaims"
                            :key="`claim-${index}`"
                            :value="claim"
                          >
                            {{ claim }}
                          </option>
                        </b-select>
                      </b-field>
                    </div>
                  </div>
                </div>

                <hr />

                <div class="column is-12">
                  <div class="title is-4">
                    {{ renameField(fieldNames.project) }}
                  </div>

                  <div class="columns is-multiline">
                    <div class="column">
                      <b-tooltip
                        label="Formatos soportados: .pdf, .docx, .cvs (Max: 5Mb)."
                        position="is-right"
                        animated
                      >
                        <b-field
                          v-if="
                            ['superqueen', 'recruiter-manager', 'recruiter', 'hrmanager', 'solicitant'].includes(
                              $isRole(user)
                            )
                          "
                          class="file"
                        >
                          <b-upload
                            v-model="file"
                            accept=".pdf, .docx, .pptx"
                          >
                            <a
                              class="button is-primary"
                              style="border-radius: 30px"
                            >
                              <b-icon icon="upload" />
                              <span>Subir archivo</span>
                            </a>
                          </b-upload>
                        </b-field>
                      </b-tooltip>
                    </div>

                    <div
                      class="column"
                      v-if="form.project"
                    >
                      <a
                        :href="form.project.url"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <b-button
                          rounded
                          outlined
                          type="is-primary"
                          target="_blank"
                        >
                          Ver Documento
                        </b-button>
                      </a>
                    </div>
                  </div>
                </div>

                <hr />

                <KillerQuestions ref="killerQuestions" />

                <hr />

                <div class="column is-12 mt-5">
                  <div class="title is-4">
                    {{ renameField(fieldNames.comments) }}
                  </div>

                  <b-field label="Comentarios, observaciones, aclaraciones y más...">
                    <b-input
                      v-model="form.comments"
                      type="textarea"
                      maxlength="512"
                      :placeholder="newPlaceholder(fieldNames.comments)"
                    />
                  </b-field>
                </div>
              </div>

              <OtherFields
                ref="otherFields"
                :otherFields="otherFields"
                :hasOtherFields="hasOtherFields"
              />
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="column is-4 relative">
        <div
          class="card sticky is--top75 mt-12 p-2"
          style="border-radius: 15px"
        >
          <div class="card-content">
            <div class="title is-4">Crear vacante</div>

            <div v-if="isMexico">
              <div class="subtitle is-6">
                Ingresa manualmente el ID de requisición de Monday o selecciona un proyecto.
              </div>

              <div>
                <b-field label="ID de requisición">
                  <b-input
                    icon="barcode"
                    placeholder="XXXXXXXXXX"
                    maxlength="10"
                    has-counter
                    v-model="form.projectMondayId"
                    :disabled="form.projectId?.length > 0"
                  />
                </b-field>
              </div>

              <div v-if="availableProjects.length > 0">
                <div class="is-flex is-justify-content-space-between is-align-items-flex-end mb-5">
                  <b-field
                    style="width: 80%; margin-bottom: 0"
                    label="Proyecto"
                  >
                    <b-select
                      v-model="form.projectId"
                      expanded
                      icon="list-check"
                      :disabled="form.projectMondayId?.length > 0"
                    >
                      <option
                        v-for="(project, index) in availableProjects"
                        :key="`project-${index}`"
                        :value="project.projectId"
                      >
                        {{ project?.name }}
                      </option>
                    </b-select>
                  </b-field>

                  <b-button
                    @click="form.projectId = ''"
                    style="width: 15%"
                    type="is-danger"
                    icon-left="trash"
                    :disabled="form.projectMondayId?.length > 0"
                  ></b-button>
                </div>
              </div>

              <div v-else>
                <div class="subtitle is-6 mb-4">
                  <f-icon
                    icon="fa-regular fa-info-circle"
                    class="has-text-danger"
                  />
                  No tienes proyectos con posiciones disponibles.
                </div>
              </div>
            </div>

            <div
              class="card-content"
              v-else
            >
              <div class="subtitle is-6">Revisa adecuadamente los campos antes de guardar los cambios.</div>
            </div>

            <div>
              <b-button
                expanded
                rounded
                type="is-primary"
                icon-left="save"
                @click="createVacancy"
              >
                Guardar vacante
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <UserCreate
      contactUsers
      :company="companyId"
      @updated="getUsers"
      ref="create"
    />

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
  import { academicLevels } from "@promeritum/promeritum-utils";
  import states from "../lib/states";
  import provinces from "../lib/ES/provinces";
  import { getSemestersList } from "../lib/semesters";
  import {
    roleLabel,
    levelOptions,
    modalities,
    getDefaultVacancyFields,
    getStateCities,
    knowledgeLevels,
  } from "../lib/vacancyDictionaries";
  import { vacancyCreateForm } from "../mixins/vacancy";
  import {
    createVacancy,
    getCompanyContacts,
    getOtherFields,
    getRenamedFields,
    notifyRecruiter,
    getKnowledges,
    getAvailableProjects,
  } from "../api/vacancy";
  import { mapGetters } from "vuex";
  import servicesSchema from "@/lib/servicesSchema";
  import servicesClaims from "@/lib/servicesClaims";
  import alternativeRecruitmentServices from "@/lib/alternativeRecruitmentServices";
  import recruitmentServices from "@/lib/recruitmentServices";

  export default {
    components: {
      UserCreate: () => import("@/components/modals/UserCreate.vue"),
      KillerQuestions: () => import("@/components/KillerQuestions.vue"),
      OtherFields: () => import("@/components/OtherFields.vue"),
    },
    mixins: [vacancyCreateForm],
    data() {
      return {
        academicLevels,
        knowledgeLevels,
        servicesSchema,
        servicesClaims,
        alternativeRecruitmentServices,
        recruitmentServices,
        authorForm: true,
        authors: [],
        citySelected: null,
        file: null,
        hasOtherFields: false,
        isLoading: false,
        otherFields: [],
        project: null,
        provinces,
        renamedFields: [],
        states,
        stateSelected: null,
        availableProjects: [],
        knowledges: [],
        knowledgeIndex: 0,
        selectedKnowledge: "",
        selectedProject: null,
        otherSolicitants: false,
      };
    },
    watch: {
      async file(val) {
        const formData = new FormData();
        if (val) {
          this.isLoading = true;
          try {
            formData.append("file", val);
            const response = await this.$api.post("/upload", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
            this.form.project = response.data;
            this.$buefy.toast.open({
              message: "El archivo se subió con éxito",
              type: "is-success",
            });
          } catch (error) {
            this.$buefy.toast.open({
              message: !error.response ? error : error.response.data.message,
              type: "is-danger",
            });
          }
          this.isLoading = false;
        }
      },
      "form.author": function (val) {
        if (val) {
          this.authors.forEach((obj) => {
            if (obj.id === val) {
              this.form.authorName = obj.fullName;
              this.form.authorEmail = obj.email;
              if (obj.profile) this.form.authorPhone = obj.profile.phone;
            }
          });
        }
      },
      stateSelected() {
        if (this.stateSelected.id == "00" && this.isSpain) {
          this.citySelected = this.provincesMunicipalities[0];
        }
      },
      selectedProject(val) {
        if (val) {
          this.form.projectMondayId = val.projectMondayId;
          this.form.projectId = val.projectId;
        }
      },
      otherSolicitants(val) {
        if (!val) {
          this.form.additionalAuthors = [];
        }
      },
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      semesters() {
        return getSemestersList(process.env.VUE_APP_LOCATION);
      },
      user() {
        return this.$store.state.session.user;
      },
      levels() {
        return levelOptions;
      },
      modalities() {
        return modalities;
      },
      roles() {
        return roleLabel;
      },
      company() {
        return this.$store.state.company;
      },
      fieldNames() {
        return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
      },
      stateCities() {
        return getStateCities(this.stateSelected?.id, process.env.VUE_APP_LOCATION);
      },
      isMexico() {
        return process.env.VUE_APP_LOCATION === "mx";
      },
      isSpain() {
        return process.env.VUE_APP_LOCATION === "es";
      },
      filteredKnowledges() {
        if (this.form.knowledges[this.knowledgeIndex].name) {
          return this.knowledges.filter((knowledge) => {
            if (this.knowledgeIndex >= 0) {
              return (
                knowledge.name.toLowerCase().indexOf(this.form.knowledges[this.knowledgeIndex].name.toLowerCase()) >= 0
              );
            }
          });
        } else return this.knowledges;
      },
    },
    mounted() {
      this.mountedPromises();
    },
    methods: {
      async mountedPromises() {
        const { hasOtherFields, otherFields } = await getOtherFields(this.companyId);
        this.hasOtherFields = hasOtherFields;
        this.otherFields = otherFields;

        this.renamedFields = await getRenamedFields(this.companyId);
        this.authors = await getCompanyContacts(this.companyId);
        this.knowledges = await getKnowledges(this.companyId);
        this.availableProjects = await getAvailableProjects(this.companyId);
        this.authorForm = false;

        const roles = ["hrmanager", "solicitant"];
        if (roles.includes(this.user.role)) this.mountedAuthor();
      },
      mountedAuthor() {
        const roles = ["hrmanager", "solicitant"];
        if (roles.includes(this.user.role)) {
          this.authorForm = false;
          this.form.authorName = this.user.fullName;
          this.form.authorEmail = this.user.email;
          this.form.author = [this.user.id];
          if (this.user.profile) this.form.authorPhone = this.user.profile.phone;
        }
      },
      renameField(actualName) {
        const newField = this.renamedFields.find((field) => actualName === field.actualName);
        return newField?.newName || actualName;
      },
      newPlaceholder(actualName) {
        const newField = this.renamedFields.find((field) => actualName === field.actualName);
        return newField?.newPlaceholder || "";
      },
      addLenguaje() {
        this.form.otherLanguages.push({
          language: "",
          writing: "",
          listening: "",
          reading: "",
          speaking: "",
          representativeSkill: [],
        });
      },
      removeLenguaje(index) {
        this.form.otherLanguages.splice(index, 1);
      },
      buildPayloadForm() {
        this.form.location.state = this.stateSelected.name;
        this.form.location.city = this.citySelected.name;
        this.form.otherFields = this.$refs.otherFields.otherFields;
        this.form.projectId = this.form.projectId ? this.form.projectId : this.projectId;
        this.form.englishLevel = this.form.englishLevels.speaking;

        if (!this.form.recruiter && this.$isRole() === "recruiter") this.form.recruiter = this.user.id;
        else this.form.recruiter = this.company.headRecruiter?.id;

        if (this.form.projectId)
          this.form.projectMondayId = this.availableProjects.find(
            (project) => project.projectId === this.form.projectId
          ).projectMondayId;
      },
      async validateForm() {
        const valid = await this.$refs.observer.validate();
        // const lastValid = await this.$refs.lastObserver.validate() Descomentar posteriormente para hacer oobligatorio id de monday
        // if (valid && lastValid) { descomentar posteriormente para hacer obligatorio id de mondya
        if (!valid || !this.form.author) {
          this.$buefy.toast.open({
            message: "Uno o más campos están incompletos.",
            type: "is-danger",
          });
          return false;
        }
        return true;
      },
      async createVacancy() {
        if (await this.validateForm()) {
          this.buildPayloadForm();
          this.isLoading = true;

          try {
            const { data } = await createVacancy(this.companyId, this.form);

            this.$refs.killerQuestions.createKillerQuestion(data.id);

            if (["solicitant", "hrmanager"].includes(this.user.role)) await notifyRecruiter(this.companyId, data);

            this.$router.push({ name: "Vacancies", params: { id: this.companyId } });
          } finally {
            this.isLoading = false;
          }
        }
      },
      addActivity() {
        this.form.activities.push("");
      },
      removeActivity(index) {
        this.form.activities.splice(index, 1);
      },
      addSkill() {
        this.form.skills.push({
          name: "",
        });
      },
      deleteSkill(index) {
        this.form.skills.splice(index, 1);
      },
      addBenefit() {
        this.form.benefits.push("");
      },
      deleteBenefit(index) {
        this.form.benefits.splice(index, 1);
      },
      addKnowledge() {
        if (this.form.knowledges.length < 10) {
          this.form.knowledges.push({
            name: "",
            level: "",
          });
        } else
          this.$buefy.toast.open({
            message: "Límite máximo de conocimientos alcanzado",
            type: "is-warning",
          });
      },
      deleteKnowledge(index) {
        this.form.knowledges.splice(index, 1);
      },
      focusedKnowledge(index) {
        this.knowledgeIndex = index;
      },
      async getUsers() {
        this.authors = await getCompanyContacts(this.companyId);
      },
      openCreateDialog() {
        this.$refs.create.open();
      },
      setAuthor(id) {
        this.form.author = id;
      },
      addCompanyUser(id) {
        this.form.additionalAuthors.push(id);
      },
      popCompanyUser(index) {
        this.form.additionalAuthors.splice(index, 1);
      },
      getAuthorName(id) {
        const author = this.authors.find((user) => user.id === id);
        return author?.fullName ? author.fullName : "Sin Asignar";
      },
      getAuthorEmail(id) {
        const author = this.authors.find((user) => user.id === id);
        return author?.email;
      },
    },
  };
</script>

<style scoped>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

  .academicOptions--option {
    margin-top: 2px;
  }
  .language-table {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    align-items: center;
    row-gap: 1rem;
  }
</style>
