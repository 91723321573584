import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import auth from './auth'
import company from './company'
import recruitment from './recruitment'
import calendar from './calendar'
import pdf from './pdf'
import CandidateProfile from '@/screens/CandidateProfile'
import _403 from '@/screens/errors/403'
import _404 from '@/screens/errors/404'
import Maintenance from '@/screens/Maintenance'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    component: {
      render: (r) => r('router-view')
    },
    beforeEnter: (to, from, next) => {
      const role = store.state.session.user.role
      const company = store.state.session.user.company
      const routes = {
        superqueen: '/empresas',
        recruiter: '/empresas',
        'recruiter-manager': '/empresas',
        designer: '/empresas',
        'designer-manager': '/empresas',
        manager: '/empresas',
        hrmanager: `/empresa/${company}/vacantes`,
        solicitant: `/empresa/${company}/vacantes`,
        account: `/empresas`,
        'account-manager': `/empresas`
      }
      if (!role) {
        next('/login')
      }
      next(routes[role])
    }
  },
  ...auth,
  ...company,
  ...recruitment,
  ...calendar,
  ...pdf,
  {
    path: '/download-candidate-profile',
    component: CandidateProfile
  },
  {
    path: '/maintenance',
    component: Maintenance
  },
  {
    path: '/403',
    component: _403
  },
  {
    path: '*',
    component: _404
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition){
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})

router.beforeEach((to, from, next) => {
  if ([true, 'true', 1, '1'].includes(process.env.VUE_APP_MAINTENANCE_MODE)) {
    if (from.path !== '/maintenance' && to.path !== '/maintenance') {
      next('/maintenance')
    }
  }

  if (!store || (store && store.state.session.token === null && to.meta.requireAuth)) {
    next('/login')
  } else {
    next()
  }

  if (to.meta.permissions && to.meta.permissions.length > 0) {
    const permissions = to.meta.permissions
    return permissions.includes(store.state.session.user.role)
      ? next()
      : next('/403')
  }
})

export default router
