<template>
    <div>
      <b-modal
        :active.sync="isOpen"
        :destroy-on-hide="true"
        @cancel="close"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        width>

        <div class="modal-card" style="width: 68vw;">
          <div class="modal-card-head">
            <div class="modal-card-title">
              {{ company ? `Editar ${company.name}` : 'Nueva empresa'  }}
            </div>
          </div>

          <div class="modal-card-body">
            <ValidationObserver ref="observer">
              <div class="columns">

                <div class="column is-4">
                  <ValidationProvider rules="required|max:64" name="Nombre" v-slot="{ errors, valid }">
                    <b-field
                      label="Nombre"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-input
                        icon="building"
                        type="text"
                        v-model="form.name"
                      />
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider rules="required|min:7|max:7" v-slot="{ errors, valid }">
                    <b-field
                      label="Color"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-input
                      icon="palette"
                      v-model="form.mainColor"
                      />
                    </b-field>
                  </ValidationProvider>

                  <b-field>
                    <b-button
                      :style="`
                        background-color: ${form.mainColor};
                        pointer-events: none; color: white;
                      `"
                      expanded>
                      <p style="mix-blend-mode: difference">
                        {{ form.mainColor.toLocaleUpperCase() }}
                      </p>
                    </b-button>
                  </b-field>

                  <div class="columns">
                    <div class="column">
                      <div class="label">
                        Logo
                      </div>
                    </div>
                    <div class="column">
                      <div v-if="form.logo?.name" class="is-flex is-justify-content-flex-end">
                        <b-button
                          @click="discardCompanyLogo"
                          type="is-danger"
                          outlined>
                          <f-icon icon="fa-regular fa-trash" />
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <ValidationProvider rules="image" v-slot="{ errors, valid }">
                    <b-field
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-upload
                        accept=".png, .jpg, .svg"
                        drag-drop
                        expanded
                        v-model="form.logo">
                        <section class="section">
                          <div class="content has-text-centered">
                            <p>
                              <f-icon icon="fa-regular fa-file-arrow-up" size="2x" />
                            </p>
                            <p>{{ form.logo?.name || "Arrastrar y soltar archivo" }}</p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-4">
                  <ValidationProvider rules="max:256" name="Descripción" v-slot="{ errors, valid }">
                    <b-field
                      label="Descripción"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-input
                        maxlength="256"
                        type="textarea"
                        v-model="form.description"
                      />
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider rules="max:256" name="Objetivo" v-slot="{ errors, valid }">
                    <b-field
                      label="Objetivo"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-input
                        maxlength="256"
                        type="textarea"
                        v-model="form.objective"
                      />
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider
                    rules="required|numeric|max:5"
                    name="Remuneración promedio"
                    v-slot="{ errors, valid }">
                    <b-field
                      label="Remuneración promedio"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-input
                        icon="money-bill"
                        type="number"
                        v-model="form.remuneration"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-4">
                  <ValidationProvider rules="required" name="Estado" v-slot="{ errors, valid }">
                    <b-field
                      label="Estado"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-select
                        v-if="mxLocation"
                        icon="location-pin"
                        v-model="form.state"
                        expanded>
                        <option
                          v-for="({name}, index) in states"
                          :key="`state-${index}`"
                          :value="name"
                        >
                          {{ name }}
                        </option>
                      </b-select>
                      <b-select
                        v-else
                        icon="location-pin"
                        v-model="form.state"
                        expanded
                      >
                        <option 
                          v-for="(province, index) in provinces"
                          :key="`province-${index}`"
                          :value="province.name"
                        >
                        {{ province.name }}
                        </option >

                      </b-select>
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider rules="required" name="Sector" v-slot="{ errors, valid }">
                    <b-field
                      label="Sector"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-select
                        icon="objects-column"
                        v-model="form.sector"
                        expanded>
                        <option
                          v-for="sector in businessSector"
                          :key="`${sector}`"
                          :value="sector">
                            {{ sector }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>

                  <div class="columns" style="margin-bottom: 0;">
                    <div class="column">
                      <ValidationProvider rules="required" name="Burocracía" v-slot="{ errors, valid }">
                        <b-field
                          label="Burocracía"
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors">
                          <b-select
                            icon="files"
                            v-model="form.bureaucracyLevel"
                            expanded>
                            <option
                              v-for="level in levels"
                              :key="`${level}`"
                              :value="level">
                                {{ level }}
                            </option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="column">
                      <ValidationProvider rules="required" name="Impacto" v-slot="{ errors, valid }">
                        <b-field
                          label="Impacto"
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors">
                          <b-select
                            icon="shield"
                            v-model="form.customerImpact"
                            expanded>
                            <option
                              v-for="level in levels"
                              :key="`${level}`"
                              :value="level">
                                {{ level }}
                            </option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>

                  <ValidationProvider rules="required" name="Coordinador principal" v-slot="{ errors, valid }">
                    <b-field
                      label="Coordinador principal"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-select
                        icon="user-bounty-hunter"
                        v-model="form.headRecruiterManager"
                        expanded>
                        <option
                          v-for="recruiterManager, index in recruiterManagers"
                          :key="`recruiterManager-${index}`"
                          :value="recruiterManager">
                            {{ recruiterManager.fullName }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider rules="required" name="Reclutador principal" v-slot="{ errors, valid }">
                    <b-field
                      label="Reclutador principal"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-select
                        icon="user-bounty-hunter"
                        v-model="form.headRecruiter"
                        expanded>
                        <option
                          v-for="recruiter, index in recruiters"
                          :key="`recruiter-${index}`"
                          :value="recruiter">
                            {{ recruiter.fullName }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>

                  <b-field>
                    <b-button
                      :disabled="hasRecommendation"
                      @click="getRecruitersRecommendation"
                      expanded
                      focused
                      type="is-primary is-light">
                        Obtener recomendación
                    </b-button>
                  </b-field>
                </div>

              </div>
            </ValidationObserver>
          </div>

          <div class="modal-card-foot is-justify-content-flex-end">
            <b-button rounded @click="close">
              Cancelar
            </b-button>
            <b-button rounded type="is-primary" @click="updateCompany" v-if="company">
              Guardar
            </b-button>
            <b-button rounded type="is-primary" @click="createCompany" v-else>
              Guardar
            </b-button>
          </div>

        </div>
      </b-modal>

      <b-loading :is-full-page="true" :active="isLoading" />
    </div>
</template>

<script>
import states from '@/lib/states'
import levels from '@/lib/levels'
import businessSector from '@/lib/businessSector'
import provinces from '@/lib/ES/provinces'

export default {
  props: {
    company: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      // Company form
      form: {
        name: '',
        mainColor: this.randomColor(),
        description: '',
        objective: '',
        remuneration: '',
        state: '',
        sector: '',
        bureaucracyLevel: '',
        customerImpact: '',
        headRecruiterManager: '',
        headRecruiter: '',
        logo: null,
      },
      // Reactive data
      recruiters: null,
      recruiterManagers: null,
      //Flags
      hasRecommendation: true,
      // Imported libraries
      states,
      levels,
      businessSector,
      provinces
    };
  },
  computed: {
    mxLocation() {
      return process.env.VUE_APP_LOCATION === 'mx'
    }
  },
  methods: {
    async open(){
      await this.getRecruiters()
      if(this.company) this.setCompany()
      this.isOpen = true
    },
    close(){
      this.resetForm()
      this.$emit('update');
      this.isOpen = false
    },
    resetForm(){
      this.form = {
        id: '',
        name: '',
        mainColor: this.randomColor(),
        description: '',
        objective: '',
        remuneration: '',
        state: '',
        sector: '',
        bureaucracyLevel: '',
        customerImpact: '',
        headRecruiterManager: '',
        headRecruiter: ''
      }
    },
    async getRecruiters() {
      try {
        const { data } = await this.$api.get( '/users/all-recruiters' )
        this.recruiters = data
        this.recruiterManagers = data.filter(recruiter => recruiter.role === 'recruiter-manager')
      } catch ( error ) {
        this.$buefy.toast.open( {
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        } )
      }
    },
    async getRecruitersRecommendation() {
      this.isLoading = true
      const params = [
        `sectorSelected=${this.form.sector}`,
        `scholarshipSelected=${this.form.remuneration ? this.form.remuneration : 0}`,
        `stateSelected=${this.form.state.name}`
      ]
      try {
        const { data } = await this.$api.get( `/users/recruiters-recommendation?${params.join('&')}` )
        this.recruiters = data
      } catch ( error ) {
        this.$buefy.toast.open( {
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        } )
      }
      this.isLoading = false
    },
    setCompany(){
      this.form = {
        name: this.company.name,
        mainColor: this.company.mainColor,
        description: this.company.description,
        objective: this.company.objective,
        remuneration: this.company.remuneration,
        sector: this.company.sector,
        bureaucracyLevel: this.company.bureaucracyLevel,
        customerImpact: this.company.customerImpact,
        logo: null
      }
      
      if(this.mxLocation) this.form.state = (this.states.find( item => item.name === this.company.state)).name
      else this.form.state = (this.provinces.find( item => item.name === this.company.state )).name

      if(this.recruiters.length > 0){
        this.form.headRecruiter = this.recruiters.find( item =>  item.id === this.company.headRecruiter )
      }
      if(this.recruiterManagers.length > 0){
        this.form.headRecruiterManager = this.recruiterManagers.find( item => item.id === this.company.headRecruiterManager)
      }
      if(this.company.logo){
        this.form.logo ={
          name: this.company.logo.name,
          key: this.company.logo.key,
          url: this.company.logo.url
        }
      }
    },
    async updateCompanyLogo(id) {
        const payload = new FormData();
        payload.append("file", this.form.logo);

        try {
          this.isLoading = true;
          await this.$api.post(`/companies/${id}/update-logo`, payload);

        } catch (error) {
          const message = error.response.data?.message || error;
          this.$buefy.toast.open({
            message: message,
            type: "is-danger",
          });
        }
        this.isLoading = false;
    },
    async discardCompanyLogo() {
        this.form = {
          ...this.form,
          logo: null
        }
      },
    async createCompany () {
      const valid = await this.$refs.observer.validate()
      if ( valid ) {
        this.isLoading = true

        const payload = {
          ...this.form,
          headRecruiter: this.form.headRecruiter.id,
          headRecruiterManager: this.form.headRecruiterManager.id,
          mainColor: this.form.mainColor.toUpperCase()
        }
        delete payload.logo

        try {
          const { data } = await this.$api.post('/companies', payload)
          await this.updateCompanyLogo(data.id)

          this.$emit('update')
          this.close()
          this.$buefy.toast.open( {
            message: 'La empresa se ha creado con éxito',
            type: 'is-success'
          } )
        } catch ( error ) {
          this.$buefy.toast.open( {
            message: !error.response ? error : error.response.data.message,
            type: 'is-danger'
          } )
        }
        this.createDialog = false
        this.isLoading = false
      }
    },
    // Update company
    async updateCompany () {
      const valid = await this.$refs.observer.validate()
      if ( valid ) {

        const payload = {
          ...this.form,
          headRecruiter: this.form.headRecruiter.id,
          headRecruiterManager: this.form.headRecruiterManager.id,
          mainColor: this.form.mainColor.toUpperCase()
        }
        delete payload.logo

        try {
          const { data } = await this.$api.put( `/companies/${this.company.id}`, payload)

          if(!this.form.logo?.url) // Updating just if there's a file
            await this.updateCompanyLogo(data.id)
          this.$emit('update')
          this.close()
          this.$buefy.toast.open( {
            message: 'La empresa se ha actualizado con éxito',
            type: 'is-success'
          } )
        } catch ( error ) {
          const message = error.response?.data?.message || error.response?.message || error
          this.$buefy.toast.open( {
            message,
            type: 'is-danger'
          })
        }
      }
    },
    // Random color
    randomColor () {
      let code = '#'
      for (let i = 0; i < 6; i++) {
        const caracter = Math.floor(Math.random() * 16).toString(16).toUpperCase()
        code += caracter
      }
      return code
    },
  },
};
</script>

<style lang="scss" scoped>

</style>