import Calendar from '@/screens/Calendar'

const caledar = [
  {
    path: '/calendario',
    component: Calendar,
    name: 'Calendar',
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'manager', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager']
    }
  }
]

export default caledar
