<template>
  <div class="card has-background-dark has-text-light">
    <div class="card-header">
      <div class="column">
        <b-icon icon="arrows-up-down-left-right" />
        Vacantes
      </div>
    </div>
    <div class="card-content">
      Este módulo contiene las vacantes abiertas de esta empresa
    </div>
  </div>
 </template>
 
 <script>
 export default {
 }
 </script>
 
 <style scoped></style>