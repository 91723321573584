<template>
  <div class="card mt-5 mb-5">
    <div class="card-content columns is-multiline">
      <div class="column">
        <b-field label="Color de títulos">
          <ColorPicker :value="colors.title" @input="updateTitleColor" />
        </b-field>
      </div>

      <div class="column">
        <b-field label="Color de texto en beneficios">
          <ColorPicker :value="colors.benefits" @input="updateBenefitsColor" />
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  components: {
    ColorPicker: Chrome,
  },
  props: {
    defaultColors: {
      title: String,
      benefits: String
    }
  },
  data() {
    return {
      colors: {
        title: '#42CBB6',
        benefits: '#42CBB6'
      }
    }
  },
  mounted() {
    this.colors = { ...this.defaultColors };
  },
  watch: {
    defaultColors: {
      handler: function (val) {
        this.colors = { ...val }
      }
    },
    
  },
  methods: {
    updateTitleColor(color) {
      this.colors.title = color.hex;
      this.updateColors();
    },
    updateBenefitsColor(color) {
      this.colors.benefits = color.hex;
      this.updateColors();
    },
    updateColors() {
      this.$emit('updateLandingProperty', this.colors, 'colors');
    },
  }
}
</script>

<style>
.sort-element {
  cursor: grab !important;
}
</style>
