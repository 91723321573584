<template>
  <div class="pl-10">
    <div class="columns">
      <div class="column is-8">
        <div class="title is-4 pt-2">Killer Questions</div>
      </div>
      <div class="column is-4 has-text-right">
        <b-button
          @click="addKillerQuestion"
          type="is-primary"
          outlined
          label="Agregar pregunta"
          icon-left="plus"
        />
      </div>
    </div>

    <ValidationObserver ref="observer">
      <div
        class="columns is-multiline"
        v-for="(killerQuestion, index) in killerQuestions"
        :key="`question-${index}`"
      >
        <div class="column is-8">
          <ValidationProvider
            rules="required"
            :name="`killer-question-${index}`"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="`${index + 1}.- ${killerQuestion.question}`"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input v-model="killerQuestion.question"></b-input>
            </b-field>
          </ValidationProvider>
        </div>

        <div class="column is-3">
          <ValidationProvider
            rules="required"
            :name="`Tipo de respuesta ${index}`"
            v-slot="{ errors, valid }"
          >
            <b-field
              label="Tipo de respuesta"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-model="killerQuestion.questionType"
                expanded
              >
                <option
                  v-for="(option, index) in killerQuestionOptions"
                  :key="`option-${index}`"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <div class="column is-1 is-flex align-flex-end pb-4">
          <b-button
            type="is-danger"
            @click="popKillerQuestion(index, killerQuestion._id)"
            outlined
            icon-left="trash"
          />
        </div>

        <div
          class="column is-8"
          v-if="hasOptionsValue.includes(killerQuestion.questionType)"
        >
          <div
            v-for="(option, index) in killerQuestion.optionsValues"
            :key="`option-${index}`"
          >
            <ValidationProvider
              rules="required"
              :name="`opción${index}`"
              v-slot="{ errors, valid }"
            >
              <b-field
                :label="`Opción ${index + 1}`"
                :type="{ 'is-danger': errors.length > 0, 'is-success': valid }"
                :message="errors[0]"
              >
                <b-input
                  v-model="killerQuestion.optionsValues[index]"
                  aria-label="Ingresa una opción"
                />
              </b-field>
            </ValidationProvider>
          </div>
        </div>

        <div
          class="column is-4 is-flex is-justify-content-space-evenly is-align-content-center"
          v-if="hasOptionsValue.includes(killerQuestion.questionType)"
        >
          <b-button
            @click="killerQuestion.optionsValues.push('')"
            icon-left="plus"
            type="is-blue"
            outlined
          />

          <div>Opciones</div>

          <b-button
            @click="killerQuestion.optionsValues.pop()"
            icon-left="minus"
            type="is-danger"
            outlined
          />
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
  import killerQuestionOptions from "@/lib/killerQuestions";

  export default {
    props: {
      vacancy: {
        type: String,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        killerQuestionOptions,
        killerQuestions: [],
        hasOptionsValue: ["single-option", "multiple-option"],
        deletedQuestions: [],
      };
    },
    async mounted() {
      await this.getKillerQuestions();
    },
    methods: {
      addKillerQuestion() {
        this.killerQuestions.push({
          question: "",
          questionType: "",
          optionsValues: [""],
        });
      },
      popKillerQuestion(index, id) {
        this.killerQuestions.splice(index, 1);
        this.deletedQuestions.push({ id });
      },
      async deleteKillerQuestion() {
        try {
          const payload = this.deletedQuestions;
          await this.$api.put(`/vacancies/${this.vacancy}/killer-questions/delete`, payload);
        } catch (error) {
          this.$buefy.toast.open({
            message: !error.response ? error : error.response.data.message,
            type: "is-danger",
          });
        }
      },
      async createKillerQuestion(id) {
        if (this.killerQuestions.length > 0) {
          const valid = await this.$refs.observer.validate();

          if (valid) {
            const payload = this.killerQuestions;
            try {
              await this.$api.post(`vacancies/${id}/killer-questions`, payload);
            } catch (error) {
              this.$buefy.toast.open({
                message: !error.response ? error : error.response.data.message,
                type: "is-danger",
              });
            }
          } else {
            this.$buefy.toast.open({
              message: "Killer Questions incompleto",
              type: "is-warning",
            });
          }
        }
      },
      async getKillerQuestions() {
        if (this.vacancy) {
          try {
            const { data } = await this.$api.get(`/vacancies/${this.vacancy}/killer-questions`);
            this.killerQuestions = data;
          } catch (error) {
            this.$buefy.toast.open({
              message: !error.response ? error : error.response.data.message,
              type: "is-danger",
            });
          }
        }
      },
      async updateKillerQuestion() {
        try {
          const payload = this.killerQuestions;
          await this.$api.put(`vacancies/${this.vacancy}/killer-questions`, payload);
        } catch (error) {
          this.$buefy.toast.open({
            message: !error.response ? error : error.response.data.message,
            type: "is-danger",
          });
        }

        if (this.deletedQuestions.length > 0) {
          await this.deleteKillerQuestion();
        }
      },
    },
  };
</script>

<style></style>
