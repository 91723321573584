<template>
  <div>
    <div class="columns">

      <div class="column">
        <span class="is-size-3">Empresas</span>
      </div>

      <CompaniesProgress/>
    </div>

    <div class="columns">

      <div class="column">
        <b-button
          rounded
          type="is-primary"
          :href="downloadCompanies"
          target="_blank"
          tag="a"
          icon-left="down-to-line">
          Descargar empresas
        </b-button>
      </div>

      <div class="column">
        <div class="mb-20 is-flex justify-flex-end">
          <form
            @submit="search"
            class="is-flex is-justify-content-flex-end align-flex-end mr-4"
          >
            <b-field class="mb-0">
              <b-input
                v-model="nameToSearch"
                class="mr-10"
                placeholder="Buscar empresa"
                icon="magnifying-glass"
                rounded/>
            </b-field>
            <b-button
              rounded
              type="is-primary"
              native-type="submit"
              icon-left="magnifying-glass"
            />
          </form>
          <b-button
            rounded
            v-if="$hasPermissionsByRole(companyCreationEditionRoles)"
            type="is-primary"
            icon-right="plus"
            @click="openCreateDialog">
            Crear empresa
          </b-button>
        </div>
      </div>

    </div>

    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="columns is-multiline">
          <div
            v-for="company in companies"
            :key="company.id"
            class="column is-4"
          >
            <CompanyCard
              :company="company"
              @update="getCompanies"
              @delete="getCompanies"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-pagination
          :current.sync="current"
          :per-page="perPage"
          :range-after="rangeAfter"
          :range-before="rangeBefore"
          :total="total"
          aria-current-label="Current page"
          aria-next-label="Next page"
          aria-page-label="Page"
          aria-previous-label="Previous page"
          order="is-centered"
          rounded>
        </b-pagination>
      </div>
    </div>

    <b-modal
      :active.sync="createDialog"
      :destroy-on-hide="false"
      @cancel="cancel"
      aria-modal
      aria-role="dialog"
      has-modal-card
      trap-focus
      width>

      <div class="modal-card" style="width: 68vw;">

        <div class="modal-card-head">
          <div class="modal-card-title">
            Nueva empresa
          </div>
        </div>

        <div class="modal-card-body">
          <ValidationObserver ref="observer">
            <div class="columns">

              <div class="column is-4">
                <ValidationProvider rules="required|max:64" name="Nombre" v-slot="{ errors, valid }">
                  <b-field
                    label="Nombre"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors">
                    <b-input
                      icon="building"
                      type="text"
                      v-model="form.name"
                    />
                  </b-field>
                </ValidationProvider>

                <ValidationProvider rules="required|min:7|max:7" v-slot="{ errors, valid }">
                  <b-field
                    label="Color"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors">
                    <b-input
                    icon="palette"
                    v-model="form.mainColor"
                    />
                  </b-field>
                </ValidationProvider>

                <b-field>
                  <b-button
                    :style="`
                      background-color: ${form.mainColor};
                      pointer-events: none; color: white;
                    `"
                    expanded>
                    <p style="mix-blend-mode: difference">
                      {{ form.mainColor.toLocaleUpperCase() }}
                    </p>
                  </b-button>
                </b-field>

                <div class="label">
                  Logo
                </div>
                <ValidationProvider rules="image" v-slot="{ errors, valid }">
                  <b-field
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors">
                    <b-upload
                      accept=".png, .jpg, .svg"
                      drag-drop
                      expanded
                      v-model="file">
                      <section class="section">
                        <div class="content has-text-centered">
                          <p>
                            <f-icon icon="fa-regular fa-file-arrow-up" size="2x" />
                          </p>
                          <p>{{ fileName || "Arrastrar y soltar archivo" }}</p>
                        </div>
                      </section>
                    </b-upload>
                    <!-- <b-upload
                      v-model="file"
                      accept=".png, .jpg, .svg"
                      expanded>
                      <a class="button is-primary is-fullwidth">
                        <b-icon icon="file-arrow-up"></b-icon>
                        <span>{{ fileName || "Arrastrar y soltar archivo" }}</span>
                      </a>
                    </b-upload> -->
                  </b-field>
                </ValidationProvider>
              </div>

              <div class="column is-4">
                <ValidationProvider rules="max:256" name="Descripción" v-slot="{ errors, valid }">
                  <b-field
                    label="Descripción"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors">
                    <b-input
                      maxlength="256"
                      type="textarea"
                      v-model="form.description"
                    />
                  </b-field>
                </ValidationProvider>

                <ValidationProvider rules="max:256" name="Objetivo" v-slot="{ errors, valid }">
                  <b-field
                    label="Objetivo"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors">
                    <b-input
                      maxlength="256"
                      type="textarea"
                      v-model="form.objective"
                    />
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  rules="required|numeric|max:5"
                  name="Remuneración promedio"
                  v-slot="{ errors, valid }">
                  <b-field
                    label="Remuneración promedio"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors">
                    <b-input
                      icon="money-bill"
                      type="number"
                      v-model="form.remuneration"
                    />
                  </b-field>
                </ValidationProvider>
              </div>

              <div class="column is-4">
                <ValidationProvider rules="required" name="Estado" v-slot="{ errors, valid }">
                  <b-field
                    label="Estado"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors">
                    <b-select
                      icon="location-pin"
                      v-model="form.state"
                      expanded>
                      <option
                        v-for="(state, index) in states"
                        :key="`state-${index}`"
                        :value="state"
                      >
                        {{ state.name }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider rules="required" name="Sector" v-slot="{ errors, valid }">
                  <b-field
                    label="Sector"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors">
                    <b-select
                      icon="objects-column"
                      v-model="form.sector"
                      expanded>
                      <option
                        v-for="sector in businessSector"
                        :key="`${sector}`"
                        :value="sector">
                          {{ sector }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>

                <div class="columns" style="margin-bottom: 0;">
                  <div class="column">
                    <ValidationProvider rules="required" name="Burocracía" v-slot="{ errors, valid }">
                      <b-field
                        label="Burocracía"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">
                        <b-select
                          icon="files"
                          v-model="form.bureaucracyLevel"
                          expanded>
                          <option
                            v-for="level in levels"
                            :key="`${level}`"
                            :value="level">
                              {{ level }}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column">
                    <ValidationProvider rules="required" name="Impacto" v-slot="{ errors, valid }">
                      <b-field
                        label="Impacto"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">
                        <b-select
                          icon="shield"
                          v-model="form.customerImpact"
                          expanded>
                          <option
                            v-for="level in levels"
                            :key="`${level}`"
                            :value="level">
                              {{ level }}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>

                <ValidationProvider rules="required" name="Coordinador principal" v-slot="{ errors, valid }">
                  <b-field
                    label="Coordinador principal"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors">
                    <b-select
                      icon="user-bounty-hunter"
                      v-model="form.headRecruiterManager"
                      expanded>
                      <option
                        v-for="recruiterManager, index in recruiterManagers"
                        :key="`recruiterManager-${index}`"
                        :value="recruiterManager">
                          {{ recruiterManager.fullName }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider rules="required" name="Reclutador principal" v-slot="{ errors, valid }">
                  <b-field
                    label="Reclutador principal"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors">
                    <b-select
                      icon="user-bounty-hunter"
                      v-model="form.headRecruiter"
                      expanded>
                      <option
                        v-for="recruiter, index in recruiters"
                        :key="`recruiter-${index}`"
                        :value="recruiter">
                          {{ recruiter.fullName }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>

                <b-field>
                  <b-button
                    :disabled="hasRecommendation"
                    @click="getRecruitersRecommendation"
                    expanded
                    focused
                    type="is-primary is-light">
                      Obtener recomendación
                  </b-button>
                </b-field>
              </div>

            </div>
          </ValidationObserver>
        </div>

        <div class="modal-card-foot is-justify-content-flex-end">
          <b-button rounded @click="cancel">
            Cancelar
          </b-button>
          <b-button rounded type="is-primary" @click="createCompany">
            Guardar
          </b-button>
        </div>

      </div>
    </b-modal>

    <CompanyModal ref="CompanyModal" :company="null" @update="getCompanies"/>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import CompanyCard from '@/components/CompanyCard'
import CompaniesProgress from '@/screens/CompaniesProgress.vue'
import CompanyModal from '../components/modals/CompanyModal.vue'
import { companyCreationEditionRoles } from '../lib/permissions'

export default {
  name: 'CompaniesList',
  components: {
    CompanyCard,
    CompaniesProgress,
    CompanyModal
  },
  data() {
    return {
      companyCreationEditionRoles,
      isLoading: false,
      authRoles: {
        one: [ 'superqueen', 'manager', 'recruiter-manager', 'recruiter', 'designer-manager', 'designer' ],
        two: [ 'superqueen', 'manager','designer-manager', 'designer' ],
        three: [ 'manager',
          'recruiter-manager','recruiter',
          'designer-manager', 'designer',
          'account-manager','account' ],
      },
      companies: [],
      nameToSearch: '',
      createDialog: false,
      form: {
        bureaucracyLevel: null,
        customerImpact: null,
        description: null,
        headRecruiter: null,
        headRecruiterManager: null,
        mainColor: this.randomColor(),
        name: null,
        objective: null,
        remuneration: null,
        sector: null,
        state: null,
      },
      recruiters: null,
      recruiterManagers: null,
      file: null,
      fileName : null,
      hasRecommendation: true,
      current: 1,
      perPage: 12,
      rangeBefore: 3,
      rangeAfter: 3,
      total: 0
    }
  },
  computed: {
    token () {
      return this.$store.state.session.token
    },
    user () {
      return this.$store.state.session.user
    },
    downloadCompanies () {
      return `${process.env.VUE_APP_API_URL}/v1/companies/export/${this.user.id}?token=${this.token}`
    }
  },
  watch: {
    'form.state' () {
      if ( this.form.sector === null ) {
        this.hasRecommendation = true
      } else {
        this.hasRecommendation = false
      }
    },
    'form.sector' () {
      if ( this.form.state === null ) {
        this.hasRecommendation = true
      } else {
        this.hasRecommendation = false
      }
    },
    'file': function ( val ) {
      if ( val ) {
        this.fileName = this.file.name
      }
    },
    async current () {
      await this.getCompanies()
    }
  },
  methods: {
    openCreateDialog () {
      this.$refs.CompanyModal.open()
    },
    cancel () {
      this.createDialog = false
      this.updateDialog = false
      this.clean()
    },
    clean () {
      this.$refs.observer.reset()
      this.form = {
        bureaucracyLevel: null,
        customerImpact: null,
        description: null,
        headRecruiter: null,
        headRecruiterManager: null,
        mainColor: this.randomColor(),
        name: null,
        objective: null,
        remuneration: null,
        sector: null,
        state: null,
      },
      this.file = null
      this.fileName = null
      this.hasRecommendation = true
    },
    randomColor () {
      let code = '#'
      for (let i = 0; i < 6; i++) {
        const caracter = Math.floor(Math.random() * 16).toString(16).toUpperCase()
        code += caracter
      }
      return code
    },
    async getRecruitersRecommendation() {
      this.isLoading = true
      const params = [
        `sectorSelected=${this.form.sector}`,
        `scholarshipSelected=${this.form.remuneration ? this.form.remuneration : 0}`,
        `stateSelected=${this.form.state.name}`
      ]
      try {
        const { data } = await this.$api.get( `/users/recruiters-recommendation?${params.join('&')}` )
        this.recruiters = data
      } catch ( error ) {
        this.$buefy.toast.open( {
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        } )
      }
      this.isLoading = false
    },
    async createCompany () {
      const valid = await this.$refs.observer.validate()
      if ( valid ) {
        this.isLoading = true

        this.form.mainColor = this.form.mainColor.toUpperCase()
        this.form.headRecruiter = this.form.headRecruiter.id
        this.form.headRecruiterManager = this.form.headRecruiterManager.id
        this.form.state = this.form.state.name

        try {
          const { data } = await this.$api.post('/companies', this.form)
          if ( this.file ) {
            const formData = new FormData()
            formData.append( 'file', this.file )
            await this.$api.post( `/companies/${data.id}/upload-logo`, formData,{
              headers: { 'Content-Type': 'multipart/form-data' }
            } )
          }
          await this.getCompanies()
          this.clean()
          this.$buefy.toast.open( {
            message: 'La empresa se ha creado con éxito',
            type: 'is-success'
          } )
        } catch ( error ) {
          this.$buefy.toast.open( {
            message: !error.response ? error : error.response.data.message,
            type: 'is-danger'
          } )
        }
        this.createDialog = false
        this.isLoading = false
      }
    },
    async search (e) {
      e.preventDefault()
      if ( this.nameToSearch ) {
        const params = []
        if ( this.authRoles.three.includes( this.$isRole( this.user ) ) ) {
          params.push (
            `populate=company`,
            `user=${this.user.id}`,
            `active=true`
          )
        }
        params.push( `name=/${this.nameToSearch}/i` )
        await this.getCompanies( params )
      } else {
        await this.getCompanies()
      }

    },
    async getCompanies ( params ) {
      this.isLoading = true
      try {
        if ( this.authRoles.three.includes( this.$isRole( this.user ) ) ) {
          params = params || [
            `user=${this.user.id}`,
            `active=true`,
            `skip=${(this.current - 1) * this.perPage}`,
            `limit=${this.perPage}`
          ]
          const response = await this.$api.get( `/companies?${params.join('&')}` )
          this.companies = response.data.data.map( item => item.company )
          this.total = response.data.total
        } else {
          params = params || [
            `skip=${(this.current - 1) * this.perPage}`,
            `limit=${this.perPage}`
          ]
          const response = await this.$api.get( `/companies?${params.join('&')}` )
          this.companies = response.data.data
          this.total = response.data.total
        }
      } catch ( error ) {
        this.$buefy.toast.open( {
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        } )
      }
      this.isLoading = false
    },
  },
  async mounted () {
    await this.getCompanies()
  },
}
</script>
