<template>
  <div
    class="card is-shadowless"
    style="border-radius: 30px"
  >
    <div class="card-content p-5">
      <div>
        <div class="title is-4 has-text-blue mb-4">{{ labels.companyComments }}</div>
      </div>

      <b-input
        placeholder="Feedback del candidato"
        type="textarea"
        v-model="form.feedback"
      />
      <div class="is-flex justify-flex-end pt-10">
        <b-button
          type="is-primary"
          @click="doneCompanyInterview"
        >
          Agregar comentarios y concluir entrevista
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { companyFeedback } from '@/mixins/companyFeedback';

export default {
  props: {
  },
  mixins: [companyFeedback], 
  data() {
    return {
      
    };
  },
  computed: {
    labels() {
        return this.$t("screens.candidate.detail");
    },
  },
  methods: {
    async doneCompanyInterview() {
      this.$emit("doneCompanyInterview", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>