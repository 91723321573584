import {
  confirmed,
  digits,
  email,
  image,
  length,
  max_value,
  max,
  min,
  min_value,
  numeric,
  required,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("confirmed", {
  ...confirmed,
  message: "Confirma la contraseña",
});

extend("required", {
  ...required,
  message: "Este campo es requerido",
});

extend("email", {
  ...email,
  message: "Introduce un email válido",
});

extend("numeric", {
  ...numeric,
  message: "El valor debe ser numérico",
});

extend("digits", {
  ...digits,
  message: (value, { length }) => `Ingresa ${length} dígitos`,
});

extend("min_value", {
  ...min_value,
  message: (value, { min }) => `El valor debe ser mayor que ${min}`,
});

extend("max", {
  ...max,
  message: (value, { length }) =>
    `El número de caracteres debe ser menor a ${length + 1}`,
});

extend("min", {
  ...min,
  message: (value, { length }) =>
    `El número de caracteres debe ser mayor a ${length - 1}`,
});

extend("max_value", {
  ...max_value,
  message: (value, { max }) => `El valor debe ser menor que ${max}`,
});

extend("length", {
  ...length,
  message: (value, { length }) =>
    `La longitud del valor debe ser igual a ${length}`,
});

extend("image", {
  ...image,
  message: (value, { name }) => `El tipo de archivo ${name} no es una imagen`,
});

extend("hour", {
  params: ["hour"],
  validate: (value) => {
    const formatHour = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return formatHour.test(value);
  },
  message: "Ingresa la hora en formato de 24hrs",
});

extend("fileSize", {
  params: ["size"],
  validate: (value, { size }) => {
    if (value.size) {
      return value.size / (1024 * 1024) <= size;
    }
    return true;
  },
  message: (value, { size }) => `El archivo debe pesar máximo ${size} MB`,
});

// Agregar la validación de complejidad de contraseña
extend("password_complexity", {
  validate: (value) => {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[@$!%*?&]/.test(value);
    const minLength = value.length >= 12;

    if (!minLength) {
      return "La contraseña debe tener al menos 12 caracteres.";
    }
    if (!hasUpperCase) {
      return "Debe incluir al menos una letra mayúscula.";
    }
    if (!hasLowerCase) {
      return "Debe incluir al menos una letra minúscula.";
    }
    if (!hasNumber) {
      return "Debe incluir al menos un número.";
    }
    if (!hasSpecialChar) {
      return "Debe incluir al menos un carácter especial (@$!%*?&).";
    }

    return true;
  },
  message: "La contraseña no cumple con los requisitos de seguridad.",
});
