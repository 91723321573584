export default[
  {
    text: 'Abierta corta',
    value: 'open-short'
  },
  {
    text: 'Abierta larga',
    value: 'open-long'
  },
  {
    text: 'Opción única',
    value: 'single-option'
  },
  {
    text: 'Opción múltiple',
    value: 'multiple-option'
  }
]