import httpApi from '@/axios'
import { ToastProgrammatic as Toast } from 'buefy'

const getCandidate = async function( id ){
  try {
    const { data } = await httpApi.get(`/candidates/${id}`);
    return data
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: "is-danger",
    });
  }
} 

const sendCustomEvaluation = async function ( id, payload ){
  try {
    await httpApi.post(`application/${id}/set-custom-evaluation`, payload);
    Toast.open({
      message: "Se guardó la evaluación personalizada del candidato",
      type: "is-success",
    });
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: "is-danger",
    });
  }
}

const sendCompanyFeedback = async function ( id, payload ){
  try {
    await httpApi.post(`application/${id}/company-feedback`, payload);
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: "is-danger",
    });
  }
}

export  {
  getCandidate,
  sendCustomEvaluation,
  sendCompanyFeedback
}
