<template>
  <div v-if="section.name" class="card">
    <div class="card-header">
      <div class="column">
        <div class="columns">
          <div class="column">
            <b-icon icon="arrows-up-down-left-right" />
            Preguntas frecuentes
          </div>
          <div class="column has-text-right">
            Mostrar sección:
            <b-switch v-model="section.faqs.active" @input="updateSection" />
          </div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div v-for="(ann, index) in section.faqs.questions" :key="index">
        <div class="columns">
          <div class="column">
            <b-field>
              <b-input v-model="ann.question" placeholder="Pregunta de la convocatoria" @blur="updateSection"/>
            </b-field>
            <b-field>
              <b-input v-model="ann.answer" type="textarea"
                placeholder="¿Cuáles son los beneficios ofrecidos a los becarios?" @blur="updateSection" />
            </b-field>
          </div>
          <div class="column">
            <div class="buttons" style="justify-content: flex-end">
              <b-button type="is-danger" icon-right="trash" @click="removeQuestion(index)" />
            </div>
          </div>
        </div>
        <hr>
      </div>
      <b-button @click="addQuestion">
        Agregar pregunta
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    defaultSection: {
      type: Object,
    },
    uploadImage: {
      type: Function,
    },
    deleteImage: {
      type: Function,
    },
  },
  data() {
    return {
      section: {},
      isLoading: false,
    }
  },
  mounted() {
    this.section = {...this.defaultSection};
  },
  watch: {
    defaultSection: {
      handler: function (val) {
        this.section = {...val }
      }
    },
  },
  methods: {
    updateSection() {
      this.$emit('updateSection', this.section)
    },
    async uploadLocalImage(e, section){
      this.isLoading = true;
      const file = e;
      const fileIdentifier = section.name;
      const fileMetadata = section[section.name].image;
      section[section.name].image = await this.uploadImage(file, fileIdentifier, fileMetadata);

      this.updateSection();
      this.isLoading = false;
    },
    async deleteLocalImage(section){
      this.isLoading = true;
      section[section.name].image = await this.deleteImage(section[section.name].image.key);

      this.updateSection();
      this.isLoading = false;
    },
    removeQuestion(index) {
      this.section.faqs.questions.splice(index, 1);
      this.updateSection();
    },
    addQuestion() {
      this.section.faqs.questions.push({
        question: "",
        answer: "",
      });
      this.updateSection();
    },
  }
}
</script>

<style scoped>
.loading-wrapper{
    position: relative;
    min-height: 300px;
  }
</style>
