export default {
  state: "Provincia",
  goal: 'Descripción',
  activities: 'Funciones',
  benefit: 'Condiciones',
  minimumSemester: 'Curso mínimo',
  maximumSemester: 'Curso máximo',
  degree: 'Grado(s)',
  showScholarship: 'Mostrar remuneración',
  scholarship: 'Remuneración',
  maxPhoneDigits: '9',
}
