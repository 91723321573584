<template>
  <div class="card" v-if="section.name">
    <div class="card-header">
      <div class="column">
        <div class="columns">
          <div class="column">
            <b-icon icon="arrows-up-down-left-right" />
            Cualidades del becario
          </div>
          <div class="column has-text-right">
            Mostrar sección:
            <b-switch v-model="section.attributes.active" @input="updateSection" />
          </div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <b-field label="Cualidades del becario">
        <b-taginput v-model="section.attributes.attributes"
          placeholder="Presiona 'ENTER' para crear la cualidad" @input="updateSection"/>
      </b-field>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    defaultSection: {
      type: Object,
    },
  },
  data() {
    return {
      section: {},
      isLoading: false,
    }
  },
  mounted() {
    this.section = {...this.defaultSection};
  },
  watch: {
    defaultSection: {
      handler: function (val) {
        this.section = {...val}
      }
    },
  },
  methods: {
    updateSection() {
      this.$emit('updateSection', this.section)
    },
  }
}
</script>

<style scoped>
.loading-wrapper{
    position: relative;
    min-height: 300px;
  }
</style>
