<template>
  <div class="p-calendar">
    <Kalendar
      :configuration="calendar_settings"
      :events="events">
      <div slot="created-card" slot-scope="{ event_information }" class="details-card">
        <h4 class="appointment-title">{{event_information.data.title}}</h4>
        <small>
          {{event_information.data.description}}
        </small>
        <span class="time">{{event_information.start_time}} - {{event_information.end_time}}</span>
        <div class="d-flex remove">
          <b-button class="mr-10" size="is-small" type="is-blue" @click="openUpdateDialog(event_information)" icon-right="pencil"/>
          <b-button size="is-small" type="is-danger" @click="removeEvent(event_information)" icon-right="delete"/>
        </div>
      </div>

      <div slot="creating-card" slot-scope="{ event_information }">
        <h4 class="appointment-title" style="text-align: left;">
          Nuevo Evento
        </h4>
        <span class="time">
          {{event_information.start_time | formatToHours}}
          -
          {{event_information.end_time | formatToHours}}
        </span>
      </div>

      <div slot="popup-form" slot-scope="{ popup_information }" style="display: flex; flex-direction: column;">
        <h4 class="subtitle">
          Nuevo Evento
        </h4>
        <b-input
          v-model="new_appointment.title"
          type="text"
          size="is-small"
          placeholder="Nombre del evento"
        />
        <b-input
          v-model="new_appointment.description"
          type="textarea"
          size="is-small"
          placeholder="Descripción del evento"
          rows="2"
        />
        <div class="mb-10">
          Desde:
          <b-tag type="is-primary" size="is-small">
            <b-icon size="is-small" icon="clock" />
            {{ popup_information.start_time | formatToHours }}
          </b-tag>
        </div>
        <div class="mb-10">
          Hasta:
          <b-tag type="is-primary" size="is-small">
            <b-icon size="is-small" icon="clock" />
            {{ popup_information.end_time | formatToHours }}
          </b-tag>
        </div>
        <div class="buttons">
          <b-button size="is-small" rounded @click="closePopups()">
            Cancelar
          </b-button>
          <b-button size="is-small" rounded type="is-primary" @click="addEvent(popup_information)">
            Crear
          </b-button>
        </div>
      </div>
    </Kalendar>

    <b-modal
      :active.sync="updateDialog"
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-moda
      :width="300">
      <div class="modal-card" style="width: auto">
        <div class="modal-card-head">
          <div class="modal-card-title">
            Hola
          </div>
        </div>
        <div class="modal-card-body">

        </div>
        <div class="modal-card-foot">
          <b-button rounded @click="cancelUpdate">
            Cancelar
          </b-button>
          <b-button rounded type="is-primary" @click="updateEvent">
            Guardar
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Kalendar } from 'kalendar-vue'

export default {
  name: 'CalendarAgenda',
  components: {
    Kalendar
  },
  data () {
    return {
      updateDialog: false,
      calendar_settings: {
        view_type: 'week',
        style: 'material_design',
        cell_height: 10,
        scrollToNow: true,
        // start_day: new Date().toLocaleString(),
        read_only: false,
        day_starts_at: 0,
        day_ends_at: 24,
        overlap: true,
        hide_dates: ['2019-10-31'], // Spooky
        hide_days: [5, 6],
        past_event_creation: false
      },
      events: [
        {
          from: '2020-09-25T18:00:00Z',
          to: '2020-09-25T19:00:00Z',
          data: {
            title: 'Hola',
            description: 'Tenemos que ir a comer cosas'
          },
        }
      ],
      new_appointment: {
        data: {
          title: null,
          description: null
        },
        start_time: new Date(),
        end_time: new Date()
      },
      restrictedHours: [8, 18]
    }
  },
  methods: {
    addEvent(popup_info) {
      const hour = new Date(popup_info.start_time).getHours()
      if (hour > this.restrictedHours[0] && hour < this.restrictedHours[1]) {
        let payload = {
          data: {
            title: this.new_appointment.title,
            description: this.new_appointment.description,
          },
          from: popup_info.start_time,
          to: popup_info.end_time
        }

        this.$kalendar.addNewEvent(
          payload
        )
        this.closePopups()
        this.clearFormData()
      } else {
        this.$buefy.dialog.confirm({
            title: 'Oh oh',
            message: `Lo sentimos, a esta hora no estamos disponibles, pero prueba otra hora entre las ${this.restrictedHours[0]}hrs ó las ${this.restrictedHours[1]}hrs.`,
            confirmText: 'Ok, lo entiendo',
            canCancel: false,
            type: 'is-danger',
            hasIcon: true
        })
      }
    },
    removeEvent(kalendarEvent) {
      let day = kalendarEvent.start_time.slice(0, 10);
      this.$kalendar.removeEvent({
        day,
        key: kalendarEvent.key,
        id: kalendarEvent.kalendar_id,
      })
    },
    clearFormData () {
      this.new_appointment = {
        title: null,
        description: null
      }
    },
    updateEvent () {

    },
    closePopups () {
      this.$kalendar.closePopups()
      this.clearFormData()
    },
    cancelUpdate () {
      this.updateDialog = false
    },
    openUpdateDialog (event_info) {
      this.updateDialog = true
      this.new_appointment = event_info.data
    }
  }
}
</script>

<style lang="scss" scoped>
.remove {
  position: absolute;
  top: 0;
  right: 0;
}
.time {
  position: absolute;
  bottom: 0;
}
.created-event>.details-card {
  height: 100%;
}
</style>

<style>
.p-calendar > .kalendar-wrapper.gstyle .day-indicator.today .number-date {
  background: #0f486d;
}

.p-calendar .sticky-top .days .today {
  color: #0f486d !important;
}

.p-calendar > .kalendar-wrapper.gstyle .created-event,
.p-calendar > .kalendar-wrapper.gstyle .creating-event {
  background-color: #42CBB6;
}
</style>
