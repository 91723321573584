<template>
  <div>
    <div class="container">
      <div v-if="application && application.user" class="pb-20 pt-20 title">
        Agendar entrevista con {{ application.user.profile.firstName }} {{ application.user.profile.firstSurname }}
      </div>
      <div v-if="application && application.vacancy" class="pb-20 pt-20 is-flex justify-space-between">
        <div class="subtitle">
          Vacante: {{ application.vacancy.name }}
        </div>
        <b-button
          rounded
          icon-left="arrow-left"
          type="is-primary"
          tag="router-link"
          :to="{ name: 'VacancyCandidates', params: { id: application.vacancy._id } }">
          Regresar
        </b-button>
      </div>
      <FullCalendar
        ref="calendar"
        :options='calendarOptions'
      >
        <template v-slot:eventContent='arg'>
          <b>{{ arg.event.title }}</b>
        </template>
      </FullCalendar>
    </div>

    <b-modal
      :active.sync="interviewModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <div class="modal-card">
        <div class="modal-card-head">
          <div class="modal-card-title">
            Agendar entrevista con Pro Meritum
          </div>
        </div>
        <div class="modal-card-body">
          <div class="columns is-multiline">
            <div class="column is-full">
              <div>
                De: <b>{{ form.startDate | formatToHours }}</b>
              </div>
              <div>
                A: <b>{{ form.endDate | formatToHours }}</b>
              </div>
            </div>
            <div class="column is-full">
              <b-input
                icon="map-marker"
                placeholder="Dirección"
                type="text"
                v-model="form.address"
              />
            </div>
            <div class="column is-full">
              <b-input
                icon="cellphone"
                placeholder="Teléfono"
                type="text"
                v-model="form.phoneCall"
                maxlength="10"
              />
            </div>
            <div class="column is-full">
              <b-input
                icon="message-video"
                placeholder="Link de videollamada"
                type="text"
                v-model="form.urlCall"
              />
            </div>
            <div class="column is-full">
              <b-input
                placeholder="Comentarios"
                type="textarea"
                v-model="form.comments"
              />
            </div>
          </div>
        </div>
        <div class="modal-card-foot">
          <b-button rounded @click="closeInterviewModal">
            Cancelar
          </b-button>
          <b-button rounded type="is-primary" @click="setInterview">
            Guardar
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'
import blockedDates from '@/lib/blockedDates'
import moment from 'moment'

export default {

  components: {
    FullCalendar // make the <FullCalendar> tag available
  },

  data: function() {
    return {
      application: {},
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: null,
          center: 'title',
          right: 'prev,next today'
        },
        initialView: 'timeGridWeek',
        initialEvents: [],
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: false,
        nowIndicator: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        events: [],
        eventChange: this.handleEvent,
        locale: esLocale,
        displayEventTime: false,
        businessHours: [
          {
            daysOfWeek: [ 1, 2, 3, 4 ], // Monday, Tuesday, Wednesday
            startTime: '08:00', // 8am
            endTime: '17:00' // 6pm
          },
          {
            daysOfWeek: [ 5 ], // Thursday, Friday
            startTime: '08:00', // 10am
            endTime: '14:00' // 4pm
          }
        ]
        /*
        eventsSet:
        eventAdd:
        eventRemove:
        */
      },
      currentEvents: [],
      restrictedHours: [8, 17],
      fridayRestricted: [8, 14],
      halfDayDates: [
        '2020-05-10',
        '2020-09-15',
        '2020-11-01',
        '2020-12-12',
        '2020-12-24',
        '2020-12-31'
      ],
      isLoading: false,
      interviewModal: false,
      form: {
        startDate: '',
        endDate: '',
        address: '',
        phoneCall: '',
        urlCall: '',
        comments: ''
      }
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    companyId () {
      return this.$store.state.company.id
    }
  },
  async mounted () {
    await this.load()
  },
  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    async handleDateSelect (selectInfo) {
      if (this.isBlockedDate(selectInfo.startStr)) {
        this.showBlockedDateDialog()
        return
      }

      if (!this.isAfterToday(selectInfo.startStr)) {
        this.showPastTimeDialog()
        return
      }

      if (!this.isAvailableHour(selectInfo.startStr)) {
        this.showRestrictedTimeDialog(selectInfo.startStr)
        return
      }

      if (!this.isTimeAfter(selectInfo.startStr)) {
        this.showEarlyTimeDialog()
        return
      }

      if (this.isTaken(selectInfo.startStr)) {
        this.showTakenTimeDialog()
        return
      }

      this.form.startDate = selectInfo.startStr
      this.form.endDate = selectInfo.endStr

      this.interviewModal = true
    },
    async handleEventClick(clickInfo) {
      this.isLoading = true
      try {
        const response = await this.$api.get(`/events/${clickInfo.event.id}`)
        const event = response.data
        const template = `
          <div>
            Fecha: <b>${new Date(event.startDate).toLocaleString()} - ${new Date(event.endDate).toLocaleString()}</b>
          </div>
          <div>
            Empresa: <b>${event.company.name}</b>
          </div>
          <div>
            Vacante: <b>${event.vacancy.name}</b>
          </div>
          <div>
            Autor: <b>${event.author.email}</b>
          </div>
        `

        const canRemove = !event.confirmedBy.length && event.kind === 'promeritumInterview'

        this.$buefy.dialog.confirm({
          title: event.name,
          message: `${template}`,
          confirmText: canRemove ? 'Eliminar' : 'Cerrar',
          cancelText: 'Cerrar',
          canCancel: canRemove,
          type: 'is-danger',
          onConfirm: canRemove ? async () => this.removeEvent(event._id) : () => {},
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    async handleEvent (event) {
      const newEvent = event.event
      const phoneCallDate = moment(newEvent.startStr).format('lll')

      if (this.isBlockedDate(newEvent.startStr)) {
        this.showBlockedDateDialog()
        return
      }

      if (!this.isAfterToday(newEvent.startStr)) {
        this.showPastTimeDialog()
        await this.load()
        return
      }

      if (!this.isAvailableHour(newEvent.startStr)) {
        this.showRestrictedTimeDialog(newEvent.startStr)
        await this.load()
        return
      }

      if (!this.isTimeAfter(newEvent.startStr)) {
        this.showEarlyTimeDialog()
        await this.load()
        return
      }

      if (this.isTaken(newEvent.startStr)) {
        this.showTakenTimeDialog()
        await this.load()
        return
      }

      const payload = {
        startDate: newEvent.startStr,
        endDate: newEvent.endStr
      }
      this.$buefy.dialog.confirm({
        title: 'Cambiar entrevista',
        message: `La entrevista para revisar todos los detalles de tu vacante se agendarán para esta fecha:<br>
          <b>${phoneCallDate}</b>`,
        cancelText: 'Cancelar',
        confirmText: 'Agendar',
        type: 'is-primary',
        onConfirm: async () => await this.updatePhoneCallDate(newEvent.id, payload)
      })
    },
    async load () {
      this.isLoading = true
      try {
        const response = await this.$api.get(`/companies/${this.companyId}/events`)
        const application = await this.$api.get(`/application/${this.id}`)
        this.application = application.data
        this.calendarOptions.events = response.data.map(event => {
          const payload = {
            id: event.id,
            title: event.name,
            start: event.startDate,
            end: event.endDate,
            allDay: false,
            borderColor: event.kind === 'promeritumInterview' ? '#42CBB6' : event.company.mainColor,
            backgroundColor: event.kind === 'promeritumInterview' ? '#42CBB6' : event.company.mainColor,
            editable: event.kind === 'promeritumInterview' && !event.confirmedBy.length
          }
          return payload
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    async updatePhoneCallDate (id, payload) {
      this.isLoading = true
      try {
        await this.$api.put(`/events/${id}`, payload)
        this.$buefy.toast.open({
          message: 'La entrevista se actualizó',
          type: 'is-success'
        })
        await this.load()
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    async removeEvent (id) {
      this.isLoading = true
      try {
        await this.$api.delete(`/events/${id}`)
        this.$buefy.toast.open({
          message: 'La entrevista se eliminó',
          type: 'is-success'
        })
        await this.load()
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    isAfterToday (selectedDate) {
      const date = new Date(selectedDate)
      const today = new Date()

      return (today < date)
    },
    isAvailableHour (selectedDate) {
      const date = moment(selectedDate)
      const hour = date.hour()
      const day = date.day()

      const halfDayDate = this.halfDayDates.find(halfDay => moment(halfDay).isSame(date, 'day'))

      if (halfDayDate) {
        return ((hour + 1) > 8 && hour < 14)
      }

      const availableHour = (day === 5)
        ? (hour + 1) > this.fridayRestricted[0] && hour < this.fridayRestricted[1]
        : (hour + 1) > this.restrictedHours[0] && hour < this.restrictedHours[1]

      return availableHour
    },
    isTaken (selectedDate) {
      const isTaken = this.calendarOptions.events.some(event => {
        return moment(event.start).isSame(selectedDate)
      })
      return isTaken
    },
    isBlockedDate (selectedDate) {
      const date = moment(selectedDate)
      return blockedDates.some(blockedDate => blockedDate.isSame(date, 'day'))
    },
    closeInterviewModal () {
      this.form = {
        startDate: '',
        endDate: '',
        address: '',
        phoneCall: '',
        urlCall: '',
        comments: ''
      }
      this.interviewModal = false
    },
    async setInterview () {
      this.isLoading = true
      try {
        await this.$api.post(`/application/${this.id}/promeritum-interview`, this.form)
        this.$buefy.toast.open({
          message: 'Se agendó la entrevista',
          type: 'is-success'
        })
        this.closeInterviewModal()
        await this.load()
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    isTimeAfter (selectedDate) {
      const date = moment(selectedDate)
      const now = moment().add(1, 'hour')

      return now.isBefore(date)
    },
    showRestrictedTimeDialog (selectedDate) {
      const date = new Date(selectedDate)
      const day = date.getDay()
      const dates = {
        start: day === 5 ? this.fridayRestricted[0] : this.restrictedHours[0],
        end: day === 5 ? this.fridayRestricted[1] : this.restrictedHours[1]
      }
      this.$buefy.dialog.confirm({
        title: 'Oh oh',
        message: `Lo sentimos, a esta hora no estamos disponibles, pero prueba otra hora entre las ${dates.start}hrs ó las ${dates.end}hrs.`,
        confirmText: 'Ok, lo entiendo',
        canCancel: false,
        type: 'is-danger',
        hasIcon: true
      })
    },
    showPastTimeDialog () {
      this.$buefy.dialog.confirm({
        title: ':(',
        message: `Lo sentimos, pero no puedes agendar una llamada para el pasado, nuestro DeLorean no está en servicio aún.`,
        confirmText: 'Ok, lo entiendo',
        canCancel: false,
        type: 'is-danger',
        hasIcon: true
      })
    },
    showEarlyTimeDialog () {
      this.$buefy.dialog.confirm({
        title: 'Oh oh',
        message: `Lo sentimos, es muy pronto para agendar una llamada, pero prueba dentro de una hora.`,
        confirmText: 'Ok, lo entiendo',
        canCancel: false,
        type: 'is-danger',
        hasIcon: true
      })
    },
    showTakenTimeDialog () {
      this.$buefy.dialog.confirm({
        title: 'Oh oh',
        message: `Lo sentimos, esta hora ya está ocupada, busca otro espacio libre en el calendario para que puedas agendar.`,
        confirmText: 'Ok, lo entiendo',
        canCancel: false,
        type: 'is-danger',
        hasIcon: true
      })
    },
    showBlockedDateDialog () {
      this.$buefy.dialog.confirm({
        title: 'Oh oh',
        message: `Lo sentimos, esta fecha no laboramos, intenta otro día.`,
        confirmText: 'Ok, lo entiendo',
        canCancel: false,
        type: 'is-danger',
        hasIcon: true
      })
    }
  }
}
</script>
