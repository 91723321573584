<template>
  <div
    :class="`media-section ${selectedSection?._id === defaultMediaSection._id ? 'selectedSection' : ''}`"
    @mousedown="activeSection"
  >
    <div
      v-if="defaultMediaSection.backgroundImage?.url"
    >
      <b-image
        :src="defaultMediaSection.backgroundImage.url"
        :alt="`Imagen de fondo de la sección ${defaultMediaSection.name}`"
        class="mediaSectionBackgroundImage"
        ref="mediaSectionBackgroundImage"
        id="source-sizes-reference"
        @load="updateContainerSizes"
      ></b-image>
      <div 
        class="media-sources" 
      >
        <MultimediaSource
          v-for="(multimediaSource, index) in mediaSection.multimediaSources"
          :key="index"
          :defaultMultimediaSource="multimediaSource"
          :containerSizes="containerSizes"
          @updateMultimediaSource="updateMultimediaSource"  
          @activeMediaSource="activeMediaSource(multimediaSource)"     
        />
      </div>
    </div>

    <div
      v-else
      class="non-image"
    >
      Agregue una imagen de fondo
    </div>
  </div>
</template>

<script>
import MultimediaSource from './MultimediaSource.vue';

export default {
  props: {
    defaultMediaSection: {
      type: Object,
    },
    selectedSection: {
      type: Object,
    },
    availableMediaQueries: {
      type: Array,
    },
  },
  components:{
    MultimediaSource
  },
  data(){
    return {
      mediaSection: {
        backgroundImage: {},
        name: '',
        multimediaSources: []
      },
      containerSizes: {
        width: -1,
        height: -1
      }
    }
  },
  mounted() {
    this.mediaSection = {...this.mediaSection, ...this.defaultMediaSection}
  },
  watch: {
    defaultMediaSection: {
      handler(val) {
        if (val) {
          this.mediaSection = {...this.mediaSection, ...val}
        }
      },
      deep: true,
    },
  },
  methods: {
    activeSection() {
      this.$emit('activeSection', this.defaultMediaSection)
    },
    removeMediaSection() {
      this.$emit('removeMediaSection', this.defaultMediaSection)
    },
    activeMediaSource(mediaSource) {
      this.$emit('activeMediaSource', mediaSource)
    },
    updateMultimediaSource(auxMultimediaSource){
      this.mediaSection.multimediaSources = this.mediaSection.multimediaSources.map(multimediaSource => {
        if(multimediaSource._id == auxMultimediaSource._id){
          return auxMultimediaSource;
        }
        return multimediaSource;
      }); 

      this.$emit('updateMediaSection', this.mediaSection)
    },
    updateContainerSizes(){
      this.containerSizes = {
        width: document.querySelector('#source-sizes-reference').offsetWidth,
        height: document.querySelector('#source-sizes-reference').offsetHeight
      }
    }
  },
}
</script>

<style scoped>
  .mediaSectionBackgroundImage {
    user-select: none;
  }
  .media-section {
    width: 100%;
    min-height: fit-content;
    position: relative;
    border: none;
  }
  .media-section .media-sources {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .non-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    border: 1px dashed #42CBB6;
  }
  .draggable-resizable {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .selectedSection {
    box-shadow: 0 0 2px 2px #42CBB6;
  }
</style>
