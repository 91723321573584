<template>
  <div
    class="card is-shadowless"
    style="border-radius: 30px"
  >
    <div class="card-content">
      <!-- General user -->
      <div class="columns is-multiline">
        <div class="column is-full">
          <div class="title is-4 has-text-blue">
            {{ labels.generalProfile }}
          </div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.birthday }}
          </div>
          <div>
            {{ profile.birthdate | formatDate }}
          </div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.gender }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.gender }}
          </div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.pronoun }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.pronoun || "Consultar con candidatx" }}
          </div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.anyDisability }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.hasDisability ? "Si" : "No" }}
          </div>
        </div>

        <div
          class="column is-6"
          v-if="profile.hasDisability"
        >
          <div class="label">
            {{ labels.typeDisability }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.disability }}
            <span v-if="profile.disability === 'Otro'"> - {{ profile.otherDisability }}</span>
          </div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.telephone }}
          </div>
          <div class="overflow-ellipsis">{{ profile.phoneCode }} {{ profile.phone }}</div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.mail }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.email }}
          </div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.nationalIdentifier }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.curp }}
          </div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.state }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.state }}
          </div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.city }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.city }}
          </div>
        </div>
      </div>

      <hr />

      <!-- Academic user -->
      <div class="columns is-multiline">
        <div class="column is-full">
          <div class="title is-4 has-text-blue">
            {{ labels.academicProfile }}
          </div>
        </div>

        <div class="column is-full">
          <div class="label">
            {{ labels.university }}
          </div>
          <div class="overflow-ellipsis is-capitalized">
            {{ profile.university }}
          </div>
        </div>

        <div class="column is-full">
          <div class="label">
            {{ labels.campus }}
          </div>
          <div class="overflow-ellipsis is-capitalized">
            {{ profile.campus }}
          </div>
        </div>

        <div class="column is-full is-capitalized">
          <div class="label">
            {{ labels.career }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.career }}
          </div>
        </div>

        <div class="column is-4">
          <div class="label">
            {{ labels.studyArea }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.studyArea }}
          </div>
        </div>

        <div class="column is-4">
          <div class="label">
            {{ labels.academicLevel }}
          </div>
          <div class="overflow-ellipsis">
            {{ academicLevels[profile.academicLevel[0]] }}
          </div>
        </div>

        <div
          v-if="profile.academicLevel.includes('student')"
          class="column is-4"
        >
          <div class="label">
            {{ labels.currentSemester }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.currentSemester }}
          </div>
        </div>

        <div class="column is-4">
          <div class="label">
            {{ labels.graduateDate }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.graduateDate | formatToDateMonth }}
          </div>
        </div>

        <div class="column is-4">
          <div class="label">
            {{ labels.degreeDate }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.degreeDate | formatToDateMonth }}
          </div>
        </div>

        <div class="column is-full">
          <div class="label">
            {{ labels.schoolSchedule }}
          </div>
        </div>

        <div class="column is-4">
          <div class="label">
            {{ labels.monday }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.mondaysSchedule ?? "Sin asignar" }}
          </div>
        </div>

        <div class="column is-4">
          <div class="label">
            {{ labels.tuesday }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.tuesdaysSchedule ?? "Sin asignar" }}
          </div>
        </div>

        <div class="column is-4">
          <div class="label">
            {{ labels.wednesday }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.wednesdaysSchedule ?? "Sin asignar" }}
          </div>
        </div>

        <div class="column is-4">
          <div class="label">
            {{ labels.thursday }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.thursdaysSchedule ?? "Sin asignar" }}
          </div>
        </div>

        <div class="column is-4">
          <div class="label">
            {{ labels.friday }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.fridaysSchedule ?? "Sin asignar" }}
          </div>
        </div>

        <div class="column is-4">
          <div class="label">
            {{ labels.saturday }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.saturdaysSchedule ?? "Sin asignar" }}
          </div>
        </div>
      </div>

      <hr />

      <!-- Skills and knowledge user -->
      <div class="columns is-multiline">
        <div class="column is-full">
          <div class="title is-4 has-text-blue">
            {{ labels.skillsKnowledgeProfile }}
          </div>
        </div>

        <div class="column is-half">
          <div class="label">
            {{ labels.skills }}
          </div>

          <div>
            <b-field
              grouped
              group-multiline
            >
              <div
                v-for="(skill, index) in profile.skills"
                :key="`skill-${index}`"
                class="control"
              >
                <b-taglist attached>
                  <b-tag type="is-blue">
                    {{ skill.name }}
                  </b-tag>
                  <b-tag type="is-dark">
                    {{ skill.level }}
                  </b-tag>
                </b-taglist>
              </div>
            </b-field>
          </div>
        </div>

        <div class="column is-half">
          <div class="label">
            {{ labels.interests }}
          </div>

          <div class="overflow-ellipsis">
            {{ profile.interestOne ?? "Sin asignar" }}
          </div>

          <div class="overflow-ellipsis">
            {{ profile.interestTwo ?? "Sin asignar" }}
          </div>

          <div class="overflow-ellipsis">
            {{ profile.interestThree ?? "Sin asignar" }}
          </div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.englishLevel }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.englishLevel }}
          </div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.excelLevel }}
          </div>
          <div class="overflow-ellipsis">
            {{ profile.excelLevel }}
          </div>
        </div>
      </div>

      <hr />

      <!-- Professional user -->
      <div class="columns is-multiline">
        <div class="column is-full">
          <div class="title is-4 has-text-blue">
            {{ labels.professionalProfile }}
          </div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.curriculum }}
          </div>
          <div class="overflow-ellipsis">
            <a
              :href="profile.resume.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver curriculum
            </a>
          </div>
        </div>

        <div
          class="column is-6"
          v-if="profile.applicationLinkVideo"
        >
          <div class="label">
            {{ labels.urlVideo }}
          </div>
          <div class="overflow-ellipsis">
            <a
              :href="profile.applicationLinkVideo"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ profile.applicationLinkVideo }}
            </a>
          </div>
        </div>

        <div
          class="column is-6"
          v-if="profile.applicationFile"
        >
          <div class="label">
            {{ labels.uploadFile }}
          </div>
          <div class="overflow-ellipsis">
            <a
              :href="profile.applicationFile.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ profile.applicationFile.name }}
            </a>
          </div>
        </div>

        <div
          v-if="['superqueen', 'recruiter-manager', 'recruiter'].includes($isRole(user))"
          class="column is-6"
        >
          <div class="label">
            {{ labels.psychometryApplicationDate }}
          </div>
          <div v-if="profile.psychometricTest">
            {{ profile.psychometricTest.appliedAt | formatDate }}
          </div>
          <div v-else>No ha aplicado la psicometría</div>
        </div>

        <div class="column is-6">
          <div class="label">
            {{ labels.portfolio }}
          </div>
          <div class="overflow-ellipsis">
            <a
              :href="profile.portfolio"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ profile.portfolio }}
            </a>
          </div>
        </div>
      </div>

      <hr />

      <!-- Vacancy found source -->
      <div class="columns is-multiline mb-1">
        <div class="column is-full">
          <div class="title is-4 has-text-blue">
            {{ labels.vacancyFoundSource }}
          </div>
          <div v-if="application.vacancyFoundSource">
            {{ application.vacancyFoundSource }}
          </div>
          <div v-else>
            {{ labels.noRecordedVacancyFound }}
          </div>
        </div>
      </div>

      <hr />

      <!-- Aditional Comments -->
      <div class="columns is-multiline mb-1">
        <div class="column is-full">
          <div class="title is-4 has-text-blue">
            {{ labels.aditionalComments }}
          </div>
          <div v-if="profile.aditionalComments">
            {{ profile.aditionalComments }}
          </div>
          <div v-else>
            {{ labels.noAditionalComments }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { academicLevels } from '@/lib/vacancyDictionaries'
export default {
  props:{
    application: {
      default: () => ({}),
      required: true
    },
    email: {
      default: '',
      required: true
    }
  },
  data() {
    return {
      academicLevels,
    };
  },
  computed: {
    labels() {
      return this.$t("screens.candidate.detail");
    },
    user() {
      return this.$store.state.session.user;
    },
    profile(){
      return this.application.profile;
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

</style>