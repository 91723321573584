<template>
  <div class="card" v-if="section.name">
    <div class="card-header">
      <div class="column">
        <div class="columns">
          <div class="column">
            <b-icon icon="arrows-up-down-left-right" />
            Presencia
          </div>
          <div class="column has-text-right">
            Mostrar sección:
            <b-switch v-model="section.prescence.active" @input="updateSection"/>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="pb-15">
        <b-field label="Descripción">
          <b-input 
            v-model="section.prescence.description"
            placeholder="Ejem. nuestra empresa es la mejor de todas"
            @blur="updateSection" 
          />
        </b-field>
      </div>
      <div v-for="(stat, index) in section.prescence.stats" :key="index">
        <div class="columns">
          <div class="column">
            <b-field label="Presencia de la empresa">
              <b-input 
                v-model="stat.stat" 
                placeholder="Ejem. 200"
                @blur="updateSection"
              />
            </b-field>
            <b-field>
              <b-input 
                v-model="stat.text" 
                placeholder="Ejem. Ciudades" 
                @blur="updateSection"
              />
            </b-field>
          </div>
          <div class="column">
            <div class="buttons" style="justify-content: flex-end">
              <b-button type="is-danger" icon-right="trash" @click="removeStat(index)" />
            </div>
          </div>
        </div>
        <hr>
      </div>
      <b-button @click="addStats">
        Agregar estadística
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    defaultSection: {
      type: Object,
    },
  },
  data() {
    return {
      section: {},
      isLoading: false,
    }
  },
  mounted() {
    this.section = {...this.defaultSection};
  },
  watch: {
    defaultSection: {
      handler: function (val) {
        this.section = { ...val }
      }
    },
  },
  methods: {
    updateSection() {
      this.$emit('updateSection', this.section)
    },
    addStats() {
      this.section.prescence.stats.push({ stat: '', text: '' })
      this.updateSection()
    },
    removeStat(index) {
      this.section.prescence.stats.splice(index, 1)
      this.updateSection()
    },
  }
}
</script>

<style scoped>
.loading-wrapper{
    position: relative;
    min-height: 300px;
  }
</style>
