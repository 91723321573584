<template>
  <div>
    <ValidationObserver ref="observer">
      <ValidationProvider
        rules="required"
        :name="labels.recommendable"
        v-slot="{ errors, valid }"
      >
        <div class="label">
          {{ labels.recommendable }}
        </div>
        <b-field
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-radio-button
            v-for="(option, index) in options"
            :key="`option-${index}`"
            :native-value="option.value"
            v-model="interview.recommendable"
            type="is-primary"
          >
            <span>{{ option.text }}</span>
          </b-radio-button>
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.familyEnviroment"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.familyEnviroment"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.familyEnviroment"
            type="textarea"
            v-model="interview.familyEnviroment"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.freeTime"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.freeTime"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.freeTime"
            type="textarea"
            v-model="interview.freeTime"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.sports"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.sports"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.sports"
            type="textarea"
            v-model="interview.sports"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.character"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.character"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.character"
            type="textarea"
            v-model="interview.character"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.challenges"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.challenges"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.challenges"
            type="textarea"
            v-model="interview.challenges"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.achievements"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.achievements"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.achievements"
            type="textarea"
            v-model="interview.achievements"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.mediumTermGoals"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.mediumTermGoals"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.mediumTermGoals"
            type="textarea"
            v-model="interview.mediumTermGoals"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.longTermGoals"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.longTermGoals"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.longTermGoals"
            type="textarea"
            v-model="interview.longTermGoals"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.strengths"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.strengths"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.strengths"
            type="textarea"
            v-model="interview.strengths"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.skillToImprove"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.skillToImprove"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.skillToImprove"
            type="textarea"
            v-model="interview.skillToImprove"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.careerPath"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.careerPath"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.careerPath"
            type="textarea"
            v-model="interview.careerPath"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.capabilityOne"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.capabilityOne"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.capabilityOne"
            type="textarea"
            v-model="interview.capabilityOne"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.capabilityTwo"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.capabilityTwo"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.capabilityTwo"
            type="textarea"
            v-model="interview.capabilityTwo"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.capabilityThree"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.capabilityThree"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.capabilityThree"
            type="textarea"
            v-model="interview.capabilityThree"
          />
        </b-field>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
        :name="labels.comments"
        v-slot="{ errors, valid }"
      >
        <b-field
          :label="labels.comments"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            :placeholder="labels.comments"
            type="textarea"
            v-model="interview.comments"
          />
        </b-field>
      </ValidationProvider>
    </ValidationObserver>

    <div class="is-flex justify-flex-end pt-10">
      <b-button
        type="is-primary"
        @click="donePromeritumInterview"
      >
        {{ labels.addComments }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { promeritumInterviewForm } from '@/mixins/promeritumInterview';
export default {
  mixins:[promeritumInterviewForm],
  data() {
    return {
      options: [
        { text: "Recomendable", value: "recommendable" },
        { text: "Recomendable con reservas", value: "recommended-with-reservations" },
        { text: "No recomendable", value: "non-recommendable" },
      ],
    };
  },

  mounted() {
    
  },
  computed:{
    labels() {
      return this.$t("screens.candidate.detail");
    },
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    async donePromeritumInterview() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        this.$buefy.dialog.confirm({
          title: `¿Cerrar la entrevista?`,
          message: `Al cerrarla, tu feedback del candidato no podrá ser editado, asegúrate de haber escrito
                  un feedback completo de tu candidato.`,
          confirmText: "Ok, estoy de acuerdo",
          cancelText: "Ooops, mejor no",
          onConfirm: async () => await this.sendPromeritumFeedback(),
        });
      }
    },
    async sendPromeritumFeedback() {
      this.isLoading = true;

      const payload = this.interview;
      try {
        await this.$api.post(`application/${this.id}/promeritum-feedback`, payload);
        this.$emit('update')
        this.$buefy.toast.open({
          message: "Se guardó el feedback del candidato",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: "is-danger",
        });
      }
      this.$emit('update')
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>