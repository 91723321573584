<template>
	<div>
		<div class="column is-multiline">
			<div class="column is-full">
				<div class="title is-4 has-text-blue">Killer Questions</div>
			</div>

			<div class="column is-full">
				<ol class="ml-20">
					<li
						v-for="(answer, index) in answers"
						:key="`answer-${index}`"
					>
						<div class="label">
							{{ answer.killerQuestion.question }}
						</div>
						<div v-if="answer.killerQuestion.questionType !== 'multiple-option'">
							<strong> R: </strong>
							{{ answer.answer }}
						</div>
						<div v-else>
							<strong> R: </strong>
							<span
								v-for="(item, index) in answer.answer"
								:key="`item-${index}`"
							>
								{{ item }}
								<span v-if="index < answer.answer.length - 1"> , </span>
							</span>
						</div>
					</li>
				</ol>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			vacancy: {
				type: String,
				required: true,
			},
			application: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				answers: [],
			};
		},
		watch: {
			application() {
				this.getKillerQuestionsAnswers();
			},
		},
		mounted() {
			this.getKillerQuestionsAnswers();
		},
		methods: {
			async getKillerQuestionsAnswers() {
				try {
					const { data } = await this.$api.get(`/vacancies/${this.vacancy}/killer-questions/${this.application}`);
					this.answers = data;

					if (this.answers.length)
						return this.$emit("hasKillerQuestions", true);
				} catch (error) {
					console.error(error);
				}
				this.$emit("hasKillerQuestions", false);
			},
		},
	};
</script>

<style lang="scss" scoped></style>
