import moment from 'moment'

const blockedDates = [
  // januaryFirst
  moment().year(2022).date(1).month(0),
  // firstFebruryMonday
  moment().year(2022).date(7).month(1),
  // twentyOneMarch
  moment().year(2022).date(21).month(2),
  // eightMarch
  moment().year(2022).date(8).month(2),
  // holyWeek
  moment().year(2022).month(3).date(14),
  moment().year(2022).month(3).date(15),
  // mayFirst
  moment().year(2022).date(1).month(4),
  // mayFifth
  moment().year(2022).date(5).month(4),
  // septemberSixteenth
  moment().year(2022).date(16).month(8),
  // thirthNovemberMonday
  moment().year(2022).date(21).month(10),
  // novemberSecond
  moment().year(2022).date(2).month(10),
  // christmas
  moment().year(2022).date(21).month(11),

  // moment().year(year).month(month).date(day) - Good (Documentation)
]

export default blockedDates
