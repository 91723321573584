<template>
  <div class="container">
    <div v-if="loggedIn" class="mb-10 pt-15 is-flex justify-flex-end">
      <div>
        <b-button
          rounded
          icon-left="arrow-left"
          type="is-primary"
          tag="router-link"
          :to="{ name: 'Vacancies', params: { id: company.id } }">
          Regresar
        </b-button>
      </div>
    </div>

    <div class="pt-40 pb-40">
      <div class="is-flex justify-center">
        <div v-if="!confirmed" class="card" style="width: 100%; max-width: 600px">
          <div class="card-header">
            <div class="card-header-title">
              Vacante: {{ vacancy.name }}
            </div>
          </div>
          <div class="card-content">
            <div class="subtitle">
              Horas sugeridas para la llamada:
            </div>
            <div
              class="card pointer mb-20"
              v-for="(phoneCallDate, index) in phoneCallDates"
              :key="`phone-call-${index}`"
              @click="confirm(phoneCallDate)">
              <div class="card-content">
                <div class="columns">
                  <div class="column">
                    <div class="subtitle is-6">
                      Empresa:
                    </div>
                    <div class="subtitle is-4">
                      {{ phoneCallDate.company ? phoneCallDate.company.name : '' }}
                    </div>
                  </div>
                  <div class="column">
                    <div class="subtitle is-6">
                      Fecha de llamada:
                    </div>
                    <div class="subtitle is-4">
                      {{ phoneCallDate.startDate | formatToHours }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="card" style="width: 100%; max-width: 600px">
          <div class="card-header">
            <div class="card-header-title">
              Vacante: {{ vacancy.name }}
            </div>
          </div>
          <div class="card-content">
            <div class="subtitle">
              {{ eventKind }} confirmada:
            </div>
            <div
              class="card mb-20"
              v-for="(phoneCallDate, index) in phoneCallDates"
              :key="`phone-call-${index}`">
              <div class="card-content">
                <div class="columns">
                  <div class="column">
                    <div class="subtitle is-6">
                      Empresa:
                    </div>
                    <div class="subtitle is-4">
                      {{ phoneCallDate.company ? phoneCallDate.company.name : '' }}
                    </div>
                  </div>
                  <div class="column">
                    <div class="subtitle is-6">
                      Fecha de llamada:
                    </div>
                    <div class="subtitle is-4">
                      {{ phoneCallDate.startDate | formatToHours }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      phoneCallDates: [],
      vacancy: {},
      isLoading: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    company () {
      return this.$store.state.company
    },
    kind () {
      return this.$route.query.kind
    },
    candidate () {
      return this.$route.query.candidate
    },
    loggedIn () {
      return !!this.$store.state.session.token
    },
    eventKind () {
      const events = {
        promeritumInterview: 'Entrevista con Pro meritum',
        companyInterview: 'Entrevista con la empresa',
        phoneCallDate: 'Llamada de alineación'
      }

      return events[this.kind]
    },
    confirmed () {
      return this.phoneCallDates.some(event => event.confirmedBy.length)
    }
  },
  async beforeMount () {
    await this.load()
  },
  methods: {
    async load () {
      this.isLoading = true
      try {
        const params = [
          'limit=12'
        ]
        if (this.kind) {
          params.push(`kind=${this.kind}`)
        }
        if (this.candidate) {
          params.push(`application=${this.candidate}`)
        }
        const { data } = await this.$api.get(`/vacancies/${this.id}/events?${params.join('&')}`)
        const vacancy = await this.$api.get(`/vacancies/${this.id}`)
        this.phoneCallDates = data
        this.vacancy = vacancy.data
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    async confirm (phoneCall) {
      const start = moment(phoneCall.startDate).format('lll')
      const end = moment(phoneCall.endDate).format('lll')
      this.$buefy.dialog.alert({
        title: `¿Deseas confirmar?`,
        message: `Estás por confirmar la ${this.eventKind} para la fecha:<br>
          De: <b>${start}</b><br>
          A: <b>${end}</b>`,
        confirmText: 'Si, confirmar',
        cancelText: 'Cancelar',
        canCancel: true,
        onConfirm: async () => await this.confirmEvent(phoneCall._id)
      })
    },
    async confirmEvent (id) {
      this.isLoading = true
      try {
        await this.$api.put(`/events/${id}/confirm-phone-call`)
        this.$buefy.toast.open({
          message: `Se confirmó la ${this.eventKind}`,
          type: 'is-success'
        })
        await this.load()
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    }
  }
}
</script>
