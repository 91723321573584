<template>
  <div
    class="card is-shadowless"
    style="border-radius: 30px"
  >
    <div class="card-content p-5">
      <div>
        <div class="title is-4 has-text-blue mb-4">{{ labels.companyComments }}</div>
      </div>
      <div
        v-if="companyInterviewFeedback"
      >
        {{ companyInterviewFeedback }}
      </div>
      <div
        v-else
      >
      {{ labels.noCompanyComments  }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companyInterviewFeedback: {
      required: false
    }
  },
  data() {
    return {
      
    };
  },
  computed: {
    labels() {
        return this.$t("screens.candidate.detail");
    }
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>