import cities from './cities';
import municipalities from './ES/municipalities'
import { replaceAccent } from '../utils/string'

export const roleLabel = Object.freeze({
  hrmanager: 'Manager de RH',
  solicitant: 'Solicitante'
});

export const levelOptions = [
  'Básico',
  'Intermedio',
  'Avanzado',
];

export const knowledgeLevels = [
  'Deseable',
  'Básico',
  'Intermedio',
  'Avanzado',
  'Certificado'
];

export const modalities = [
  'Híbrido',
  'Presencial',
  'Home Office',
  'Remoto por Protocolo COVID'
];

const defaultVacancyFields = {
  name: 'Nombre de la vacante',
  duration: 'Duración',
  places: 'Número de Posiciones',
  state: 'Estado',
  city: 'Ciudad',
  schedule: 'Horario',
  showScholarship: 'Mostrar Beca',
  scholarship: 'Beca',
  modality: 'Modalidad',
  degree: 'Licenciatura(s)',
  academicLevel: 'Nivel académico',
  minimumSemester: 'Semestre Mínimo',
  maximumSemester: 'Semestre Máximo',
  excelLevel: 'Nivel de Excel',
  englishLevel: 'Nivel de Inglés',
  graduateDate: 'Termino de Materias',
  degreeDate: 'Titulación',
  skills: 'Habilidades',
  knowledges: 'Conocimientos',
  goal: 'Objetivo',
  benefit: 'Beneficios',
  activities: 'Actividades Diarias',
  author: 'Contacto de la empresa',
  authorName: 'Nombre',
  authorEmail: 'Correo electrónico',
  authorPhone: 'Teléfono',
  project: 'Proyecto',
  comments: 'Comentarios',
};

export const academicLevels = {
  'student': 'Estudiante',
  'recently-graduated': 'Recién graduado',
  'experienced-graduated': 'Graduado con experiencia'
}

export function getDefaultVacancyFields (location) {
  if (location) {
    return location === 'mx'
      ? defaultVacancyFields
      : {
        ...defaultVacancyFields,
        state: "Provincia",
        goal: 'Descripción',
        activities: 'Funciones',
        benefit: 'Condiciones',
        minimumSemester: 'Curso mínimo',
        maximumSemester: 'Curso máximo',
        degree: 'Grado(s)',
        showScholarship: 'Mostrar remuneración',
        scholarship: 'Remuneración',
        maxPhoneDigits: '9',
      }
  }
  return defaultVacancyFields
}

export function getStateCities (stateId, location) {
  if (location) {
    const items = location === 'mx' ? cities : municipalities;
    return items
      .filter(city => city.state_id === stateId)
      .sort((a, b) => {
        const nameA = replaceAccent(a.name)
        const nameB = replaceAccent(b.name)
        if (nameA > nameB) {
          return 1
        }
        if (nameA < nameB) {
          return -1
        }
        return 0
      })
  }
  return []
}
