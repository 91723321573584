import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from '@/axios'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({ key: 'promeritum' })
  ],
  state: {
    session: {
      user: {
        id: null,
        role: null,
        email: null,
        company: null
      },
      token: null
    },
    company: {},
    recruiters: [],
    project: {
      id: null,
      name: null
    }
  },
  actions: {
    logout ({ commit }) {
      commit('logout')
    },
    login ({ commit }, session) {
      commit('login', session)
    },
    async getRecruiters ({ commit }) {
      const { data } = await axios.get(`/recruiters`)
      commit('setRecruiters', data)
    },
    getProjectName({ commit }, name){
      commit('setProjectName', name)
    },
    deleteProjectName({ commit}){
      commit("deleteProjectName");
    }
  },
  mutations: {
    logout (state) {
      state.session = {
        user: {
          id: null,
          role: null,
          email: null,
          company: null
        },
        token: null
      }
      state.company = {}
      state.recruiters = []
      state.project.name = null
    },
    login (state, session) {
      state.session = session
    },
    setCompany (state, company) {
      state.company = company
    },
    setRecruiters (state, recruiters) {
      state.recruiters = recruiters
    },
    setProjectName(state, name){
      state.project.name = name || 'Por definir'
    },
    deleteProjectName(state){
      state.project.name = null 
    }
  },
  getters: {
    companyId (state) {
      return state.company.id || null
    },
    unassignedRecruiters (state) {
      return state.recruiters.filter(recruiter => recruiter.deleted || !recruiter.companyRecruiter)
    },
    projectName (state) {
      return state.project.name || 'Por definir'
    }
  }
})