<template>
  <div>
    <div>
      <div class="label">
        {{ labels.urlVideo }}
      </div>
      <div v-if="customEvaluation.applicationLinkVideo">
        <a
          :href="customEvaluation.applicationLinkVideo"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ customEvaluation.applicationLinkVideo }}
        </a>
      </div>
      <div v-else>
        {{ labels.noUrlVideo }}
      </div> 
    </div>

    <div>
      <hr/>
      <div class="label">
        {{ labels.uploadFile }}
      </div>
      <div v-if="customEvaluation.applicationFile">
        <a
          :href="customEvaluation.applicationFile.url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ customEvaluation.applicationFile.name }}
        </a>
      </div>
      <div v-else>
        {{ labels.noUploadFile }}
      </div>
    </div>

    <div>
      <hr/>
      <div class="label">
        {{ labels.aditionalComments }}
      </div>
      <div v-if="customEvaluation.aditionalComments">
        {{ customEvaluation.aditionalComments }}
      </div>
      <div v-else>
        {{ labels.noAditionalComments }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    customEvaluation:{
      default: () => ({}),
      required: false
    }
  },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },
  computed:{
    labels() {
      return this.$t("screens.candidate.detail");
    },
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>